import React, { useState, useEffect } from "react";
import IconImg from "./HAMMERED.png";
import { makeStyles, useTheme, Theme, createStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Routes from "../Routes/Routes";
import { AppContext } from "../../libs/contextLib";
import { Button, Divider, Drawer, Grid, Hidden, IconButton, List, ListItem, ListItemText } from "@material-ui/core";
import { Auth } from "aws-amplify";
import { useHistory } from "react-router-dom";
import { onError } from "../../libs/errorLib";
import HomePage from "../HomePage/HomePage";
import MenuIcon from "@material-ui/icons/Menu";
import clsx from "clsx";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import { Link } from "react-router-dom";
import CookieConsent, { taredownCookie } from "../CookieConsent/CookieConsent";
import { environment } from "../../config/config";
import setupFirebase, { taredownFirebase } from "../../libs/firebase";
import { MyPropertyTile, HistoricTile, DistressedAssetsTile, DeletedPropertiesTile } from "../Menus/TiledMenu/Tiles/";
import { clearProfileStore, profileGet } from "../../api/Api";

const drawerWidth = 240;
const menuDrawerWidth = 86;
const BACKGROUNDS = {
  test: "grey",
  prod: "black",
  staging: "navyblue",
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      // flexGrow: 1,
      marginLeft: menuDrawerWidth,
      height: "100%",
    },
    rootMobile: {
      // flexGrow: 1,
      height: "100%",
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    title: {
      flexGrow: 1,
    },
    appBar: {
      backgroundColor: BACKGROUNDS[environment],
    },
    hide: {
      display: "none",
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
    },
    drawerPaper: {
      width: drawerWidth,
      backgroundColor: "grey",
    },
    menuDrawerPaper: {
      width: menuDrawerWidth,
      backgroundColor: "#181818",
      overflow: "scroll",
      zIndex: 1,
      "&::-webkit-scrollbar": {
        width: 0,
      },
    },
    drawerHeader: {
      display: "flex",
      alignItems: "center",
      padding: theme.spacing(0, 1),
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
      justifyContent: "flex-start",
    },
    list: {
      borderSWidth: 0,
    },
  })
);

const menuItems = [
  { text: "Home", url: "/", tile: DistressedAssetsTile },
  { text: "Distressed", url: "/distressed-properties", tile: DistressedAssetsTile },
  { text: "Stressed", url: "/stressed-properties", tile: DistressedAssetsTile },
  { text: "Our Properties", url: "/my-properties", tile: MyPropertyTile },
  { text: "Deleted", url: "/deleted-properties", tile: DeletedPropertiesTile },
  { text: "Historic", url: "/historic-properties", title: HistoricTile },
  // { text: "Profile", url: "/profile", tile: MyDetailsTile },
];

function taredownLocalStorage() {
  Object.keys(localStorage).forEach((key) => {
    if (key.indexOf("CognitoIdentityServiceProvider.") === 0) {
      delete localStorage[key];
    }
  });
}

export default function MenuAppBar() {
  const [isAuthenticating, setIsAuthenticating] = useState(true);
  const [isAuthenticated, setAuthenticated] = useState(false);
  const [open, setOpen] = React.useState(false);

  const classes = useStyles();
  const theme = useTheme();
  const history = useHistory();

  const handleDrawerOpen = () => setOpen(true);
  const handleDrawerClose = () => setOpen(false);

  useEffect(() => {
    setupFirebase();
  }, []);

  useEffect(() => {
    onLoad();
  }, []);

  async function onLoad() {
    try {
      await Auth.currentSession();
      setAuthenticated(true);
      setupFirebase();
    } catch (e: any) {
      if (e !== "No current user") {
        onError(e);
      }
    }
    setIsAuthenticating(false);
  }

  async function handleLogout() {
    setAuthenticated(false);

    await taredownFirebase();
    taredownCookie();
    taredownLocalStorage();

    try {
      await Auth.signOut({ global: true });
    } catch (e: any) {
      console.log(e);
    }

    clearProfileStore();
    history.push("/");
  }

  async function handleLogin() {
    const profile = await profileGet();
    if (profile.user_type !== "admin") {
      handleLogout();
      alert('Invalid User Type');
      return false;
    }
    setAuthenticated(true);
  }

  return isAuthenticating ? (
    <p>Loading...</p>
  ) : !isAuthenticated ? (
    <HomePage onSuccess={() => handleLogin()} />
  ) : (
    <>
      <Hidden implementation="js" only={["xs", "sm"]}>
        <div className={classes.root}>
          <AppContext.Provider value={{ isAuthenticated, setAuthenticated }}>
            <Drawer
              // className={`${classes.drawer} mobile-drawer`}
              variant="persistent"
              anchor="left"
              open={true}
              classes={{
                paper: classes.menuDrawerPaper,
              }}
            >
              <div className={classes.drawerHeader}>
                <Link to={"/"} title={"Home"} style={{ margin: "0 auto" }}>
                  <p style={{ color: "white", fontSize: "x-large" }}>Oparo</p>
                </Link>
              </div>
              <Divider style={{ backgroundColor: "#181818" }} />
              <List style={{ borderWidth: 0, backgroundColor: "#181818", color: "white" }}>
                <DistressedAssetsTile path="/distressed-properties" size="small" caption="Distressed" popperCaption="All Distressed Properties" />
                <DistressedAssetsTile path="/stressed-properties" size="small" caption="Stressed" popperCaption="All Stressed Properties" />
                <DistressedAssetsTile path="/properties/search" size="small" caption="Property Search" popperCaption="Property Search" />
                <MyPropertyTile path="/my-properties" size="small" caption="Our Properties" popperCaption="Properties of Interest" />
                <HistoricTile path="/historic-properties" size="small" caption="Historic" popperCaption="Properties no longer available" />
                <DeletedPropertiesTile path="/deleted-properties" size="small" caption="Deleted" popperCaption="Properties deleted" />
                {/* <MyDetailsTile path="/profile" size="small" caption="Profile" popperCaption="Show your details" /> */}
                <div className="TileContainer small">
                  <Button className="buttonsidebar" variant="contained" size="small" onClick={handleLogout} style={{ margin: "0 auto" }}>
                    Logout
                  </Button>
                </div>
              </List>
            </Drawer>

            <Routes />

            <CookieConsent message="In using this site you agree to our use of cookies." />
          </AppContext.Provider>
        </div>
      </Hidden>
      <Hidden implementation="js" only={["md", "lg", "xl"]}>
        <div className={classes.rootMobile}>
          <AppContext.Provider value={{ isAuthenticated, setAuthenticated }}>
            <AppBar position="static" className={classes.appBar}>
              <Toolbar>
                <Grid container justifyContent="space-between">
                  <Grid item>
                    <a href="/Homepage.tsx" style={{ margin: "0 auto" }}>
                      <img className="ImageH" src={IconImg} alt="Hammered" height="45" width="45" />
                    </a>
                  </Grid>

                  <Grid item>
                    <IconButton aria-label="open drawer" edge="end" onClick={handleDrawerOpen} className={clsx(open && classes.hide)}>
                      <MenuIcon style={{ fill: "white" }} />
                    </IconButton>
                  </Grid>
                </Grid>
              </Toolbar>
            </AppBar>

            <Drawer
              variant="temporary"
              anchor="right"
              open={open}
              onBackdropClick={handleDrawerClose}
              classes={{
                paper: classes.drawerPaper,
              }}
            >
              <div className={classes.drawerHeader}>
                <IconButton onClick={handleDrawerClose}>
                  {theme.direction === "rtl" ? <ChevronLeftIcon style={{ fill: "white" }} /> : <ChevronRightIcon style={{ fill: "white" }} />}
                </IconButton>
              </div>
              <Divider style={{ backgroundColor: "rgba(0,0,0,0)" }} />
              <List style={{ borderWidth: 0, backgroundColor: "#181818" }}>
                {menuItems.map((item, index) => (
                  <ListItem button key={item.text}>
                    <Link to={item.url} onClick={handleDrawerClose}>
                      <ListItemText primary={item.text} style={{ borderWidth: 0, color: "white" }} />
                    </Link>
                  </ListItem>
                ))}
              </List>
              <Divider style={{ backgroundColor: "rgba(0,0,0,0)" }} />
              <List style={{ borderWidth: 0, backgroundColor: "#181818" }}>
                <ListItem button onClick={handleLogout}>
                  <ListItemText primary="Logout" style={{ borderWidth: 0, color: "white" }} />
                </ListItem>
              </List>
            </Drawer>
            <Routes />
            <CookieConsent message="In using this site you agree to our use of cookies." />
          </AppContext.Provider>
        </div>
      </Hidden>
    </>
  );
}
