import { createStyles, LinearProgress, Theme, withStyles } from '@material-ui/core';

const BorderLinearProgress = withStyles((theme: Theme) =>
  createStyles({
    root: {
      height: 2,
      borderRadius: 5,
    },
    colorPrimary: {
      backgroundColor: theme.palette.grey[theme.palette.type === "light" ? 200 : 700],
    },
    bar: {
      borderRadius: 3,
      backgroundColor: "grey",
    },
  })
)(LinearProgress);

export default BorderLinearProgress;
