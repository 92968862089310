import React, { useEffect, useState } from "react";
import { Button, Grid, TextField, Typography } from "@material-ui/core";
import QueryFormExtras from "../QueryForm/QueryFormExtras";
import Autocomplete from "@material-ui/lab/Autocomplete";

import "./SearchForm.scss";
import { SearchFilterProps } from "./types";
import DistressedAssetsContainer from "./DistressedAssetsContainer";
import { getAutocompletePlaces } from "../../api/Api";
import { transformAPIPlacesListForAutocompleteList } from "../../libs/helpres";

interface Props {
  setFilter?: (filter: SearchFilterProps) => void;
  filter?: SearchFilterProps | null;
  showStressed?: boolean;
}

export default function SearchForm(props: Props) {
  const [showMore, showMoreSet] = useState(false);
  const [places, placesSet] = useState<any[]>([]);
  const [selectedPlace, selectedPlaceSet] = useState<any>(null);
  const stressed = props.showStressed;

  const submitFilter = (filters: SearchFilterProps) => {
    const searchTerm = selectedPlace ? { [selectedPlace.type]: selectedPlace.place } : {};
    const newFilter = { ...filters, ...searchTerm };
    props?.setFilter?.(newFilter);
  };

  useEffect(() => {
    (async () => {
      const places: any[] = await getAutocompletePlaces();
      placesSet(transformAPIPlacesListForAutocompleteList(places));
    })();
  }, []);

  const title = (text) => `${stressed ? "Stressed" : "Distressed"} ${text}`;

  return (
    <>
      <div className="search-form-container">
        <h1>{title("Property Search")}</h1>
        <p style={{ textAlign: "center", width: "100%" }}>Search {title("properties in England and Wales")}</p>

        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <Autocomplete
              id="combo-box-demo"
              options={places}
              getOptionLabel={(option) => option["place"]}
              fullWidth
              style={{ backgroundColor: "white", color: "black", borderRadius: 10 }}
              onChange={(event, value) => selectedPlaceSet(value)}
              renderInput={(params) => <TextField {...params} variant="outlined" placeholder="Postcode, District, Region..." />}
            />
          </Grid>
          {!showMore && (
            <>
              <Grid item xs={6} sm={2}>
                <Button fullWidth className="rounded-button" variant="outlined" onClick={() => submitFilter({ tenure: "freehold", stressed })}>
                  Freehold
                </Button>
              </Grid>
              <Grid item xs={6} sm={2}>
                <Button fullWidth className="rounded-button" variant="outlined" onClick={() => submitFilter({ tenure: "leasehold", stressed })}>
                  Leasehold
                </Button>
              </Grid>
              <Grid item xs={12} sm={2}>
                <Button fullWidth className="rounded-button" variant="outlined" onClick={() => submitFilter({ tenure: "both", stressed })}>
                  Both
                </Button>
              </Grid>
            </>
          )}
          <Grid item xs={12}>
            <Typography variant="caption">
              <button className="box-button-advanced" onClick={() => showMoreSet(!showMore)}>
                Advanced Filters {showMore ? <>&lt;</> : <>&gt;</>}
              </button>
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="caption">
              <Button className="box-button" variant="contained" href="/distressed-properties2">
                Search For Leads as a Table
              </Button>
            </Typography>
          </Grid>
        </Grid>
        {showMore && <QueryFormExtras show={true} submitFilter={submitFilter} />}
      </div>
      <DistressedAssetsContainer justDisplayResults showStressed={stressed} />
    </>
  );
}
