// Snackbar hover bottom - done
// Agree - save cookie consent to localstorage - done
// View Policy - open dialog and display text
// 	- inclulde an Agree button only

import * as React from "react";
import Cookies from "js-cookie";
import Snackbar from "@material-ui/core/Snackbar";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import "./styles.scss";

type Props = {
  cookieName: string;
  cookieValue?: string | boolean | number;
  expires?: number | Date;
  message?: string;
  acceptButtonLabel?: string;
  debug?: boolean;
  extraCookieOptions?: any;
  snackbarAnchor?: {
    horizontal: "left" | "center" | "right";
    vertical: "top" | "bottom";
  };
};
type State = {
  displayCookieBar: boolean;
  displayPolicy: boolean;
  firstDisplay: boolean,
};

const COOKIE_NAME = "siteCookieConsent";

export function taredownCookie(){
  localStorage.setItem(COOKIE_NAME, "");
}

/**
 * This component is the CookieConsent it pops a Snackbar and a Dialog informing the user about cookie consent.
 */
export default class CookieConsent extends React.Component<Props, State> {
  static defaultProps = {
    cookieName: COOKIE_NAME,
    cookieValue: "accepted",
    expires: 365,
    debug: false,
    snackbarAnchor: { horizontal: "center", vertical: "bottom" },
    message: "In using this site you agree to our use of cookies.",
    acceptButtonLabel: "Accept",
  };

  constructor(props: Props) {
    super(props);
    this.state = {
      displayCookieBar: false,
      displayPolicy: false,
      firstDisplay: false,
    };
  }

  componentDidMount() {
    const { cookieName, debug } = this.props;
    if (Cookies.get(cookieName) === undefined || debug) {
      this.setState({ displayCookieBar: true, firstDisplay: true });
    }
  }

  /**
   * Set a persistent cookie
   */
  handleAccept = () => {
    const { cookieName, cookieValue, expires, extraCookieOptions } = this.props;
    Cookies.set(cookieName, cookieValue, { expires, ...extraCookieOptions });
    this.setState({ displayCookieBar: false, displayPolicy: false });
  };

  handleDisplayPolicy = () => {
    this.setState({ displayPolicy: true });
  };

  render() {
    const { message, snackbarAnchor, acceptButtonLabel } = this.props;
    const render: any = [];
    
    if (!this.state.firstDisplay) return null;

      render.push(
        <Snackbar
          anchorOrigin={snackbarAnchor}
          open={this.state.displayCookieBar}
          message={<span id="message-id">{message}</span>}
          action={[
            <Button className="MuiButton-contained-modal" style={{marginRight: '2px'}} size="small" onClick={() => this.setState({ displayPolicy: true })}>
              View Policy
            </Button>,
            <Button key="accept" className="MuiButton-contained-modal" size="small" onClick={this.handleAccept} style={{ color: "white" }}>
              {acceptButtonLabel}
            </Button>,
          ]}
        />
      );


      render.push(
        <Dialog open={this.state.displayPolicy} classes={{ paper: "cookie-consent-dialog" }}>
          <DialogTitle>This site uses cookies to store information on your computer.</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description" component="div">
              <p>
                Some of these cookies are essential, while others help us to improve your experience by providing insights into how the site is being used. 
                </p>
                <p>
                Accepting our recommended settings will set cookies on your device that remember your site preferences and 
                provide us insights into how you use our website which helps us improve your experience.
              </p>
              <p>
              For more information visit our Cookies page.
              </p>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleAccept} className="MuiButton-contained-modal">
              {acceptButtonLabel}
            </Button>
          </DialogActions>
        </Dialog>
      );

    return render;
  }
}
