import React, { useState } from "react";
import ResultLikeTable from "../ResultLikeTable/ResultLikeTable";
import SimpleDialogContainer from "./SimpleDialogContainer";
import { postUndeletePropertyInternal } from "../../api/Api";
import QueryUIs from "../QueryUIs/QueryUIs";

export default function DeletedPropertiesContainer() {
  const [query, setQuery] = useState("");
  const [dialogUndeleteShow, setDialogUndeleteShow] = useState(false);
  const [dialogUndeleteMessage, setDialogUndeleteMessage] = useState("");
  const [dialogUndeleteTitle, setDialogUndeleteTitle] = useState("");
  const [selectedUndeleteRow, setSelectedUndeleteRow] = useState({});
  const [isBusy, setIsBusy] = useState(false);
  const [errorDialogShow, setErrorDialogShow] = useState(false);
  const [errorDialogMessage, setErrorDialogMessage] = useState("");
  const [errorDialogTitle, setErrorDialogTitle] = useState("");

  function undeleteProperty(rowData) {
    setSelectedUndeleteRow(rowData);
    setDialogUndeleteMessage(
      "You are about to move the property to the Distressed Properties list"
    );
    setDialogUndeleteTitle("Confirm Undeletion");
    setDialogUndeleteShow(true);
  }

  async function onUndeleteConfirm() {
    try {
      setIsBusy(true);
      await postUndeletePropertyInternal(selectedUndeleteRow);
    } catch (e: any) {
      setDialogUndeleteShow(false);
      setIsBusy(false);
      displayErrorDialog(e.message);
    }
    setIsBusy(false);
    setDialogUndeleteShow(false);
  }

  function onUndeleteCancel() {
    setDialogUndeleteShow(false);
  }

  function displayErrorDialog(message) {
    setErrorDialogMessage(`Sorry, there was an error:\n"${message}"`);
    setErrorDialogTitle("Undeete Canceled");
    setErrorDialogShow(true);
  }

  function onCloseErrorDialog() {
    setErrorDialogShow(false);
  }

  function updateQuery(_query) {
    setQuery(_query);
  }

  return (
    <>
      <div style={{ textAlign: "center" }}>
        <h1>DELETED PROPERTIES</h1>
        </div>

      <SimpleDialogContainer
        show={dialogUndeleteShow}
        message={dialogUndeleteMessage}
        title={dialogUndeleteTitle}
        onConfirm={onUndeleteConfirm}
        onCancel={onUndeleteCancel}
        lock={isBusy}
      />
      <SimpleDialogContainer
        show={errorDialogShow}
        message={errorDialogMessage}
        title={errorDialogTitle}
        onCancel={onCloseErrorDialog}
      />

      <QueryUIs queryValue={query} onChange={updateQuery} />

      <ResultLikeTable
        tableTitle="Deleted Properties"
        endpointName="deleted-properties"
        query={query}
        actions={[
          {
            icon: "add",
            tooltip: "Move Property to Distressed Assets list",
            onClick: (event, rowData) => undeleteProperty(rowData),
          },
        ]}
        options={{
          actionsColumnIndex: -1,
        }}
      />
    </>
  );
}
