import API from "@aws-amplify/api";
import { Grid, IconButton, makeStyles, createStyles, Checkbox } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { getInitialisedMessage } from "../../libs/session";
import CachedIcon from "@material-ui/icons/Cached";

interface Props {
  companyNumber?: string;
}

interface Officer {
  appointed_on: string;
  name: string;
  officer_role: string;
  link: string;
}

type APIReturn<T> = [string | null, T[] | null];

export const useStyles = makeStyles(() =>
  createStyles({
    rotateIcon: {
      animation: "$spin 2s linear infinite",
    },
    "@keyframes spin": {
      "0%": {
        transform: "rotate(360deg)",
      },
      "100%": {
        transform: "rotate(0deg)",
      },
    },
  })
);

async function getData(companyNumber: string): Promise<APIReturn<Officer>> {
  try {
    const config = await getInitialisedMessage();
    const url = `internal/company/${companyNumber}/officers`;
    const { data = [] }: { data: Officer[] } = await API.get("internal", url, config);
    return [null, data];
  } catch (e: any) {
    return [e["message"] as string, null];
  }
}

async function runData(companyNumber, classes, dataSet, loadingClassSet) {
  if (companyNumber) {
    loadingClassSet(classes.rotateIcon);
    const [err, data] = await getData(companyNumber as string);
    loadingClassSet({});
    if (!err) return dataSet(data ? data : []);
    console.log("[OfficersPanel] getData error: ", err);
  } else {
    console.log({ OfficersPanel: "No companyNumber provided" });
  }
}

export function OfficersPanel(props: Props) {
  const [data, dataSet] = useState<Officer[]>([]);
  const [showOnlyActivePersons, showOnlyActivePersonsSet] = useState(true);
  const [loadingClass, loadingClassSet] = useState<any>({});
  const classes = useStyles();

  useEffect(() => {
    runData(props.companyNumber, classes, dataSet, loadingClassSet);
  }, [props.companyNumber, classes]);

  return (
    <Grid item className="summary" xs={12} md={6}>
      <Grid container justifyContent="space-between">
        <Grid item>
          <h3>officers</h3>
        </Grid>
        <Grid item>
          <IconButton title="Refresh" className={loadingClass} onClick={() => runData(props.companyNumber, classes, dataSet, loadingClassSet)}>
            <CachedIcon />
          </IconButton>
        </Grid>
      </Grid>

      <hr className="short" />

      {data?.length < 1 && (
        <p>
          <i>No Data available</i>
        </p>
      )}
      <table>
        <thead>
          <tr>
            <th style={{ textAlign: "left" }} colSpan={2}>
              Show only active persons <Checkbox checked={showOnlyActivePersons} onClick={() => showOnlyActivePersonsSet(!showOnlyActivePersons)} />
            </th>
          </tr>
        </thead>
        {data
          .filter((a) => (showOnlyActivePersons ? !a["resigned_on"] : true))
          .sort((a, b) => (a.appointed_on > b.appointed_on ? -1 : 1))
          .map((t, idx, arr) => (
            <tbody key={`op-resigned_on-1-${idx}`}>
              <tr>
                <td className="title">{t.officer_role}:</td>
                <td className="value" style={t["resigned_on"] && { textDecoration: "line-through" }}>
                  {t.name}
                </td>
              </tr>
              <tr key={`op-appointed_on-${idx}`}>
                <td className="title">Appointed: </td>
                <td className="value">{t["appointed_on"]}</td>
              </tr>
              <tr key={`op-resigned_on-${idx}`}>
                <td className="title">Resigned: </td>
                <td className="value">{t["resigned_on"]}</td>
              </tr>
              {idx < arr.length - 1 && (
                <tr key={`op-line-${idx}`}>
                  <td colSpan={2}>
                    <hr />
                  </td>
                </tr>
              )}
            </tbody>
          ))}
      </table>
    </Grid>
  );
}
