import React, { useState } from "react";
import "./HomePage.scss";
import { LoginUI, RegisterUI } from "../LoginUIs/index";
import Forgotten from "../LoginUIs/Forgotten";

interface Props {
  onSuccess: () => void;
}
const LOGIN = "LOGIN";
const REGISTER = "REGISTER";
const CONFIRMATION = "CONFIRMATION";

function HomePage(props: Props) {
  // const [showLogin, showLoginSet] = useState(true);

  const [showUI, showUISet] = useState(LOGIN);
  const gotoUI = ui => () => showUISet(ui);

  return (
    <div className="App">
      <div className="login">
        <div className="container">
          {/* {showLogin && <LoginUI onLogin={props.onSuccess} onRegistrationClick={toggleForms} onForgottenPassword={toggleForgottenPassword} />}
          {!showLogin && <RegisterUI onRegisterSuccess={toggleForms} onCancel={toggleForms} />} */}
          {showUI === LOGIN && (
            <LoginUI onLogin={props.onSuccess} onRegistrationClick={gotoUI(REGISTER)} onForgottenPassword={gotoUI(CONFIRMATION)} />
          )}
          {showUI === REGISTER && (
            <RegisterUI onRegisterSuccess={gotoUI(LOGIN)} onCancel={gotoUI(LOGIN)} onForgottenPassword={gotoUI(CONFIRMATION)} />
          )}
          {showUI===CONFIRMATION && (
            <Forgotten onCancel={gotoUI(LOGIN)} onSuccess={gotoUI(LOGIN)} />
          )}
        </div>
      </div>
    </div>
  );
}

export default HomePage;
