import API from "@aws-amplify/api";
import { Grid, IconButton, makeStyles, createStyles } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { getInitialisedMessage } from "../../libs/session";
import CachedIcon from "@material-ui/icons/Cached";
import { deSnake } from "../../libs/helpres";

interface Props {
  companyNumber?: string;
}

interface Financials {}

type APIReturn<T> = [string | null, T[] | null];

export const useStyles = makeStyles(() =>
  createStyles({
    rotateIcon: {
      animation: "$spin 2s linear infinite",
    },
    "@keyframes spin": {
      "0%": {
        transform: "rotate(360deg)",
      },
      "100%": {
        transform: "rotate(0deg)",
      },
    },
  })
);

async function getData(companyNumber: string): Promise<APIReturn<Financials>> {
  try {
    const config = await getInitialisedMessage();
    const url = `internal/company/${companyNumber}/financials`;
    const { data = [] }: { data: Financials[] } = await API.get("internal", url, config);
    return [null, data];
  } catch (e: any) {
    return [e["message"] as string, null];
  }
}

async function runData(companyNumber, classes, dataSet, loadingClassSet) {
  if (companyNumber) {
    loadingClassSet(classes.rotateIcon);
    const [err, data] = await getData(companyNumber as string);
    loadingClassSet({});
    if (!err) return dataSet(data ? data : []);
    console.log("[FinancialsPanel] getData error: ", err);
  } else {
    console.log({ FinancialsPanel: "No companyNumber provided" });
  }
}

export function FinancialsPanel(props: Props) {
  const [data, dataSet] = useState<Financials>({});
  const [loadingClass, loadingClassSet] = useState<any>({});
  const classes = useStyles();

  const companyNumber = props.companyNumber;
  const dataToShow = Object.keys(data);

  useEffect(() => {
    runData(companyNumber, classes, dataSet, loadingClassSet);
  }, [companyNumber, classes]);

  return (
      <Grid item className="summary" xs={12} md={6}>
        <Grid container justifyContent="space-between">
          <Grid item>
            <h3>financials</h3>
          </Grid>
          <Grid item>
            <IconButton title="Refresh" className={loadingClass} onClick={() => runData(companyNumber, classes, dataSet, loadingClassSet)}>
              <CachedIcon />
            </IconButton>
          </Grid>
        </Grid>

        <hr className="short" />

        {dataToShow?.length < 1 && (
          <p>
            <i>No Data available</i>
          </p>
        )}
        <table>
          <tbody>
            {dataToShow.map((key, idx, arr) => (
              <tr key={`fp-${idx}`}>
                <td style={{ width: "50%", textTransform: "capitalize" }}>{deSnake(key)}</td>
                <td style={{ width: "50%", color: posNegColour(data[key]) }}>{data[key]}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </Grid>
  );
}
function posNegColour(data) {
  const result =
    {
      "-": "red",
      "+": "green",
    }[data?.[0][0]] || "";
  return result;
}
