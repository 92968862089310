import React from "react";
import { Auth, API } from "aws-amplify";
import { getInitialisedMessage } from "../libs/session";
import { CardPositions } from "../components/Containers/types";
import { Interface_Profile } from "../components/Profile/sharedTypes";
import { environment } from "../config/config";

export const store: { profile: Interface_Profile | null } = {
  profile: null,
};

export const ProfileContext = React.createContext(null);

function isSubscriptionActive(profile) {
  if (!profile.date_subscription_ends) return false;
  const endDate = profile.date_subscription_ends.substr(0, 10);
  const today = new Date().toJSON().substr(0, 10);
  const result = new Date(endDate) >= new Date(today);
  return result;
}

function canPurcase(profile) {
  if (!isSubscriptionActive(profile)) return false;
  return true;
}

export async function profileGet(force = false) {
  if (store.profile && !force) return store.profile;
  try {
    const headers = await getInitialisedMessage();
    const sub = await getSub();
    const { data } = await API.get("backend", `user/${sub}`, headers);
    store.profile = data;
    if (store && store.profile) {
      // @ts-ignore
      store.profile.isSubscriptionActive = isSubscriptionActive(store.profile);
      // @ts-ignore
      store.profile.canPurchase = canPurcase(store.profile);
      store.profile.subscriptionType = store.profile.subscriptionType || "none";
    }
    return data;
  } catch (e: any) {
    console.log("ERROR: profileGet, e= ", e);
    return null;
  }
}

export async function clearProfileStore() {
  store.profile = null;
}

export async function profilePatch(body) {
  try {
    const headers = await getInitialisedMessage(body);
    const sub = await getSub();
    const { data } = await API.patch("backend", `user/${sub}`, headers);
    if (!data.in_one_place_code) data.in_one_place_code = "";
    return data;
  } catch (e: any) {
    console.log("ERROR: profilePatch, e= ", e);
    return null;
  }
}

export async function notificationsPatch(body) {
  try {
    const headers = await getInitialisedMessage(body);
    const sub = await getSub();
    const { data } = await API.patch("backend", `user/${sub}/notifications`, headers);
    return data;
  } catch (e: any) {
    console.log("ERROR: profilePatch, e= ", e);
    return null;
  }
}

export async function notificationsGet() {
  try {
    const headers = await getInitialisedMessage();
    const sub = await getSub();
    const { data } = await API.get("backend", `user/${sub}/notifications`, headers);

    return data;
  } catch (e: any) {
    console.log("ERROR: notificationsGet, e= ", e);
    return null;
  }
}

export async function getSub() {
  const cs = await Auth.currentSession();
  const sub = cs.getAccessToken().payload.sub;
  return sub;
}

export async function doLogin({ email, password }) {
  try {
    await Auth.signIn(email, password);
    return true;
  } catch (e: any) {
    console.log("ERROR: doLogin, e= ", e);
    return e.message;
  }
}

export async function doRegistration({ email, password, name, company, phone }) {
  try {
    await Auth.signUp({
      username: email,
      password,
      attributes: {
        name,
        phone_number: phone,
        profile: company,
      },
    });
    return true;
  } catch (e: any) {
    console.log("ERROR: doRegistration, e= ", e);
    return e.message;
  }
}

export async function doConfirmation({ email, confirmationCode, password }) {
  try {
    await Auth.confirmSignUp(email, confirmationCode);
    await Auth.signIn(email, password);
    return true;
  } catch (e: any) {
    console.log("ERROR; doConfirmation, e=", e);
    return e.message;
  }
}

export async function checkPasscodeIsUnused(passcode) {
  const config = {
    contentType: "application/json",
    body: { passcode },
  };
  const { data } = await API.post("backend", `user/passcode-check`, config);
  if (data.message === "passcode not in use") {
    return true;
  }
  console.log(data);
  throw new Error(`Passcode invalid: ${data.message}`);
}

export async function postPurchaseProperty(table_id) {
  const config = await getInitialisedMessage({ table_id });
  const sub = await getSub();
  const { data } = await API.post("backend", `user/${sub}/my-properties`, config);
  if (data.message === "ok") {
    await profileGet(true);
    return true;
  }
  throw new Error(`Purchase cancelled: ${data.message}`);
}

export async function postPurchasePropertyInternal(table_id, stressed: boolean = false) {
  const config = await getInitialisedMessage({ table_id, stressed });
  const sub = await getSub();
  const { data } = await API.post("internal", `internal/${sub}/our-properties`, config);
  if (data.message === "ok") {
    await profileGet(true);
    return true;
  }
  throw new Error(`Track property cancelled: ${data.message}`);
}

export async function postDeleteProperty(table_id) {
  const config = await getInitialisedMessage({ table_id });
  const sub = await getSub();
  const { data } = await API.post("backend", `user/${sub}/deleted-properties`, config);
  if (data.message === "ok") {
    return true;
  }
  throw new Error(`Deletion cancelled: ${data.message}`);
}

export async function postDeletePropertyInternal(table_id) {
  const config = await getInitialisedMessage({ table_id });
  const sub = await getSub();
  const { data } = await API.post("internal", `internal/${sub}/deleted-properties`, config);
  if (data.message === "ok") {
    return true;
  }
  throw new Error(`Deletion cancelled: ${data.message}`);
}

export async function postUndeleteProperty(property) {
  const sub = await getSub();
  const { title_number, company_number } = property;
  const config = await getInitialisedMessage({ title_number, company_number });
  const { data } = await API.post("backend", `user/${sub}/distressed-properties`, config);
  if (data.message === "ok") {
    return true;
  }
  throw new Error(`Undeletion cancelled: ${data.message}`);
}

export async function postUndeletePropertyInternal(property) {
  const sub = await getSub();
  const { title_number, company_number } = property;
  const config = await getInitialisedMessage({ title_number, company_number });
  const { data } = await API.post("internal", `internal/${sub}/distressed-properties`, config);
  if (data.message === "ok") {
    return true;
  }
  throw new Error(`Undeletion cancelled: ${data.message}`);
}

export async function updateFirebaseToken(token) {
  const sub = await getSub();
  const debug = window.location.hostname.indexOf("localhost") >= 0 || window.location.hostname.indexOf("fx-dev") >= 0;
  const config = await getInitialisedMessage({ token, debug });

  const { data } = await API.post("backend", `user/${sub}/update-firebase-token`, config);
  if (data.message === "ok") {
    return true;
  }
  throw new Error(`updateFirebaseToken cancelled: ${data.message}`);
}

export async function messagePost(body) {
  try {
    const sub = await getSub();
    const config = await getInitialisedMessage(body);
    const { data } = await API.post("backend", `user/${sub}/message`, config);
    if (typeof data.affected_rows === "number") return true;
    if (typeof data.affected_rows === "string") return data.affected_rows;
    return data;
  } catch (e: any) {
    console.log("ERROR: messagePost, e= ", e);
    return null;
  }
}

export async function saveMyPropertyPositions(cardPositions: CardPositions[]) {
  const sub = await getSub();
  const config = await getInitialisedMessage({ cardPositions });

  const { data } = await API.post("internal", `internal/${sub}/update-my-property-metadata`, config);
  if (data.message.indexOf("updated") >= 0) {
    return true;
  }
}

export async function getPropertyByTitleNumber(title_number) {
  try {
    const config = await getInitialisedMessage();
    const sub = await getSub();
    const { data=[] } = await API.get("internal", `internal/${sub}/property-by-title/${title_number}`, config);
    return data;
  } catch (e: any) {
    console.log("ERROR: getProperty, e= ", e);
    return {data: null};
  }
}

export async function getPropertyByUPRN(uprn) {
  try {
    const config = await getInitialisedMessage();
    const sub = await getSub();
    const { data: { data } } = await API.get("internal", `internal/${sub}/property-by-uprn/${uprn}`, config);
    return data;
  } catch (e: any) {
    console.log("ERROR: getProperty, e= ", e);
    return {data: null};
  }
}

export async function getPropertyInternal(table_id, propertyStressType = "distressed") {
  try {
    const config = await getInitialisedMessage();
    const sub = await getSub();
    const { data } = await API.get("internal", `internal/${sub}/my-properties/${table_id}/${propertyStressType}`, config);
    return data;
  } catch (e: any) {
    console.log("ERROR: getProperty, e= ", e);

    return null;
  }
}

function objectToQueryString(object) {
  let result = Object.keys(object)
    .map((key) => `${key}=${object[key]}`)
    .join("&");
  if (result) result = "?" + result;
  return result;
}
export async function getPropertyCalculation(postcode, params) {
  try {
    const config = await getInitialisedMessage();
    const sub = await getSub();
    let queryString = objectToQueryString(params);
    const { data } = await API.get("backend", `user/${sub}/property-calculation/prices/${postcode}${queryString}`, config);
    return data;
  } catch (e: any) {
    console.log("ERROR: getPropertyCalculation, e= ", e);

    return null;
  }
}

export async function getPropertyCalculationPricePerSqFoot(postcode, params) {
  try {
    const config = await getInitialisedMessage();
    const sub = await getSub();
    const queryString = objectToQueryString(params);
    const { data } = await API.get("backend", `user/${sub}/property-calculation/prices-per-sq-foot/${postcode}${queryString}`, config);
    return data;
  } catch (e: any) {
    console.log("ERROR: getPropertyCalculationPricePerSqFoot, e= ", e);

    return null;
  }
}

export async function getPropertyCalculationSoldPrices(postcode, params) {
  try {
    const config = await getInitialisedMessage();
    const sub = await getSub();
    const queryString = objectToQueryString(params);
    const { data } = await API.get("backend", `user/${sub}/property-calculation/sold-prices/${postcode}${queryString}`, config);
    return data;
  } catch (e: any) {
    console.log("ERROR: getPropertyCalculationSoldPrices, e= ", e);

    return null;
  }
}

export async function getPropertyCalculationSoldPricesPerSqFoot(postcode, params) {
  try {
    const config = await getInitialisedMessage();
    const sub = await getSub();
    const queryString = objectToQueryString(params);
    const { data } = await API.get("backend", `user/${sub}/property-calculation/sold-prices-per-sqf/${postcode}${queryString}`, config);
    return data;
  } catch (e: any) {
    console.log("ERROR: getPropertyCalculationSoldPricesPerSqFoot, e= ", e);
    return null;
  }
}

export async function getPropertyCalculationStampDuty(postcode, params) {
  try {
    const config = await getInitialisedMessage();
    const sub = await getSub();
    const queryString = objectToQueryString(params);
    const { data } = await API.get("backend", `user/${sub}/property-calculation/stamp-duty/${postcode}${queryString}`, config);
    return data;
  } catch (e: any) {
    console.log("ERROR: getPropertyCalculationStampDuty, e= ", e);
    return null;
  }
}

export async function getPropertyCalculationRents(postcode, params) {
  try {
    const config = await getInitialisedMessage();
    const sub = await getSub();
    const queryString = objectToQueryString(params);
    const { data } = await API.get("backend", `user/${sub}/property-calculation/rents/${postcode}${queryString}`, config);
    return data?.data?.long_let || data;
  } catch (e: any) {
    console.log("ERROR: getPropertyCalculationRents, e= ", e);
    return null;
  }
}

export async function getPropertyCalculationYields(postcode, params) {
  try {
    const config = await getInitialisedMessage();
    const sub = await getSub();
    const queryString = objectToQueryString(params);
    const { data } = await API.get("backend", `user/${sub}/property-calculation/yields/${postcode}${queryString}`, config);
    return data?.data?.long_let || data;
  } catch (e: any) {
    console.log("ERROR: getPropertyCalculationYields, e= ", e);
    return null;
  }
}

export async function getPropertySocialYields(postcode, params) {
  try {
    const config = await getInitialisedMessage();
    const sub = await getSub();
    const queryString = objectToQueryString(params);
    const { data } = await API.get("backend", `user/${sub}/property-calculation/social/${postcode}${queryString}`, config);
    return data?.data?.long_let || data;
  } catch (e: any) {
    console.log("ERROR: getPropertySocialYields, e= ", e);
    return null;
  }
}

export async function getPropertyCalculationRentsHMO(postcode, params) {
  try {
    const config = await getInitialisedMessage();
    const sub = await getSub();
    const queryString = objectToQueryString(params);
    const { data } = await API.get("backend", `user/${sub}/property-calculation/rents-mo/${postcode}${queryString}`, config);
    return data?.data || data;
  } catch (e: any) {
    console.log("ERROR: getPropertyCalculationRents, e= ", e);
    return null;
  }
}

export async function getPropertyCalculationPropertyValuationSale(postcode, params) {
  try {
    const config = await getInitialisedMessage();
    const sub = await getSub();
    const queryString = objectToQueryString(params);
    const { data } = await API.get("backend", `user/${sub}/property-calculation/property-valuation-sale/${postcode}${queryString}`, config);
    return data;
  } catch (e: any) {
    console.log("ERROR: getPropertyCalculationPropertyValuationSale, e= ", e);
    return null;
  }
}

export async function getPropertyCalculationDevelopmentCalculator(postcode, params) {
  try {
    const config = await getInitialisedMessage();
    const sub = await getSub();
    const queryString = objectToQueryString(params);
    const { data } = await API.get("backend", `user/${sub}/property-calculation/development-calculator/${postcode}${queryString}`, config);
    return data;
  } catch (e: any) {
    console.log("ERROR: getPropertyCalculationDevelopmentCalculator, e= ", e);
    return null;
  }
}

export async function getPropertyCalculationDevelopmentMultUnit(postcode, params) {
  try {
    const config = await getInitialisedMessage();
    const sub = await getSub();
    const queryString = objectToQueryString(params);
    const { data } = await API.get("backend", `user/${sub}/property-calculation/development-multi-unit/${postcode}${queryString}`, config);
    return data;
  } catch (e: any) {
    console.log("ERROR: getPropertyCalculationDevelopmentMultUnit, e= ", e);
    return null;
  }
}

export async function getPropertyCalculationPropertyValuationRental(postcode, params) {
  try {
    const config = await getInitialisedMessage();
    const sub = await getSub();
    const queryString = objectToQueryString(params);
    const { data } = await API.get("backend", `user/${sub}/property-calculation/property-valuation-rental/${postcode}${queryString}`, config);
    return data;
  } catch (e: any) {
    console.log("ERROR: getPropertyCalculationPropertyValuationRental, e= ", e);
    return null;
  }
}

export async function getStripeCustomerPortalUrl() {
  let mode: string = "test";
  if (environment === "prod") {
    mode = "live";
  }

  try {
    const body = {
      returnUrl: window.location.href,
      mode,
    };
    const config = await getInitialisedMessage(body);
    const sub = await getSub();
    const url = `user/${sub}/customer-portal`;
    const { data } = await API.post("backend", url, config);
    window.open(data.url, "_blank");
  } catch (e: any) {
    console.log("ERROR: getStripeCustomerPortalUrl, e= ", e);
    return null;
  }
}

export async function getAutocompletePlaces() {
  const KEY = "autocomplete_list";
  if (localStorage?.[KEY]) {
    try {
      const result = JSON.parse(localStorage?.[KEY]);
      return result;
    } catch (e) {}
  }
  try {
    const config = await getInitialisedMessage();
    const sub = await getSub();
    const { data = [] } = await API.get("backend", `user/${sub}/autocomplete_list`, config);
    localStorage[KEY] = JSON.stringify(data);
    const result = data;
    return result;
  } catch (e: any) {
    console.log("ERROR: getAutocompletePlaces, e= ", e);
    return null;
  }
}

export async function getDatesPropertiesAdded(): Promise<string[]> {
  // reach out and collect data
  const todaysDate = new Date().toJSON().substr(0, 10);
  const DATES_ADDED_DATE_LABEL = "dateAddedDate";
  const DATES_ADDED_DATA_LABEL = "dateAddedData";
  let data = [];

  if (!localStorage?.[DATES_ADDED_DATA_LABEL] || !localStorage?.[DATES_ADDED_DATE_LABEL] || localStorage[DATES_ADDED_DATE_LABEL] < todaysDate) {
    try {
      const config = await getInitialisedMessage();
      const sub = await getSub();
      const { data } = await API.get("backend", `user/${sub}/dates-added`, config);
      // cache data
      localStorage.setItem(DATES_ADDED_DATE_LABEL, todaysDate);
      localStorage.setItem(DATES_ADDED_DATA_LABEL, JSON.stringify(data));
      return data;
    } catch (e: any) {
      console.log("ERROR: getDatesPropertiesAdded, e= ", e);
    }
  }

  try {
    data = JSON.parse(localStorage[DATES_ADDED_DATA_LABEL]);
    data = data.sort((a, b) => (a < b ? 1 : -1));
  } catch (e: any) {
    // if there's an error in the stored data, kill it off
    localStorage.setItem(DATES_ADDED_DATE_LABEL, todaysDate);
    localStorage.setItem(DATES_ADDED_DATA_LABEL, JSON.stringify(data));
  }
  return data;
}

let permissionCache = { cache: {}, status: "empty", buffer: [] };
export async function getPermissions(): Promise<any> {
  if (permissionCache.status === "populated") return permissionCache.cache;

  if (permissionCache.status === "pending") {
    const responsePromise = new Promise(() => {
      return permissionCache.cache;
    });
    // @ts-ignore
    permissionCache.buffer.push(responsePromise);
    return responsePromise;
  }
  permissionCache.status = "pending";

  try {
    const config = await getInitialisedMessage();
    const sub = await getSub();
    const { data = [] } = await API.get("backend", `user/${sub}/permissions`, config);
    const permissions = data.reduce((a, c) => Object.assign(a, { [c.name]: c }), {});
    permissionCache.cache = permissions;
    permissionCache.status = "populated";
    // @ts-ignore
    permissionCache.buffer.forEach((promise: Promise) => {
      promise.then((...args) => {
        console.log({ args, cache: permissionCache.cache });
        return permissionCache.cache;
      });
    });
    // permissionCache.buffer = [];
    return permissionCache.cache;
  } catch (e: any) {
    console.log("ERROR: getPermissions, e= ", e);
    return null;
  }
}

export async function processPropertyData(postcode: string): Promise<any> {
  try {
    const config = await getInitialisedMessage();
    const sub = await getSub();
    const { data = [] } = await API.get("backend", `user/${sub}/property-data/${postcode}`, config);
    return data;
  } catch (e: any) {
    console.log("ERROR: getPermissions, e= ", e);
    return null;
  }
}

export async function getPropertyPolygon(titleNumber: string, topLeft: string, bottomRight: string): Promise<any> {
  try {
    const config = await getInitialisedMessage();
    const sub = await getSub();
    const { data = [] } = await API.get("backend-users", `user/${sub}/property-polygon2/${titleNumber}?topLeft=${topLeft}&bottomRight=${bottomRight}`, config);
    return data;
  } catch (e: any) {
    console.log("ERROR: getPermissions, e= ", e);
    return null;
  }
}

function retrieveCachedData(cacheLabel: string): any[] | null {
  let data = null;
  if (localStorage?.[cacheLabel]) {
    try {
      const jsonData = localStorage.getItem(cacheLabel);
      if (jsonData) {
        const data = JSON.parse(jsonData);
        return data;
      }
      localStorage.removeItem(cacheLabel);
    } catch (e) {
      console.error("Problem retrieving data in retrieveCachedData", e);
      localStorage.removeItem(cacheLabel);
    }
  }
  return data;
}

function storeCachedData(cacheLabel: string, data: any[]): boolean {
  try {
    const jsonData = JSON.stringify(data);
    localStorage.setItem(cacheLabel, jsonData);
    return true;
  } catch (e) {
    console.error("Problem saving data in storeCachedData", e);
    return false;
  }
}

export async function getHistoricBuildings(topLeft: string, bottomRight: string): Promise<any[]> {
  const CACHE_LABEL = `historic_buildings_cache_${topLeft}${bottomRight}`;
  const data = retrieveCachedData(CACHE_LABEL);
  if (data) return data;

  try {
    const config = await getInitialisedMessage();
    const sub = await getSub();
    const { data } = await API.get("backend", `user/${sub}/layers/historic_buildings?topLeft=${topLeft}&bottomRight=${bottomRight}`, config);
    if (data && data.length > 0) {
      storeCachedData(CACHE_LABEL, data);
    }
    return data;
  } catch (e: any) {
    console.log("ERROR: getHistoricBuildings, e= ", e);
    return [];
  }
}

export async function getFloodRiskaAreas(topLeft: string, bottomRight: string): Promise<any[]> {
  try {
    const config = await getInitialisedMessage();
    const sub = await getSub();
    const { data = [] } = await API.get("backend", `user/${sub}/layers/flood_risk?topLeft=${topLeft}&bottomRight=${bottomRight}`, config);
    const coordArrays = data.map((d) => JSON.parse(d.coordinates));

    const result = coordArrays.map((ca) => ca.map((fz) => [fz[1], fz[0]]));
    return result || [];
  } catch (e: any) {
    console.log("ERROR: getFloodRiskaAreas, e= ", e);
    return [];
  }
}

export async function getConversationAreas(topLeft: string, bottomRight: string): Promise<any[]> {
  try {
    const config = await getInitialisedMessage();
    const sub = await getSub();
    const { data = [] } = await API.get("backend", `user/${sub}/layers/conservation_area?topLeft=${topLeft}&bottomRight=${bottomRight}`, config);
    const coordArrays = data.map((d) => JSON.parse(d.coordinates));

    const result = coordArrays.map((ca) => ca.map((fz) => [fz[1], fz[0]]));
    return result || [];
  } catch (e: any) {
    console.log("ERROR: getConversationAreas, e= ", e);
    return [];
  }
}

export async function getPlanningPoints(topLeft: string, bottomRight: string): Promise<any[]> {
  const CACHE_LABEL = `planning_points_cache_${topLeft}${bottomRight}`;
  const data = retrieveCachedData(CACHE_LABEL);
  if (data) return data;

  try {
    const config = await getInitialisedMessage();
    const sub = await getSub();
    const { data } = await API.get("backend", `user/${sub}/layers/planning?topLeft=${topLeft}&bottomRight=${bottomRight}`, config);
    if (data && data.length > 0) {
      storeCachedData(CACHE_LABEL, data);
    }
    return data.data;
  } catch (e: any) {
    console.log("ERROR: getPlanningPoints, e= ", e);
    return [];
  }
}

export async function getPostcodePolygon(postcode, options: { skipCache: boolean } = { skipCache: false }): Promise<any[]> {
  if (!postcode) return [];
  let pm = false;
  const outcode_area = postcode
    .split("")
    .filter((c) => {
      if (!pm && c >= "A" && c <= "Z") return true;
      pm = true;
      return false;
    })
    .join("");
  try {
    const CACHE_LABEL = `pp_cache_${outcode_area}`;
    let data = retrieveCachedData(CACHE_LABEL);

    if (!data || options?.skipCache) {
      let url = `${process.env.PUBLIC_URL}/postcodes/${outcode_area.toUpperCase()}.geojson`;
      const response = await fetch(url);
      data = await response.json();
      if (data) {
        console.info('[getPostcodePolygon]', 'downloading polygon to browser cache');
        storeCachedData(CACHE_LABEL, data);
      }
    } else {
      console.info('[getPostcodePolygon]', 'polygon retrieved from browser cache');
    }

    let coordArrays = data?.["features"]
      .map((feature) => {
        if (feature.properties.name === postcode) {
          return feature.geometry.coordinates?.[0];
        }
        return undefined;
      })
      .filter((a) => a);

    // @ts-ignore
    const result = coordArrays.map((ca) => ca.map((fz) => [fz[1], fz[0]]));
    return result || [];
  } catch (e: any) {
    console.log("ERROR: getPostcodePolygon, e= ", e);
    return [];
  }
}

export async function getAllPostcodes(postcode, options: { skipCache: boolean } = { skipCache: false }): Promise<any[]> {
  if (!postcode) return [];
  let pm = false;
  const outcode_area = postcode
    .split("")
    .filter((c) => {
      if (!pm && c >= "A" && c <= "Z") return true;
      pm = true;
      return false;
    })
    .join("");
  try {
    const CACHE_LABEL = `ap_cache_${outcode_area}`;
    let data = retrieveCachedData(CACHE_LABEL);

    if (!data || options?.skipCache) {
      const sub = await getSub();
      let url = `${process.env.PUBLIC_URL}/user/${sub}/all-properties`;
      const response = await fetch(url);
      data = await response.json();
      if (data) {
        storeCachedData(CACHE_LABEL, data);
      }
    }

    return data || [];
  } catch (e: any) {
    console.log("ERROR: getPostcodePolygon, e= ", e);
    return [];
  }
}
