import React, { useState } from "react";
import { Query, Builder, BasicConfig, Utils as QbUtils, JsonGroup, Config } from "react-awesome-query-builder";
import "./styles.scss";
import PropertyTypes from "./propertyTypes.json";
import "./QueryBuilder.scss";
import { Checkbox } from "@material-ui/core";

const InitialConfig = BasicConfig;

// You need to provide your own config. See below 'Config format'
const textOperators = ["equal", "not_equal", "like", "not_like", "starts_with", "ends_with", "is_empty", "is_not_empty"];
const numberOperators = ["less", "less_or_equal", "greater", "greater_or_equal", "between"];
const dateOperators = ["equal", "less", "less_or_equal", "greater", "greater_or_equal", "between", "not_between"];
const config: Config = {
  ...InitialConfig,
  fields: {
    postcode: {
      label: "Postcode",
      type: "text",
      valueSources: ["value"],
      operators: textOperators,
    },
    tenure: {
      label: "Tenure",
      type: "select",
      valueSources: ["value"],
      listValues: [
        { value: "leasehold", title: "Leasehold" },
        { value: "freehold", title: "Freehold" },
      ],
    },
    district: {
      label: "District",
      type: "text",
      valueSources: ["value"],
      operators: textOperators,
    },
    county: {
      label: "County",
      type: "text",
      valueSources: ["value"],
      operators: textOperators,
    },
    region: {
      label: "Region",
      type: "text",
      valueSources: ["value"],
      operators: textOperators,
    },
    total_floor_area: {
      label: "Floor Size",
      type: "number",
      fieldSettings: {
        min: 0,
      },
      valueSources: ["value"],
      preferWidgets: ["number"],
      operators: numberOperators,
    },
    property_type: {
      label: "Property Type",
      type: "select",
      valueSources: ["value"],
      listValues: PropertyTypes,
    },
    price_paid: {
      label: "Price Paid",
      type: "number",
      fieldSettings: {
        min: 0,
      },
      valueSources: ["value"],
      preferWidgets: ["number"],
      operators: numberOperators,
    },
    date_added: {
      label: "Date Added",
      type: "date",
      valueSources: ["value"],
      preferWidgets: ["date"],
      operators: dateOperators,
    },
  },
};

// You can load query value from your backend storage (for saving see `Query.onChange()`)
const _queryValue: JsonGroup = { id: QbUtils.uuid(), type: "group" };

interface Props {
  onChange: Function;
  queryValue?: JsonGroup;
  show?: boolean;
  toggleFilter?: Function;
  filterState?: boolean;
}

function DemoQueryBuilder(props: Props) {
  const [state, setState] = useState({
    tree: QbUtils.checkTree(QbUtils.loadTree(props.queryValue || _queryValue), config),
    config: config,
  });

  const propsOnChange = props.onChange;
  const toggleFilter = props.toggleFilter;
  const filterState = props.filterState;

  return (
    <div>
      {window.location.href.includes("localhost") && renderResult(state)}
      <Query {...config} value={state.tree} onChange={onChange} renderBuilder={renderBuilder} />
    </div>
  );

  function renderBuilder(props) {
    return (
      <div className="query-builder-container">
        <div className="query-builder qb-lite">
          <h2>Property Search</h2>
          <Checkbox checked={filterState} onClick={() => toggleFilter && toggleFilter()} /> Use Advanced Filter
          <Builder {...props} />
          <button disabled={state.tree.size <= 2} onClick={() => propsOnChange(QbUtils.sqlFormat(state.tree, state.config))}>
            Update Table
          </button>
        </div>
      </div>
    );
  }

  function renderResult({ tree: immutableTree, config }) {
    return <div className="query-builder-result" style={{ color: "white" }}></div>;
  }

  function onChange(immutableTree, config) {
    // Tip: for better performance you can apply `throttle` - see `examples/demo`
    setState({ tree: immutableTree, config: config });
    // `jsonTree` can be saved to backend, and later loaded to `queryValue`
  }
}

export default DemoQueryBuilder;
