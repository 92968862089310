import { CircularProgress } from "@material-ui/core";
import React, { useState } from "react";
import { PropertyData } from "./api";

interface UprnButtonWithSearchProps {
  onClick: Function;
  uprn: string;
  uprnParent?: string;
  searchingUprn: string;
  idx?: number;
}

function UprnButtonWithSearch(props: UprnButtonWithSearchProps) {
  const { searchingUprn, uprn, onClick, idx = null } = props;
  if (!uprn) return null;
  if (searchingUprn === uprn) return <CircularProgress size={30} />;
  return <button onClick={() => onClick(uprn, idx)}>{uprn}</button>;
}

const styles = `
  .results-div {
    font-size: 13px;
    max-height: 600px;
    overflow-y: scroll;
    margin: 0 auto;
    max-width: 850px;
    margin: 10px auto;
    background: #fff;
    border: 2px solid #000;
    color: black;              
  }
  .results-div table {
    border-collapse: collapse;
  }
  .results-div table thead > tr {
    background-color: rgb(228, 240, 245);
    border-bottom: 1px black solid;
  }
  .results-div table tbody tr {
    border-bottom: 1px black solid;
  }
  .results-div table tbody tr:hover {
    background: lightgrey
  }
  .results-div table {
    width: 100%;
  }
  .results-div table tbody tr.selected-row {
    background: yellow;
  }
  .results-div table tbody tr.selected-row-2 {
    background: deepskyblue;
  }
`;

interface PropertySearchResultsTableProps {
  data: PropertyData[];
  searchForTitleNumber?: (uprn: string, parentUprn?: string) => any;
}

export default function PropertySearchResultsTable(props: PropertySearchResultsTableProps) {
  const [parentUprn, parentUprnSet] = useState("");
  const [ownUprn, ownUprnSet] = useState("");
  const [searchingUprn, searchingUprnSet] = useState("");
  const { data } = props;

  const doSearchForTitleNumber = async (uprn, parentUprn?) => {
    // trying to be clever and not use if..then
    void (props.searchForTitleNumber && [searchingUprnSet(uprn), props.searchForTitleNumber(uprn, parentUprn).finally(() => searchingUprnSet(""))]);
  };

  return (
    <>
      <style>{styles}</style>
      {data.length && (
        <div className="results-div">
          <table>
            <thead>
              <tr>
                <th>address</th>
                <th>uprn</th>
                <th>parent_uprn</th>
                <th>title number</th>
              </tr>
            </thead>
            <tbody>
              {data.map((item, idx) => (
                <tr
                  key={`table+${idx}`}
                  onMouseEnter={() => [parentUprnSet(item["parent_uprn"]), ownUprnSet(item["uprn"])]}
                  onMouseLeave={() => [parentUprnSet(""), ownUprnSet("")]}
                  className={parentUprn === item["uprn"] ? "selected-row" : ownUprn === item["parent_uprn"] ? "selected-row-2" : ""}
                >
                  <td>{item["address"]}</td>
                  <td style={{ textAlign: "center" }}>
                    <UprnButtonWithSearch searchingUprn={searchingUprn} uprn={item["uprn"]} uprnParent={item["parent_uprn"]} onClick={doSearchForTitleNumber} />
                  </td>
                  <td style={{ textAlign: "center" }}>
                    <UprnButtonWithSearch searchingUprn={searchingUprn} idx={idx} uprn={item["parent_uprn"]} onClick={doSearchForTitleNumber} />
                  </td>
                  <td>
                    {item["title_number"] && (
                      <a href={`/property?title_number=${item["title_number"]}&postcode=${item["postcode_locator"]}&uprn=${item["uprn"]}`}>
                        <button style={{ backgroundColor: "brown" }}>{item["title_number"]}</button>
                      </a>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </>
  );
}
