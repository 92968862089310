export function numberWithCommas(x, prefix = "") {
  if (x === "N/A" || x === null) return x;
  if (x === undefined) return "";
  return prefix + x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export function transformAPIPlacesListForAutocompleteList(places) {
  const postcodes = {};
  const districts = {};
  const regions = {};
  places.forEach((p) => {
    p.postcode && (postcodes[p.postcode] = { type: "postcode", place: p.postcode });
    p.district && (districts[p.district] = { type: "district", place: p.district });
    p.region && (regions[p.region] = { type: "region", place: p.region });
  });
  let results: any[] = [];
  [postcodes, districts, regions].forEach((list) => {
    Object.keys(list).map((key) => !!list[key] && results.push(list[key]));
  });
  results = results.sort((a, b) => (a.place < b.place ? -1 : 1));
  return results;
}

export function toTitleCase(t: string) {
  return t.toLowerCase().split("").map(a=>a==="_"?" ":a).join("").split(" ").map((a) => a.charAt(0).toUpperCase() + a.substr(1)).join(" ");
}

export const sortDesc = field => (a, b) => (a[field] < b[field] ? 1 : -1);
export const sortAsc = field => (a, b) => (a[field] > b[field] ? 1 : -1);

export function deSnake(text: string): string {
  return text.replaceAll("_", " ");
}

export function toValue(value) {
  return value || null;
}
