
export function Lines(props) {
  const { lines = [] } = props;

  return lines
    .filter((a) => typeof a === "string")
    .map((l, idx) => (
      <span key={`line-${idx}`}>
        {l}
        <br />
      </span>
    ));
}
