import React from "react";
import Chart from "react-google-charts";
import { ChartWrapperOptions } from "react-google-charts/dist/types";

interface Props {
  data: [];
  hasLoaded: boolean;
  options?: ChartWrapperOptions["options"];
  keyValue?: number;
}

const DEFAULT_OPTIONS = {
  title: "Line Graph",
  colors: ["blue"],
  backgroundColor: "black",
  legend: {position: 'none'},
};

export default function GenericLineGraph(props: Props) {
  const { data, hasLoaded, options=DEFAULT_OPTIONS, keyValue=0 } = props;

  if (hasLoaded) {
    return (
      <Chart
        style={{
          margin: "0 auto",
          width: "90%",
        }}
        chartType="Line"
        data={data}
        options={options}
        key={keyValue}
      />
    );
  }
  return null;
}
