import React, { useState } from "react";
import "./PropertyDetails.scss";
import { Grid, Container, Button, Tabs, Tab, makeStyles, Theme, Box, CircularProgress } from "@material-ui/core";
import { numberWithCommas, toValue } from "../../libs/helpres";
import { PermissionType } from "../PermissionedContent/PermissionedContent";
import { PROPERTY_TYPE_LOOKUP } from "../SearchResultTiles/SearchResultTiles";
import CalculatorMenu from "../Calculators/Calculators";
import Maps from "./Maps";
import { OfficersPanel } from "./OfficersPanel";
import { ChargesPanel } from "./ChargesPanel";
import { EstablishmentsPanel } from "./EstablishmentsPanel";
import { FinancialsPanel } from "./FinancialsPanel";
import { CompanySummaryPanel } from "./CompanySummaryPanel";
import { CompanySummary } from "./CompanySummary.type";
import { LatestNoticesPanel } from "./LatestNoticesPanel";
import { CreditHistoryPanel } from "./CreditHistoryPanel";

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

const YES_NO = {
  true: "Yes",
  false: "No",
  "?": "n/a",
  undefined: "n/a",
  null: "n/a",
  "": "n/a",
};
const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
    backgroundColor: theme.palette.background.paper,
    color: "black",
  },
}));
const DEFAULT_TAB = 0;

export function PropertyDetails(props) {
  const [tabsValue, tabsValueSet] = useState(DEFAULT_TAB);
  const classes = useStyles();

  if (props.isLoading) {
    return (
      <Container style={{ color: "white" }}>
        <div className="property-title-container">
          <i>Loading...</i>
        </div>
      </Container>
    );
  }

  if (props.noDataAvailable) {
    return (
      <Container style={{ color: "white" }}>
        <div className="property-title-container">
          <i>Property details are not available</i>
        </div>
      </Container>
    );
  }

  const { postcode, property_address, property_type, latlng } = props.property;
  const outcode = postcode?.split(" ")?.[0] || "";
  const { lease_term, title_number } = props.property;
  const placeText = property_address && property_address.replace(" ", "+") + "," + postcode;

  const countersCompanyInfo = [
    {
      text: "length time in business",
      value: yearsMonths(props?.dossier?.company_details?.date_of_creation),
    },
    {
      text: "days in receivership",
      value: numberWithCommas(daysSince(props?.dossier?.insolvency?.cases?.[0]?.dates?.[0]?.date)),
    },
    {
      text: "Number of days since last notice return",
      value: daysSince(props?.dossier?.filing_history?.[0]?.date),
    },
  ];
  const countersPropertyInfo = [
    {
      text: "Property Type",
      value: PROPERTY_TYPE_LOOKUP[("" + props?.property?.property_type).toUpperCase()] || "-",
    },
    {
      text: "Plot Size (acres)",
      value: `${numberWithCommas(props?.property?.plot_size) || "n/a"} acres`,
    },
    {
      text: "Building Footprint (m2)",
      value: props?.property?.Building_Footprint_m2 || "-",
    },
    {
      text: "Building Height Chimey (m)",
      value: props?.property?.Chimney_Height_m || "-",
    },
    {
      text: "Building Height Eaves (m)",
      value: props?.property?.Eavesheight_m || "-",
    },
    {
      text: "EPC Rating",
      value: props?.property?.epc_part || "-",
    },
  ];

  const {
    company_name = null,
    company_number = null,
    has_insolvency_history = null,
    has_been_liquidated = null,
    registered_office_address = "n/a",
    links: { self = "n/a" },
    proprietorship_categories = [],
  } = props?.dossier?.company_details || { links: { self: "n/a" } };

  const company_summary: CompanySummary[] = [
    {
      text: "company name",
      value: company_name,
    },
    {
      text: "company number",
      value: "" + company_number,
    },
    {
      text: "proprietorship categories",
      value: proprietorship_categories.length ? proprietorship_categories.join("</br>") : "-",
    },
    {
      text: "has insolvency history",
      value: YES_NO[has_insolvency_history],
    },
    {
      text: "Has Been Liquidated",
      value: YES_NO[has_been_liquidated],
    },
    {
      text: "Company Address",
      lines: formatAddress(registered_office_address),
    },
    {
      text: "Companies House URL",
      link: self,
    },
  ];
  const property_summary = [
    {
      text: "Internal",
      value: props?.property?.Internal || "-",
    },
    {
      text: "Last Sold Price",
      value: props?.property?.Last_Sold_Value || "-",
    },
    {
      text: "Last Sold Date",
      value: props?.property?.Last_Sold_Date || "-",
    },
    {
      text: "Covenants",
      value: props?.property?.Covenants || "-",
    },
    {
      text: "Easements",
      value: props?.property?.Easements || "-",
    },
    {
      text: "Flood Risk",
      value: getExtraData("flood-risk__flood_risk"),
    },
    {
      text: "Green Belt",
      value: getExtraData("green-belt__green_belt_name"),
    },
    {
      text: "National Park",
      value: getExtraData("national-park__national_park_name"),
    },
    {
      text: "Area of Natural Beauty",
      value: getExtraData("aonb__aonb_name"),
    },
  ];

  const area_summary = [
    {
      text: "Area Type",
      value: getExtraData("area-type__area_type"),
    },
    {
      text: "Average Property Price",
      value: getExtraData("postcode-states__avg_price"),
    },
    {
      text: "Average Price per sq/ft",
      value: getExtraData("postcode-states__avg_price_psf"),
    },
    {
      text: "Average Rent",
      value: getExtraData("postcode-states__avg_rent"),
    },
    {
      text: "Growth 1 Year",
      value: `${getExtraData("postcode-states__growth_1y")}%`,
    },
    {
      text: "Growth 3 Year",
      value: `${getExtraData("postcode-states__growth_3y")}%`,
    },
    {
      text: "Growth 5 Year",
      value: `${getExtraData("postcode-states__growth_5y")}%`,
    },
    {
      text: "Sales per Month",
      value: getExtraData("postcode-states__sales_per_month"),
    },
    {
      text: "Turnover of Property Stock",
      value: getExtraData("postcode-states__turnover"),
    },
  ];

  const council_tax_summary = [
    { text: "Council", value: getExtraData("council-tax__council") },
    { text: "Council Tax Rating", value: getExtraData("council-tax__council_rating") },
    { text: "Year", value: getExtraData("council-tax__year") },
    { text: "Annual Change", value: getExtraData("council-tax__annual_change") },
    { text: "Band A", value: getExtraData("council-tax__band_a") },
    { text: "Band B", value: getExtraData("council-tax__band_b") },
    { text: "Band C", value: getExtraData("council-tax__band_c") },
    { text: "Band D", value: getExtraData("council-tax__band_d") },
    { text: "Band E", value: getExtraData("council-tax__band_e") },
    { text: "Band F", value: getExtraData("council-tax__band_f") },
    { text: "Band G", value: getExtraData("council-tax__band_g") },
    { text: "Band H", value: getExtraData("council-tax__band_h") },
  ];

  const local_sales_summary = [
    { text: "Demand Type", value: getExtraData("demand__demand_rating") },
    { text: "Number Properties for Sale", value: getExtraData("demand__total_for_sale") },
    { text: "Sales per Month", value: getExtraData("demand__average_sales_per_month") },
    { text: "% of Market Sold per Month", value: getExtraData("demand__turnover_per_month") },
    { text: "Months of Inventory", value: getExtraData("demand__months_of_inventory") },
    { text: "Average Days on Market", value: getExtraData("demand__days_on_market") },
  ];

  const local_area_rentals_summary = [
    { text: "Demand Type", value: getExtraData("demand-rent__rental_demand_rating") },
    { text: "Number of Properties for Rent", value: getExtraData("demand-rent__total_for_rent") },
    { text: "Rental per Month", value: getExtraData("demand-rent__transactions_per_month") },
    { text: "% of Market Sold per Month", value: getExtraData("demand-rent__turnover_per_month") },
    { text: "Months of Inventory", value: getExtraData("demand-rent__months_of_inventory") },
    { text: "Average Days on Market", value: getExtraData("demand-rent__days_on_market") },
  ];

  const formatLhaRateLine = (bedrooms) => {
    const prefix = `lha-rate__${bedrooms}`;
    const g = (tag) => getExtraData(`${prefix}_${tag}`);
    const result = g("year") === "-" ? "-" : `${g("year")} ${g("month")} £${g("rate")} ${g("rate_unit").replaceAll("_", " ")}`;
    return result;
  };
  const local_housing_rates_summary = [
    { text: "LHA", value: getExtraData("lha-rate__1_brma") },
    { text: "1 Bedroom", value: formatLhaRateLine(1) },
    { text: "2 Bedroom", value: formatLhaRateLine(2) },
    { text: "3 Bedroom", value: formatLhaRateLine(3) },
    { text: "4 Bedroom", value: formatLhaRateLine(4) },
  ];

  const infoTiles = {
    area_summary: {
      title: "Local Area Information",
      data: area_summary,
    },
    property_summary: {
      title: "Property Summary",
      data: property_summary,
    },
    council_tax_summary: {
      title: "Council Tax",
      data: council_tax_summary,
    },
    local_sales_summary: {
      title: "Local Area Sales",
      data: local_sales_summary,
    },
    local_area_rentals_summary: {
      title: "Local Area Rentals",
      data: local_area_rentals_summary,
    },
    local_housing_rates_summary: {
      title: "Local Housing Authority Rates",
      data: local_housing_rates_summary,
    },
  };

  const rawNotices = props?.dossier?.filing_history || [];
  const notices = rawNotices.filter((a) => !(typeof a["description"] == "object" || typeof a["category"] == "object"));
  return (
    <Container style={{ color: "white" }}>
      <div className="property-title-container">
        <h1>{property_address}</h1>
        <ul>
          {lease_term && (
            <li>
              <b>Lease Term:</b> {lease_term}
            </li>
          )}
          <li>&nbsp;</li>
          <li>
            <b>Title Number:</b> {title_number}
          </li>
          {property_type && (
            <li>
              <b>Property Type:</b> {property_type}
            </li>
          )}
        </ul>
      </div>

      <Grid className="property-google-map-container">
        <Grid item style={{ width: "100%" }}>
          {placeText && <Maps placeText={placeText} titleNumber={title_number} postcode={outcode} centre={latlng} />}
        </Grid>
      </Grid>

      <Tabs value={tabsValue} onChange={changeTabEvent} className={classes.root}>
        <Tab label="Company Information" />
        <Tab label="Property Information" />
        <Tab label="Area Analysis" />
        <Tab label="Development & Valuation" />
        {props.bookViewing && (
          <>
            {!props.isViewingBooked && (
              <button disabled={props.isUpdatingBooking} onClick={() => props.bookViewing()}>
                View Booking
              </button>
            )}
            {props.isViewingBooked && <button onClick={() => props.openBookedViewing()}>Book Viewing</button>}
          </>
        )}
      </Tabs>
      <TabPanel value={tabsValue} index={0}>
        {props.dossier === null ? <h2>No company information about this property was found</h2> : ""}
        {props.dossier && (
          <>
            <Grid className="counter-container" container>
              {countersCompanyInfo.map((c, idx) => (
                <Grid key={`counter-${idx}`} className="counter" item md={4} xs={12}>
                  <div className="title">{c.text}</div>
                  <div className="value">{c.value}</div>
                </Grid>
              ))}
            </Grid>

            <Grid container className="summary-container">
              <CompanySummaryPanel companySummary={company_summary} />
              <FinancialsPanel companyNumber={company_number} />
              <CreditHistoryPanel companyNumber={company_number} />
              <OfficersPanel companyNumber={company_number} />
              <ChargesPanel companyNumber={company_number} />
              <EstablishmentsPanel companyNumber={company_number} />
              <LatestNoticesPanel notices={notices} />
            </Grid>

            <Grid container className="print-pdf-container" justifyContent="center">
              <Grid item xs={12} md={6} style={{ textAlign: "center" }}>
                <Button variant="contained" size="large" fullWidth href={document.location.href + "&printview=true"}>
                  Print PDF
                </Button>
              </Grid>
            </Grid>
          </>
        )}
      </TabPanel>

      <TabPanel value={tabsValue} index={1}>
        {props.isLoadingExtraData && (
          <div style={{ height: 500, width: "100%", textAlign: "center", color: "black" }}>
            <CircularProgress />
            <br />
            <span>Please wait, this can take up to a minute to complete</span>
          </div>
        )}
        {!props.isLoadingExtraData && (
          <>
            <Grid className="counter-container" container>
              {countersPropertyInfo.map((c, idx) => (
                <Grid key={`counter-${idx}`} className="counter" item md={2} xs={6}>
                  <div className="title">{c.text}</div>
                  <div className="value">{c.value}</div>
                </Grid>
              ))}
            </Grid>
            <Grid container>
              {Object.keys(infoTiles).map((key) => (
                <Grid item xs={12} md={6} key={key}>
                  <Grid container className="summary-container">
                    <Grid item className="summary" xs={12}>
                      <h3>{infoTiles[key].title}</h3>
                      <hr className="short" />
                      <table>
                        <tbody>
                          {infoTiles[key].data.map((t, idx) => (
                            <tr key={`pd-${key}-${idx}`}>
                              <td className="title">{t.text}:</td>
                              <td className="value">{toValue(t.value)}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </Grid>
                  </Grid>
                </Grid>
              ))}
            </Grid>
          </>
        )}
      </TabPanel>
      <TabPanel value={tabsValue} index={2}>
        <CalculatorMenu postcode={props.property.postcode} calculatorType={PermissionType.AREA_ANALYSIS} propertyTitle={title_number} />
      </TabPanel>
      <TabPanel value={tabsValue} index={3}>
        <CalculatorMenu postcode={props.property.postcode} calculatorType={PermissionType.PROJECT_CALCULATORS} propertyTitle={title_number} />
      </TabPanel>
    </Container>
  );

  function getExtraData(field) {
    return props.extraData?.[field] || "-";
  }
  function changeTabEvent(_, newValue) {
    tabsValueSet(newValue);
  }
}

function plural(singular, amount) {
  return `${singular}${amount === 1 ? "" : "s"}`;
}
function yearsMonths(sDate) {
  if (!sDate) return "n/a Yrs n/a mths";
  const past_date = new Date(sDate);
  const current_date = new Date();
  const difference = current_date.getFullYear() * 12 + current_date.getMonth() - (past_date.getFullYear() * 12 + past_date.getMonth());
  const years = Math.floor(difference / 12);
  const months = difference % 12;
  return `${years} ${plural("Yr", years)} ${months} ${plural("mth", months)}`;
}

// a and b are javascript Date objects
function daysSince(sDate) {
  if (!sDate) return "n/a days";
  const _MS_PER_DAY = 1000 * 60 * 60 * 24;
  const a = new Date(sDate);
  const b = new Date();

  // Discard the time and time-zone information.
  const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
  const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());
  const days = Math.floor((utc2 - utc1) / _MS_PER_DAY);
  return `${days} ${plural("day", days)}`;
}
function formatAddress(a = { postal_code: "n/a", locality: "n/a", address_line_1: "n/a" }) {
  return [a.address_line_1, a.locality, a.postal_code];
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;
  return (
    <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
      {value === index && <Box p={0}>{children}</Box>}
    </div>
  );
}
