const ELEMENT_ID = "gtm";
const GTM_ID = "G-JL0HZT0BFH";
const SRC = `https://www.googletagmanager.com/gtag/js?id=${GTM_ID}`;

export default function loadGTM(): Promise<boolean> {
  return new Promise((resolve, reject) => {
		console.info('[loadGTM] loading...');
    try {
      const existingScript = document.getElementById(ELEMENT_ID);

      if (!existingScript) {
        const script = document.createElement("script");
        script.src = SRC;
        script.id = ELEMENT_ID;
        script.onload = () => {
          window["dataLayer"] = window["dataLayer"] || [];
          function gtag(a, b) {
            window["dataLayer"].push(arguments);
          }
          gtag("js", new Date());
          gtag("config", GTM_ID);
          resolve(true);
					console.info('[loadGTM] loaded');
        };
        document.body.appendChild(script);
      }

      if (existingScript) {
				resolve(true);
				console.info('[loadGTM] loaded');
			}
    } catch (e: any) {
      console.error("gtml init failure", e);
			console.info('[loadGTM] failed to loaded');
      reject(e);
    }
  });
}
/*

<!-- Global site tag (gtag.js) - Google Analytics -->
<script async src="https://www.googletagmanager.com/gtag/js?id=G-JL0HZT0BFH"></script>
<script>
  window.dataLayer = window.dataLayer || [];
  function gtag(){dataLayer.push(arguments);}
  gtag('js', new Date());

  gtag('config', 'G-JL0HZT0BFH');
</script>

*/
