import React, { useEffect, useState } from "react";
import { CircularProgress } from "@material-ui/core";
import { getConversationAreas, getFloodRiskaAreas, getHistoricBuildings, getPlanningPoints, getPostcodePolygon, getPropertyPolygon } from "../../api/Api";
import OSMap from "./OSMap";
import {
  LAYER_NAME_CONSERVATION,
  LAYER_NAME_FLOOD_RISK,
  LAYER_NAME_HISTORIC_BUILDINGS,
  LAYER_NAME_PLANNING,
  LAYER_NAME_PROPERTY,
  DEFAULT_ZOOM_VALUE,
  ZoomValues,
} from "./types";

const defaultVisibility = {
  [LAYER_NAME_FLOOD_RISK]: true,
  [LAYER_NAME_CONSERVATION]: true,
  [LAYER_NAME_HISTORIC_BUILDINGS]: true,
  [LAYER_NAME_PROPERTY]: true,
  [LAYER_NAME_PLANNING]: true,
};
function useOverlayVisibility() {
  const VISIBILITY_CACHE = "visibilityy-settings";
  let storedSettings = {};
  if (!localStorage[VISIBILITY_CACHE]) localStorage[VISIBILITY_CACHE] = JSON.stringify(defaultVisibility);
  try {
    storedSettings = JSON.parse(localStorage[VISIBILITY_CACHE]);
  } catch (e) {
    localStorage[VISIBILITY_CACHE] = JSON.stringify(defaultVisibility);
  }
  const visibility = Object.assign({}, defaultVisibility, storedSettings);
  const toggleVisibility = (visibilityName) => {
    visibility[visibilityName] = !visibility[visibilityName];
    localStorage[VISIBILITY_CACHE] = JSON.stringify(visibility);
  };
  return { visibility, toggleVisibility };
}

function useZoom(initialValue: ZoomValues) {
  const CACHE = "zoom-settings";
  let startValue: { value: ZoomValues } = { value: initialValue };
  if (!localStorage[CACHE]) localStorage[CACHE] = JSON.stringify(startValue);
  try {
    startValue = JSON.parse(localStorage[CACHE]);
  } catch (e) {
    localStorage[CACHE] = JSON.stringify(startValue);
  }

  const zoom = Object.assign({}, startValue);
  const zoomSet = (value: ZoomValues) => {
    zoom.value = value;
    localStorage[CACHE] = JSON.stringify(zoom);
  };
  return { zoom, zoomSet };
}

interface MapsProps {
  placeText: string;
  titleNumber: string;
  postcode?: string;
  showOutcodeLayerOnly?: boolean;
  centre?: number[];
}

type SearcBoxType = {
  topLeft: string;
  bottomRight: string;
};
let loading = 0;
const loadingSet = (value) => (loading = value);

export default function Maps(props: MapsProps) {
  const { titleNumber, placeText, showOutcodeLayerOnly } = props;

  const [propertyPolygon, polygonPointsSet] = useState<any[]>([]);
  const [historicPoints, historicPointsSet] = useState<any[]>([]);
  const [floodRiskZones, floodRiskZonesSet] = useState<any[]>([]);
  const [conversationAreas, conversationAreasSet] = useState<any[]>([]);
  const [planningPoints, planningPointsSet] = useState<any[]>([]);
  const [postcodePolygon, postcodePolygonSet] = useState<any[]>([]);
  const [centre, centreSet] = useState<number[]>(props?.centre || []);
  // const [loading, loadingSet] = useState(0);
  const [loadingError, loadingErrorSet] = useState(false);
  const [searchBox, searchBoxSet] = useState<SearcBoxType>({ topLeft: "", bottomRight: "" });
  const { visibility, toggleVisibility } = useOverlayVisibility();
  const { zoom, zoomSet } = useZoom(DEFAULT_ZOOM_VALUE);

  useEffect(() => {
    if (!centre || centre?.length < 2) return;
    (async () => {
      const clat: number = centre[1];
      const clng: number = centre[0];
      const dp: number = 3;
      const radius: number = 0.005;

      const topLeft = (clng - radius).toFixed(dp) + "," + (clat - radius).toFixed(dp);
      const bottomRight = (+clng + radius).toFixed(dp) + "," + (+clat + radius).toFixed(dp);
      searchBoxSet({ topLeft, bottomRight });
    })();
  }, [centre]);

  useEffect(() => {
    const { topLeft, bottomRight } = searchBox;
    if (!topLeft || !bottomRight) return;
    (async () => {
      loadingSet(loading + 1);
      let historicBuildings = await getHistoricBuildings(topLeft, bottomRight);
      loadingSet(loading - 1);
      // @ts-ignore
      historicPointsSet(historicBuildings);
    })();
  }, [searchBox]);

  useEffect(() => {
    const { topLeft, bottomRight } = searchBox;
    if (!topLeft || !bottomRight) return;
    (async () => {
      loadingSet(loading + 1);
      let floodRisks = await getFloodRiskaAreas(topLeft, bottomRight);
      loadingSet(loading - 1);
      // @ts-ignore
      floodRiskZonesSet(floodRisks);
    })();
  }, [searchBox]);

  useEffect(() => {
    const { topLeft, bottomRight } = searchBox;
    if (!topLeft || !bottomRight) return;
    (async () => {
      loadingSet(loading + 1);
      let conversationAreas = await getConversationAreas(topLeft, bottomRight);
      loadingSet(loading - 1);
      // @ts-ignore
      conversationAreasSet(conversationAreas);
    })();
  }, [searchBox]);

  useEffect(() => {
    const { topLeft, bottomRight } = searchBox;
    if (!topLeft || !bottomRight) return;
    (async () => {
      loadingSet(loading + 1);
      let planningPoints = await getPlanningPoints(topLeft, bottomRight);
      loadingSet(loading - 1);
      planningPointsSet(planningPoints);
    })();
  }, [searchBox]);

  useEffect(() => { // get postode polygon to obtain a default for centre
    (async () => {
      try {
        // always get the postcode polygon
        let postcodePolygon = await getPostcodePolygon(props?.postcode?.split(" ")?.[0]);
        postcodePolygonSet(postcodePolygon);
        
        // if centre not set, set centre from postcode
        if (!centre || !centre?.length) {
          const largestPolygon = (ps) => (ps.length ? ps.reduce((a, c) => (c.length > a.length ? c : a), []) : ps);
          const roughCentre = centroid(largestPolygon(postcodePolygon));
          centreSet(roughCentre); 
        }

      } catch(e){
        console.error('<Maps>[useEffect(getPostcodePolygon and centreSet]', e);
      }

      })();
    },[props?.postcode, centre]);

  useEffect(() => {
    const { topLeft, bottomRight } = searchBox;
    if (!topLeft || !bottomRight) return;
    (async () => {
      try {
        if (props.titleNumber && !showOutcodeLayerOnly) {
          let dataArray = await getPropertyPolygon(props.titleNumber, topLeft, bottomRight);
          let data;
          if (!(!dataArray || !dataArray[0] || dataArray?.error)) {
            data = dataArray[0];
          }

          let propertyPolygon;
          if (data) {
            // if "POLYGON (...)" style data use this routine
            if (data?.Polygon) {
              if (data.Polygon.indexOf("POLYGON ((") === 0) {
                const step1 = data.Polygon.substr("POLYGON (".length, data.Polygon.length - "POLYGON (".length - 1);
                const step2 = step1.substr(1, step1.length - 2).split("), (");
                const step3 = step2.map((polygon) => polygon.split(",").map((coords) => coords.split(" ").reverse()));
                propertyPolygon = step3;
              } else {
                propertyPolygon = data.Polygon.substring("POLGYGON (".length, data.Polygon.length - 2)
                  .split(",")
                  .map((coords) => coords.split(" ").reverse());
              }
            }

            // if Array style data use this routine
            if (!data?.Polygon) {
              // swap coordinates around
              try {
                propertyPolygon = dataArray.map((polygon) => polygon.map((coords: [number, number]) => coords.reverse()));
              } catch(e){}
            }

            polygonPointsSet(propertyPolygon);

            if (!centre || !centre?.length) {
              const largestPolygon = (ps) => (ps.length ? ps.reduce((a, c) => (c.length > a.length ? c : a), []) : ps);
              const roughCentre = centroid(largestPolygon(propertyPolygon));
              centreSet(roughCentre);
            }
          }
        } else {
        }
      } catch (e) {
        console.log("error getting data in Maps.tsx", e);
        loadingErrorSet(true);
      }
    })();
    // eslint-disable-next-line
  }, [props.titleNumber, props?.postcode, showOutcodeLayerOnly, searchBox]);

  const mappingData = {
    propertyPolygon,
    historicPoints,
    floodRiskZones,
    conversationAreas,
    planningPoints,
    centre,
    loading: !loading,
    visibility,
    toggleVisibility,
    zoom,
    zoomSet,
    postcodePolygon,
  };

  if (loadingError) {
    return (
      <>
        <p>loading error </p>
      </>
    );
  }

  return (
    <>
      <OSMap address={placeText} titleNumber={titleNumber} mappingData={mappingData} showOutcodeLayerOnly={!!showOutcodeLayerOnly} />
      {!!loading && (
        <>
          <div style={{ color: "black", textAlign: "center", margin: "20px" }}>
            <CircularProgress size="1rem" />
            {"  "}Locating map data...{loading}
            {"  "}
            <CircularProgress size="1rem" />
          </div>
        </>
      )}
    </>
  );
}

function centroid(points: []): number[] {
  let centroid = [0, 0];
  const LAT = 0;
  const LNG = 1;
  for (let i = 0; i < points.length; i++) {
    // there are instances of multipolygons being returned in points[].
    // here, we ignore lat and lng points contaminated with ')' or '('
    const lat = Number(points[i][LAT]);
    const lng = Number(points[i][LNG]);
    if (!isNaN(lat) && !isNaN(lng)) {
      centroid[0] += lat;
      centroid[1] += lng;
    }
  }
  const totalPoints = points.length;
  centroid[0] = centroid[0] / totalPoints;
  centroid[1] = centroid[1] / totalPoints;
  return centroid;
}
