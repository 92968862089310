import API from "@aws-amplify/api";
// import { useEffect, useState } from "react";
import { getInitialisedMessage } from "../../libs/session";

async function getInternalUsers() {
  const endpointName = "internal/assignable-users";
  const headers = await getInitialisedMessage();
  const init = Object.assign(headers, {});
  const { data }: { data: any[] } = (await API.get("internal", endpointName, init)) || [];
  const result = data.reduce((acc, cur) => Object.assign(acc, { [cur.user_id]: cur.name }), {});
  return result;
}

let assignedToList = {};

getInternalUsers().then(data=>assignedToList = data);

export default function useAssignedToList() {
  return assignedToList;
}
