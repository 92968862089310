import React from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@material-ui/core";
import NotificationsForm from "../Notifications/NotificationsForm";
import { SubscriptionType } from "../Profile/sharedTypes";
import "./Notifications.scss";

interface NotificationsDialogContainerProps {
  show: boolean;
  postcode?: string;
  district?: string;
  handleClose: () => void;
}

export default function NotificationsDialogContainer(props: NotificationsDialogContainerProps) {
  const notificationDistrict = props.district;
  const notificationPostcode = (props.postcode || "").toUpperCase();
  const subscriptionType: SubscriptionType = "LITE";
  const isSubscriptionActive = true;

  const { handleClose, show } = props;
  return (
    <Dialog open={show} onBackdropClick={handleClose} fullWidth>
      <DialogTitle style={{textAlign: "center"}}>Create Alert</DialogTitle>
      <DialogContent>
        <NotificationsForm {...{ subscriptionType, isSubscriptionActive, notificationDistrict, notificationPostcode, onClose: handleClose }} />
      </DialogContent>
      <DialogActions>
        <Button className="close-button" onClick={handleClose} color="primary" variant="outlined">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}
