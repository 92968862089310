import React, { useState } from "react";
import { Button, CircularProgress, FormControlLabel, Radio, RadioGroup, TextField } from "@material-ui/core";

interface SearchBarPostcodeUPRNProps {
  getData: (postcodeUprn: string, searchBy?: string) => void;
  isBusy: boolean;
}

type SearchByValues = "postcode" | "uprn";

const executeSearch = (event, disabledSearch, getData, postcode, searchBy) => {
  event?.preventDefault();
  if (disabledSearch) return false; // cancel operation
  getData(postcode, searchBy);
};

const validPostcode = (postcode: string): boolean => postcode.trim().length >= 6 && postcode.trim().length <= 8;

export default function SearchBarPostcodeUPRN(props: SearchBarPostcodeUPRNProps) {
  const [searchBy, searchBySet] = useState<SearchByValues>("postcode");
  const [postcode, postcodeSet] = useState<string>("");

  const { getData, isBusy = false } = props;
  const disabledSearch = !validPostcode(postcode) || isBusy;

  return (
    <form onSubmit={(event) => executeSearch(event, disabledSearch, getData, postcode, searchBy)}>
      <table className="MuiPaper-rounded query-form">
        <tbody>
          <tr>
            <td>
              <RadioGroup defaultValue="postcode" onChange={({ target: { value } }) => searchBySet(value as SearchByValues)}>
                <FormControlLabel disabled={isBusy} value="postcode" control={<Radio />} label="Postcode" />
                <FormControlLabel disabled={isBusy} value="uprn" control={<Radio />} label="UPRN" />
              </RadioGroup>
            </td>
            <td>
              <TextField
                fullWidth
                style={{ color: "black", border: "1px white solid", borderRadius: 2 }}
                InputProps={{ style: { color: "black" } }}
                variant="outlined"
                value={postcode}
                onChange={(e) => postcodeSet(e.target.value)}
                disabled={isBusy}
              />
            </td>
            <td style={{ textAlign: "center" }}>
              <Button
                id="search-button"
                variant="contained"
                style={{ borderColor: "grey", minWidth: 90 }}
                disabled={disabledSearch}
                onClick={(event) => executeSearch(event, disabledSearch, getData, postcode, searchBy)}
              >
                {!isBusy && "Search"}
                {isBusy && <CircularProgress color="primary" size="1.5rem" />}
              </Button>
            </td>
          </tr>
        </tbody>
      </table>
    </form>
  );
}
