import { Grid } from "@material-ui/core";
  import React from "react";
import { CompanyHouseLink } from "./CompanyHouseLink";
import { CompanySummary } from "./CompanySummary.type";

interface Props {
  notices?: CompanySummary[];
}

export function LatestNoticesPanel(props: Props) {
  const { notices = [] } = props;

  return (
    <Grid item className="summary" xs={12} md={6}>
      <h3>Latest notices</h3>
      <hr className="short" />
      {!notices.length && (
        <p>
          <i>There are no Notices Available</i>
        </p>
      )}
      {notices?.length > 0 && (
        <table>
          <tbody>
            {notices.map((n, idx) => (
              <tr key={`notices-${idx}`}>
                <td style={{width: 100}}>{n["date"]}:</td>
                <td>
                  <CompanyHouseLink link={n["links"]["self"] + "/document?format=pdf&download=0"} text={n["description"].replace(/-/g, " ")} />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </Grid>
  );
}
