import React from "react";
import "./CalcModal.scss";
import { Button, Dialog, DialogActions, DialogContentText, DialogTitle, Grid } from "@material-ui/core";

export interface FieldValue {
  field: string;
  value: string | [];
}
export interface IResultDialogConfig {
  title: string;
  fields: FieldValue[] | [];
}
export interface ResultDialogProps {
  open: boolean;
  onClose: Function;
  config: IResultDialogConfig;
}

export default function ResultDialog(props: ResultDialogProps) {
  const {
    open,
    onClose,
    config: { title, fields },
  } = props;

  return (
    <Dialog className="simple-dialog-container-calc" open={open}>
      {" "}
      <DialogTitle style={{ textAlign: "center", height: "200" }}>{title}</DialogTitle>
      <DialogContentText component="div">
        {(fields as FieldValue[]).map((f, idx, arr) => (
          <Grid container key={`result-line-${idx}`}>
            <>
              <Grid item xs={1}></Grid>
              <Grid key={`field-${idx}`} item xs={5} style={{ textAlign: "left", textTransform: "capitalize", borderBottom: doSectionUnderline(f, idx, arr) }}>
                {formatLabel(f.field)}
              </Grid>
              <Grid key={`value-${idx}`} item xs={5} style={{ textAlign: "right", textTransform: "capitalize", borderBottom: doSectionUnderline(f, idx, arr) }}>
                {formatStringOrArray(f.value)}
              </Grid>
              <Grid item xs={1}></Grid>
            </>
          </Grid>
        ))}
      </DialogContentText>
      <DialogActions style={{ backgroundColor: "white" }}>
        <Button className="MuiButton-contained-modal" variant="outlined" onClick={() => onClose()}>
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
}
function doSectionUnderline(f, idx, arr) {
  const underlineStyle = "1px grey solid";
  const style = idx && f.field.indexOf(arr[idx - 1].field) === 0 ? underlineStyle : "";
  return style;
}

function formatStringOrArray(value: string | []): string | any {
  let result: string | [] = "";
  if (value instanceof Array) {
    result = value.map(formatNumber).join(" - ");
  } else if ((value as any) instanceof Object) {
    return formatValue(value);
  } else {
    result = formatNumber(value);
  }
  return formatLabel(result);
}
function formatLabel(value: string): string {
  return value.split("_").join(" ");
}
function formatNumber(value: string | number): string {
  return value.toLocaleString("en-UK");
}

function descSort(a, b) {
  return a > b ? 1 : -1;
}

function formatValue(value) {
  let result: any[] = [];
  Object.keys(value)
    .sort(descSort)
    .forEach((field) => {
      if (["points_analysed", "raw_data", "radius"].indexOf(field) >= 0) return;
      if (value[field] instanceof Array) {
        result.push(
          <span>
            {formatLabel(field)}: {value[field].map(formatNumber).join(" - ")}
            <br />
          </span>
        );
      } else {
        result.push(
          <span>
            {formatLabel(field)}: {formatLabel("" + value[field])} <br />
          </span>
        );
      }
    });
  result.push(<hr style={{ border: "1px solid darkgray" }} />);
  return result;
}
