import React from "react";
import "./PropertyDetails.scss";
import { Grid, Container, Button, CircularProgress } from "@material-ui/core";
import { numberWithCommas } from "../../libs/helpres";
import { PROPERTY_TYPE_LOOKUP } from "../SearchResultTiles/SearchResultTiles";
import Maps from "./Maps";

interface TextValue {
  text: string;
  value: any;
}

const YES_NO = {
  true: "Yes",
  false: "No",
  "?": "n/a",
  undefined: "n/a",
  null: "n/a",
  "": "n/a",
};

export function PropertyDetails(props) {
  document.getElementsByTagName("html")[0].classList.add("print");

  if (props.isLoading) {
    return (
      <Container style={{ color: "white" }}>
        <div className="property-title-container">
          <i>Loading...</i>
        </div>
      </Container>
    );
  }

  if (props.noDataAvailable) {
    return (
      <Container style={{ color: "white" }}>
        <div className="property-title-container">
          <i>Property details are not available</i>
        </div>
      </Container>
    );
  }

  const { postcode, property_address, property_type, latlng } = props.property;
  const outcode = postcode?.split(" ")?.[0] || "";
  const { lease_term, title_number } = props.property;
  const placeText = property_address && property_address.replace(" ", "+") + "," + postcode;

  const countersCompanyInfo = [
    {
      text: "length time in business",
      value: yearsMonths(props?.dossier?.company_details?.date_of_creation),
    },
    {
      text: "days in receivership",
      value: numberWithCommas(daysSince(props?.dossier?.insolvency?.cases?.[0]?.dates?.[0]?.date)),
    },
    {
      text: "Number of days since last notice",
      value: daysSince(props?.dossier?.filing_history?.[0]?.date),
    },
  ];
  const countersPropertyInfo = [
    {
      text: "Property Type",
      value: PROPERTY_TYPE_LOOKUP[("" + props?.property?.property_type).toUpperCase()] || "-",
    },
    {
      text: "Plot Size (acres)",
      value: (
        `${numberWithCommas(props?.property?.plot_size) || "n/a"} acres`
      ),
    },
    {
      text: "Building Footprint (m2)",
      value: props?.property?.Building_Footprint_m2 || "-",
    },
    {
      text: "Building Height Chimey (m)",
      value: props?.property?.Chimney_Height_m || "-",
    },
    {
      text: "Building Height Eaves (m)",
      value: props?.property?.Eavesheight_m || "-",
    },
    {
      text: "EPC Rating",
      value: props?.property?.epc_part || "-",
    },
  ];

  const {
    company_name = "n/a",
    company_number = "n/a",
    has_insolvency_history = null,
    has_been_liquidated = null,
    registered_office_address = "n/a",
    links: { self = "n/a" },
  } = props?.dossier?.company_details || { links: { self: "n/a" } };

  const justNotice = props?.dossier?.notices?.[0]?.notice || "";

  const company_summary = [
    {
      text: "company name",
      value: company_name,
    },
    {
      text: "company number",
      value: "" + company_number,
    },
    {
      text: "has insolvency history",
      value: YES_NO[has_insolvency_history],
    },
    {
      text: "Has Been Liquidated",
      value: YES_NO[has_been_liquidated],
    },
    {
      text: "Company Address",
      lines: formatAddress(registered_office_address),
    },
    {
      text: "Companies House URL",
      link: self,
    },
  ];
  const property_summary = [
    {
      text: "Internal",
      value: props?.property?.Internal || "-",
    },
    {
      text: "Last Sold Price",
      value: props?.property?.Last_Sold_Value || "-",
    },
    {
      text: "Last Sold Date",
      value: props?.property?.Last_Sold_Date || "-",
    },
    {
      text: "Covenants",
      value: props?.property?.Covenants || "-",
    },
    {
      text: "Easements",
      value: props?.property?.Easements || "-",
    },
    {
      text: "Flood Risk",
      value: getExtraData("flood-risk__flood_risk"),
    },
    {
      text: "Green Belt",
      value: getExtraData("green-belt__green_belt_name"),
    },
    {
      text: "National Park",
      value: getExtraData("national-park__national_park_name"),
    },
    {
      text: "Area of Natural Beauty",
      value: getExtraData("aonb__aonb_name"),
    },
  ];

  const area_summary = [
    {
      text: "Area Type",
      value: getExtraData("area-type__area_type"),
    },
    {
      text: "Average Property Price",
      value: getExtraData("postcode-states__avg_price"),
    },
    {
      text: "Average Price per sq/ft",
      value: getExtraData("postcode-states__avg_price_psf"),
    },
    {
      text: "Average Rent",
      value: getExtraData("postcode-states__avg_rent"),
    },
    {
      text: "Growth 1 Year",
      value: `${getExtraData("postcode-states__growth_1y")}%`,
    },
    {
      text: "Growth 3 Year",
      value: `${getExtraData("postcode-states__growth_3y")}%`,
    },
    {
      text: "Growth 5 Year",
      value: `${getExtraData("postcode-states__growth_5y")}%`,
    },
    {
      text: "Sales per Month",
      value: getExtraData("postcode-states__sales_per_month"),
    },
    {
      text: "Turnover of Property Stock",
      value: getExtraData("postcode-states__turnover"),
    },
  ];

  const council_tax_summary = [
    { text: "Council", value: getExtraData("council-tax__council") },
    { text: "Council Tax Rating", value: getExtraData("council-tax__council_rating") },
    { text: "Year", value: getExtraData("council-tax__year") },
    { text: "Annual Change", value: getExtraData("council-tax__annual_change") },
    { text: "Band A", value: getExtraData("council-tax__band_a") },
    { text: "Band B", value: getExtraData("council-tax__band_b") },
    { text: "Band C", value: getExtraData("council-tax__band_c") },
    { text: "Band D", value: getExtraData("council-tax__band_d") },
    { text: "Band E", value: getExtraData("council-tax__band_e") },
    { text: "Band F", value: getExtraData("council-tax__band_f") },
    { text: "Band G", value: getExtraData("council-tax__band_g") },
    { text: "Band H", value: getExtraData("council-tax__band_h") },
  ];

  const local_sales_summary = [
    { text: "Demand Type", value: getExtraData("demand__demand_rating") },
    { text: "Number Properties for Sale", value: getExtraData("demand__total_for_sale") },
    { text: "Sales per Month", value: getExtraData("demand__average_sales_per_month") },
    { text: "% of Market Sold per Month", value: getExtraData("demand__turnover_per_month") },
    { text: "Months of Inventory", value: getExtraData("demand__months_of_inventory") },
    { text: "Average Days on Market", value: getExtraData("demand__days_on_market") },
  ];

  const local_area_rentals_summary = [
    { text: "Demand Type", value: getExtraData("demand-rent__rental_demand_rating") },
    { text: "Number of Properties for Rent", value: getExtraData("demand-rent__total_for_rent") },
    { text: "Rental per Month", value: getExtraData("demand-rent__transactions_per_month") },
    { text: "% of Market Sold per Month", value: getExtraData("demand-rent__turnover_per_month") },
    { text: "Months of Inventory", value: getExtraData("demand-rent__months_of_inventory") },
    { text: "Average Days on Market", value: getExtraData("demand-rent__days_on_market") },
  ];

  const formatLhaRateLine = (bedrooms) => {
    const prefix = `lha-rate__${bedrooms}`;
    const g = (tag) => getExtraData(`${prefix}_${tag}`);
    const result = g("year") === "-" ? "-" : `${g("year")} ${g("month")} £${g("rate")} ${g("rate_unit").replaceAll("_", " ")}`;
    return result;
  };
  const local_housing_rates_summary = [
    { text: "LHA", value: getExtraData("lha-rate__1_brma") },
    { text: "1 Bedroom", value: formatLhaRateLine(1) },
    { text: "2 Bedroom", value: formatLhaRateLine(2) },
    { text: "3 Bedroom", value: formatLhaRateLine(3) },
    { text: "4 Bedroom", value: formatLhaRateLine(4) },
  ];

  const infoTiles = {
    area_summary: {
      title: "Local Area Information",
      data: area_summary,
    },
    property_summary: {
      title: "Property Summary",
      data: property_summary,
    },
    local_sales_summary: {
      title: "Local Area Sales",
      data: local_sales_summary,
    },
    local_area_rentals_summary: {
      title: "Local Area Rentals",
      data: local_area_rentals_summary,
    },
    local_housing_rates_summary: {
      title: "Local Housing Authority Rates",
      data: local_housing_rates_summary,
    },
    council_tax_summary: {
      title: "Council Tax",
      data: council_tax_summary,
    },
  };

  const insolvencyCases = props?.dossier?.insolvency?.cases || [];

  // if appointed_on is there, use that, else use everyhting

  let mostRecentPractitioners = [];
  let mostRecentCaseNumber = 0;
  let practitionerLink = null;
  insolvencyCases.forEach((ic) => {
    ic.practitioners.forEach((p) => {
      if (p.ceased_to_act_on) return;
      mostRecentPractitioners = ic.practitioners;
      practitionerLink = ic?.links?.charge;
      mostRecentCaseNumber = ic.number;
    });
  });
  const receiver_details = mostRecentPractitioners.map((p: { name; address; role }) => {
    return [tv("case no", mostRecentCaseNumber), tv("practioner name", p.name), tv("practioner address", flattenObject(p.address)), tv("role", p.role)];
  });
  // receiver_details.push(...practitioners);

  const notices = props?.dossier?.filing_history || [];

  return (
    <Container className="print-view" style={{ color: "white" }}>
      <Grid container justifyContent="center" className="pdf-print-button">
        <Grid item xs={12} md={6} style={{ textAlign: "center" }}>
          <Button variant="contained" size="large" fullWidth onClick={printPDF}>
            <span style={{color:'red'}} >Click this button to Print PDF</span>
          </Button>
          <Button variant="contained" size="large" fullWidth onClick={()=>window.history.back()}>
            <span style={{color:'red'}} >Cancel</span>
          </Button>
        </Grid>
      </Grid>

      <div className="property-title-container">
        <h1>{property_address}</h1>
        <ul>
          {lease_term && (
            <li>
              <b>Lease Term:</b> {lease_term}
            </li>
          )}
          <li>&nbsp;</li>
          <li>
            <b>Title Number:</b> {title_number}
          </li>
          {property_type && (
            <li>
              <b>Property Type:</b> {property_type}
            </li>
          )}
        </ul>
      </div>

      <Grid className="property-google-map-container">
        <Grid item style={{ width: "100%", height: 200 }}>
          {placeText && <Maps placeText={placeText} titleNumber={title_number} postcode={outcode} centre={latlng} />}
        </Grid>
      </Grid>

      <Grid className="counter-container" container>
        {countersCompanyInfo.map((c, idx) => (
          <Grid key={`counter-${idx}`} className="counter" item md={4} xs={4}>
            <div className="title">{c.text}</div>
            <div className="value">{c.value}</div>
          </Grid>
        ))}
      </Grid>

      <Grid container>
        <Grid item xs={6} md={6}>
          <Grid container className="summary-container">
            <Grid item className="summary" xs={12}>
              <h3>quick summary</h3>
              <hr className="short" />
              <table>
                <tbody>
                  {company_summary.map((t, idx) => (
                    <tr key={`cs-${idx}`}>
                      <td className="title">{t.text}:</td>
                      <td className="value">{toValue(t.value) || toCompanyHouseLink(t.link) || toLines(t.lines)}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={6} md={6}>
          <Grid container className="summary-container">
            <Grid item className="summary" xs={12}>
              <h3>Liquidator / Receiver Details</h3>
              <hr className="short" />
              {receiver_details.length === 0 && justNotice && <div style={{ whiteSpace: "break-spaces" }}>{justNotice}</div>}
              <table>
                {receiver_details.map((r, idx) => (
                  <tbody key={`liquidator-${idx}`}>
                    {(r || []).map((t, idx) => (
                      <tr key={`receiver_details-${idx}`}>
                        <td className="title">{t["text"]}:</td>
                        <td className="value">{t["value"]}</td>
                      </tr>
                    ))}
                  </tbody>
                ))}
                {!!practitionerLink && (
                  <tbody>
                    <tr>
                      <td className="title">Charge Record:</td>
                      <td className="value">{toCompanyHouseLink(practitionerLink)}</td>
                    </tr>
                  </tbody>
                )}
              </table>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container className="summary-container">
            <Grid item className="summary" xs={12}>
              <h3>Latest notices</h3>
              <hr className="short" />
              {!notices.length && (
                <p>
                  <i>There are no Notices Available</i>
                </p>
              )}
              {notices.length > 0 && (
                <>
                  <table style={{ width: "inherit" }}>
                    <tbody>
                      {notices.map((n, idx) => (
                        <tr key={`notices-${idx}`}>
                          <td>{n["category"]}:</td>
                          <td>{toCompanyHouseLink(n["links"]["self"] + "/document?format=pdf&download=0", n["description"].replace(/-/g, " "))}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      {props.isLoadingExtraData && (
        <div style={{ height: 500, width: "100%", textAlign: "center", color: "black" }}>
          <CircularProgress />
          <br />
          <span>Please wait, this can take up to a minute to complete</span>
        </div>
      )}
      {!props.isLoadingExtraData && (
        <>
          <Grid className="counter-container" container style={{ pageBreakBefore: 'always' }}>
            {countersPropertyInfo.map((c, idx) => (
              <Grid key={`counter-${idx}`} className="counter" item md={2} xs={4}>
                <div className="title">{c.text}</div>
                <div className="value">{c.value}</div>
              </Grid>
            ))}
          </Grid>
          <Grid container>
            {Object.keys(infoTiles).map((key) => (
              <Grid item xs={6} md={6} key={key}>
                <Grid container className="summary-container">
                  <Grid item className="summary" xs={12}>
                    <h3>{infoTiles[key].title}</h3>
                    <hr className="short" />
                    <table>
                      <tbody>
                        {infoTiles[key].data.map((t, idx) => (
                          <tr key={`cs-${key}-${idx}`}>
                            <td className="title">{t.text}:</td>
                            <td className="value">{toValue(t.value)}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </Grid>
                </Grid>
              </Grid>
            ))}
          </Grid>
        </>
      )}
    </Container>
  );

  function getExtraData(field) {
    return props.extraData?.[field] || "-";
  }
}

function plural(singular, amount) {
  return `${singular}${amount === 1 ? "" : "s"}`;
}
function yearsMonths(sDate) {
  if (!sDate) return "n/a Yrs n/a mths";
  const past_date = new Date(sDate);
  const current_date = new Date();
  const difference = current_date.getFullYear() * 12 + current_date.getMonth() - (past_date.getFullYear() * 12 + past_date.getMonth());
  const years = Math.floor(difference / 12);
  const months = difference % 12;
  return `${years} ${plural("Yr", years)} ${months} ${plural("mth", months)}`;
}
function daysSince(sDate) {
  // a and b are javascript Date objects
  if (!sDate) return "n/a days";
  const _MS_PER_DAY = 1000 * 60 * 60 * 24;
  const a = new Date(sDate);
  const b = new Date();

  // Discard the time and time-zone information.
  const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
  const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());
  const days = Math.floor((utc2 - utc1) / _MS_PER_DAY);
  return `${days} ${plural("day", days)}`;
}
function formatAddress(a = { postal_code: "n/a", locality: "n/a", address_line_1: "n/a" }) {
  return [a.address_line_1, a.locality, a.postal_code];
}
function toValue(value) {
  return value || null;
}
function toCompanyHouseLink(link, text = "Companies House Record") {
  return !link ? null : (
    <a href={`https://beta.companieshouse.gov.uk${link}`} target="_blank" rel="noopener noreferrer" style={{ textDecoration: "underline" }}>
      {text}
    </a>
  );
}
function toLines(lines) {
  if (!lines || !lines.length) return null;

  return lines
    .filter((a) => typeof a === "string")
    .map((l, idx) => (
      <span key={`line-${idx}`}>
        {l}
        <br />
      </span>
    ));
}
function tv(text: string, value: any): TextValue {
  return { text, value };
}
function flattenObject(o) {
  return Object.keys(o)
    .map((k) => o[k])
    .join(", ");
}

function printPDF(event) {
  event.preventDefault();
  document.getElementsByTagName("html")[0].classList.add("print");
  const printBtn: HTMLElement | null = document.querySelector(".pdf-print-button");
  let printButtonDisplayValue = '';
  if (printBtn) {
    printButtonDisplayValue = printBtn.style.display;
    printBtn.style.display = "none";
  }
  window.print();
  if (printBtn) {
    printBtn.style.display = printButtonDisplayValue;
  }
  document.getElementsByTagName("html")[0].classList.remove("print");
  window.history.back();
}
