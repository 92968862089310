import React, { useState, useEffect } from "react";
import { Map } from "leaflet";
import { MapContainer, TileLayer, Polygon, Marker, LayersControl, LayerGroup, Popup, useMap } from "react-leaflet";
// import MarkerClusterGroup from "react-leaflet-markercluster";
import * as L from "leaflet";
import {
  LAYER_NAME_CONSERVATION,
  LAYER_NAME_FLOOD_RISK,
  LAYER_NAME_HISTORIC_BUILDINGS,
  LAYER_NAME_PLANNING,
  LAYER_NAME_PROPERTY,
  LAYER_NAME_POSTCODE,
  ZoomValues,
} from "./types";
import { usePegman } from "../Pegman/Pegman";

const pathOptions = { color: "#FF0000", layerName: LAYER_NAME_PROPERTY };
const floodZonePathOptions = { color: "#0000AA", id: "floodZone", layerName: LAYER_NAME_FLOOD_RISK };
const conversationAreasPathOptions = { color: "#00AA00", layerName: LAYER_NAME_CONSERVATION };
const postcodePolygonPathOptions = { color: "#AA0000", layerName: LAYER_NAME_CONSERVATION };

const osApiKey = "6OuZqwhHG9WUuo4ORFtzuoXtDfblyFPb";
const osServiceUrl = `https://api.os.uk/maps/raster/v1/zxy/Road_3857/{z}/{x}/{y}.png?key=${osApiKey}`;
// const googleKey = "AIzaSyAEnfWOEM3Om9Lg51fmaMcOxwEfhd6pWN0";
const googleServiceUrl = `https://khm.googleapis.com/kh?v=930&hl=en-GB&x={x}&y={y}&z={z}`; //&key=${googleKey}`;

interface Props {
  titleNumber?: string;
  address: string;
  showOutcodeLayerOnly: boolean;
  mappingData: {
    propertyPolygon: any[];
    historicPoints: any[];
    floodRiskZones: any[];
    conversationAreas: any[];
    planningPoints: any[];
    centre: number[];
    loading: boolean;
    visibility: any;
    toggleVisibility: (layerName: string) => void;
    zoom: { value: ZoomValues };
    zoomSet: (value: ZoomValues) => void;
    postcodePolygon: any[];
  };
}

const greenIcon = L.icon({
  iconUrl: "/images/marker-icon-green.png",
});

function AddPegMan() {
  const map = useMap();
  usePegman(map);
  return <></>;
}


export default function OSMap(props: Props) {
  const {
    propertyPolygon,
    historicPoints = [],
    floodRiskZones = [],
    conversationAreas = [],
    planningPoints = [],
    visibility,
    toggleVisibility,
    zoom,
    zoomSet,
    postcodePolygon = [],
  } = props.mappingData;
  const [mapsServiceUrl, mapsServiceUrlSet] = useState(osServiceUrl);
  const [satelliteView, satelliteViewSet] = useState(true);
  const [mapInstance, mapInstanceSet] = useState<Map | null>(null);

  let centre;
  if (props.mappingData?.centre?.length === 2) centre = props.mappingData?.centre;

  function toggleMapView() {
    if (mapsServiceUrl === googleServiceUrl) {
      mapsServiceUrlSet(osServiceUrl);
    } else {
      mapsServiceUrlSet(googleServiceUrl);
    }
    satelliteViewSet(!satelliteView);
  }

  useEffect(() => {
    mapInstance?.on("overlayadd overlayremove", (e) => toggleVisibility(e["name"]));
    mapInstance?.on("zoom", (e) => zoomSet(e.target.getZoom()));
  }, [mapInstance, toggleVisibility, zoomSet]);

  return (
    <>
      {centre && satelliteView && (
        <>
          <MapContainer
            center={centre as any}
            zoom={zoom.value}
            scrollWheelZoom={true}
            className="google-map"
            whenCreated={(mapInstance) => mapInstanceSet(mapInstance)}
            preferCanvas
          >
            <AddPegMan />
            <TileLayer url={googleServiceUrl} maxZoom={20} />
            <LayersControl position="topright">
              <LayersControl.Overlay name={LAYER_NAME_POSTCODE} checked={visibility[LAYER_NAME_POSTCODE]}>
                <LayerGroup>
                  {postcodePolygon?.length &&
                    postcodePolygon.map((fz, idx) => <Polygon key={`pc-${idx}`} pathOptions={postcodePolygonPathOptions} positions={fz} />)}
                </LayerGroup>
              </LayersControl.Overlay>

              <LayersControl.Overlay name={LAYER_NAME_PROPERTY} checked={visibility[LAYER_NAME_PROPERTY]}>
                <LayerGroup>{propertyPolygon && <Polygon pathOptions={pathOptions} positions={propertyPolygon} />}</LayerGroup>
              </LayersControl.Overlay>

              <LayersControl.Overlay name={LAYER_NAME_HISTORIC_BUILDINGS} checked={visibility[LAYER_NAME_HISTORIC_BUILDINGS]}>
                <LayerGroup>
                  {historicPoints?.length &&
                    historicPoints.map(({ lat, lng, name }, idx) => (
                      <Marker key={`hbp-${idx}`} position={{ lat, lng }}>
                        <Popup>{name}</Popup>
                      </Marker>
                    ))}
                </LayerGroup>
              </LayersControl.Overlay>

              <LayersControl.Overlay name={LAYER_NAME_PLANNING} checked={visibility[LAYER_NAME_PLANNING]}>
                <LayerGroup>
                  {planningPoints?.length &&
                    planningPoints.map(({ lat, lng, app_type, description }, idx) => (
                      <Marker key={`pl-${idx}`} position={{ lat, lng }} icon={greenIcon}>
                        <Popup>
                          Planning: {app_type}
                          <br />
                          {description}
                        </Popup>
                      </Marker>
                    ))}
                </LayerGroup>
              </LayersControl.Overlay>

              <LayersControl.Overlay name={LAYER_NAME_FLOOD_RISK} checked={visibility[LAYER_NAME_FLOOD_RISK]}>
                <LayerGroup>
                  {floodRiskZones?.length && floodRiskZones.map((fz, idx) => <Polygon key={`fz-${idx}`} pathOptions={floodZonePathOptions} positions={fz} />)}
                </LayerGroup>
              </LayersControl.Overlay>

              <LayersControl.Overlay name={LAYER_NAME_CONSERVATION} checked={visibility[LAYER_NAME_CONSERVATION]}>
                <LayerGroup>
                  {conversationAreas?.length &&
                    conversationAreas.map((fz, idx) => <Polygon key={`ca-${idx}`} pathOptions={conversationAreasPathOptions} positions={fz} />)}
                </LayerGroup>
              </LayersControl.Overlay>
            </LayersControl>
          </MapContainer>
        </>
      )}

      {centre && !satelliteView && (
        <>
          <MapContainer
            center={centre as any}
            zoom={zoom.value}
            scrollWheelZoom={true}
            className="google-map"
            whenCreated={(mapInstance) => mapInstanceSet(mapInstance)}
            preferCanvas
          >
            <TileLayer url={osServiceUrl} maxZoom={20} />
            <LayersControl position="topright">
              <LayersControl.Overlay name={LAYER_NAME_POSTCODE} checked={visibility[LAYER_NAME_POSTCODE]}>
                <LayerGroup>
                  {postcodePolygon?.length &&
                    postcodePolygon.map((fz, idx) => <Polygon key={`pc-${idx}`} pathOptions={postcodePolygonPathOptions} positions={fz} />)}
                </LayerGroup>
              </LayersControl.Overlay>

              <LayersControl.Overlay name={LAYER_NAME_PROPERTY} checked={visibility[LAYER_NAME_PROPERTY]}>
                <LayerGroup>{propertyPolygon && <Polygon pathOptions={pathOptions} positions={propertyPolygon} />}</LayerGroup>
              </LayersControl.Overlay>

              <LayersControl.Overlay name={LAYER_NAME_HISTORIC_BUILDINGS} checked={visibility[LAYER_NAME_HISTORIC_BUILDINGS]}>
                <LayerGroup>
                  {historicPoints?.length &&
                    historicPoints.map(({ lat, lng, name }, idx) => (
                      <Marker key={`hbp-${idx}`} position={{ lat, lng }}>
                        <Popup>{name}</Popup>
                      </Marker>
                    ))}
                </LayerGroup>
              </LayersControl.Overlay>

              <LayersControl.Overlay name={LAYER_NAME_PLANNING} checked={visibility[LAYER_NAME_PLANNING]}>
                <LayerGroup>
                  {planningPoints?.length &&
                    planningPoints.map(({ lat, lng, app_type, description }, idx) => (
                      <Marker key={`pl-${idx}`} position={{ lat, lng }} icon={greenIcon}>
                        <Popup>
                          Planning: {app_type}
                          <br />
                          {description}
                        </Popup>
                      </Marker>
                    ))}
                </LayerGroup>
              </LayersControl.Overlay>

              <LayersControl.Overlay name={LAYER_NAME_FLOOD_RISK} checked={visibility[LAYER_NAME_FLOOD_RISK]}>
                <LayerGroup>
                  {floodRiskZones?.length && floodRiskZones.map((fz, idx) => <Polygon key={`fz-${idx}`} pathOptions={floodZonePathOptions} positions={fz} />)}
                </LayerGroup>
              </LayersControl.Overlay>

              <LayersControl.Overlay name={LAYER_NAME_CONSERVATION} checked={visibility[LAYER_NAME_CONSERVATION]}>
                <LayerGroup>
                  {conversationAreas?.length &&
                    conversationAreas.map((fz, idx) => <Polygon key={`ca-${idx}`} pathOptions={conversationAreasPathOptions} positions={fz} />)}
                </LayerGroup>
              </LayersControl.Overlay>
            </LayersControl>
          </MapContainer>
        </>
      )}

      {!satelliteView ? <button onClick={toggleMapView}>Road Map View</button> : <button onClick={toggleMapView}>Satellite View</button>}
    </>
  );
}
