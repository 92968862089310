import React from "react";
import Chart from "react-google-charts";

interface Props {
  data: [],
  hasLoaded: boolean,
}

export default function NewLineGraph(props: Props) {
  const {data, hasLoaded} = props;

  if (hasLoaded) {
    return (
      <Chart
        style={{ 
          margin: "0 auto", 
          width:"90%", 
        }}
        
        chartType="Line"
        data={data}
        options={{
          title: "Properties Added by Date",
          legend: { position: "none" },
          colors: ['orange','green'], // sets the line colour
          backgroundColor: "black",
          animation: {
            startup: true,
            easing: 'linear',
            duration: 1500,
    },
        }}
      />
    );
  }
  return null;
}
