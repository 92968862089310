import React, { useEffect, useState } from "react";
import "./ContactUs.scss";
import { Grid, TextField, Button, Container, CircularProgress } from "@material-ui/core";
import { GoogleMap } from "./GoogleMap";
import { OtherInfo } from "./OtherInfo";

interface Props {
  name: string;
  company: string;
  subject: string;
  email: string;
  onSubmit: Function;
}

const infoWindowContentString = `
  <div className="map-info">
    <div className="map-title">
      <div className="brand">
        <img alt="" src="images/brand.png" />
        <div className="brand-info">
          <div className="brand-name">Hammered</div>
          <div className="brand-text">social</div>
        </div>
        <p className="map-address">
          <div className="map-address-row">
            <i className="fa fa-map-marker"></i>
            <span className="text">
              <strong>HAMMERED Leads, Silverstream House, 45 Fitzroy Street, London,W1T 6EB.</strong>
              <br />
            </span>
          </div>
          <div className="map-address-row">
            <i className="fa fa-phone"></i>
            <span className="text">(+44) 2071909706</span>
          </div>
          <div className="map-address-row">
            <span className="map-email">
              <i className="fa fa-envelope"></i>
              <span className="text">contact@hammeredleads.co.uk</span>
            </span>
          </div>
        </p>
      </div>
    </div>
  </div>`;

export default function ContactUs(props: Props) {
  return (
    <Container className="contactmain">
      <h1>CONTACT US</h1>
      <Grid container className="contact_us">
        <Grid item xs={12}>
          <GoogleMap lat={51.523922533198665} lng={-0.14053388781080386} infoWindowContentString={infoWindowContentString} />
        </Grid>
        <Grid item md={8}>
          <ContactForm {...props} />
        </Grid>
        <Grid item md={4}>
          <OtherInfo />
        </Grid>
      </Grid>
    </Container>
  );
}
const DEFAULT_FIELD_VALUE = "None";

function ContactForm(props: Props) {
  const { onSubmit } = props;
  const [email, emailSet] = useState(props.name || "");
  const [name, nameSet] = useState(props.name || "");
  const [company, companySet] = useState(props.company || "");
  const [subject, subjectSet] = useState(props.subject || "");
  const [message, messageSet] = useState("");
  const [isSubmitting, isSubmittingSet] = useState(false);
  const update = (setFn) => (event) => setFn(event.target.value);

  useEffect(() => {
    nameSet(props.name || DEFAULT_FIELD_VALUE);
    companySet(props.company || DEFAULT_FIELD_VALUE);
    emailSet(props.email);
  }, [props]);

  async function doTheSubmit(event) {
    isSubmittingSet(true);
    try {
      await onSubmit(event);
      nameSet(props.name || "");
      companySet(props.company || "");
      subjectSet(props.subject || "");
      messageSet("");
    } catch (e: any) {
      console.log("error when submitting contact form", e);
      alert(`An error occured when submitting the contact form\n\nPlease try again.`);
    } finally {
      isSubmittingSet(false);
    }
  }

  return (
    <form onSubmit={doTheSubmit}>
      <Grid container justifyContent="flex-end" spacing={2}>
        <input type="hidden" name="email" value={email} />
        <Grid item xs={6}>
          <TextField
            disabled={isSubmitting}
            label="Company"
            name="company"
            variant="filled"
            value={company}
            onChange={update(companySet)}
            fullWidth
            required
          />
        </Grid>
        <Grid item xs={6}>
          <TextField disabled={isSubmitting} label="Name" name="name" variant="filled" value={name} onChange={update(nameSet)} fullWidth required />
        </Grid>
        <Grid item xs={12}>
          <TextField disabled={isSubmitting} label="Subject" name="subject" variant="filled" value={subject} onChange={update(subjectSet)} fullWidth required />
        </Grid>

        <Grid item xs={12}>
          <TextField
            autoFocus
            disabled={isSubmitting}
            label="Message"
            name="message"
            variant="filled"
            fullWidth
            multiline
            rows={6}
            value={message}
            onChange={update(messageSet)}
            required
          />
        </Grid>
        <Grid item>
          <Button disabled={isSubmitting} type="submit" variant="contained">
            submit
            {isSubmitting && <CircularProgress size={15} />}
          </Button>
        </Grid>
      </Grid>
    </form>
  );
}
