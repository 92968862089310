import React, { useEffect, useState } from "react";
import { Select, TextField, Tooltip } from "@material-ui/core";
import InfoIcon from "@material-ui/icons/Info";
import { withRouter } from "react-router-dom";
import Board from "react-trello";
import { saveMyPropertyPositions } from "../../api/Api";
import { CardPositions } from "../Containers/types";
import CardEditableNote from "react-trello/dist/components/CardEditableNote";
import "./MyPropertiesContainer.scss";
import { Button } from "@material-ui/core";
import { exportToSpreadsheet } from "../../libs/exportSpreadsheetFile";
import usePropertyColours from "./usePropertyColours";
import { ColorPicker } from "./ColourPicker";
import useAssignedToList from "./useAssignedToList";
import { Card, Lanes, Lane } from "./types";
import { dataFn } from "./api";
import { propertiesToLanes, updateFromEventValue } from "./utils";

function lanesToCardPositions(data: Lanes): CardPositions[] {
  const cardPositions: CardPositions[] = [];
  const index = {};
  data.lanes.forEach((lane: Lane) => {
    lane.cards.forEach((card: Card, position) => {
      const laneId = parseInt(card["laneId"], 10);
      const propertyId = card.metadata.id;
      const note = card.note || "";
      const cardId = card.id;
      const assignedTo = card.assignedTo || "";
      const cardPosition: CardPositions = { position, laneId, propertyId, note, cardId, assignedTo };
      cardPositions.push(cardPosition);
      index[propertyId] = cardPosition;
    });
  });
  return cardPositions;
}

async function saveChangedData(lanes: Lanes) {
  const cardPositions: CardPositions[] = lanesToCardPositions(lanes);
  try {
    return await saveMyPropertyPositions(cardPositions);
  } catch (e: any) {
    if (e.message.indexOf("updated 0 rows") > 0) return;
    console.log(e);
  }
}

const loadingData = { lanes: [{ id: "loading", title: "loading..", cards: [] }] };

function viewProperty(history, rowData) {
  history.push(`/my-properties/${rowData.id}/${rowData.title_type}`);
}

function exportData(data) {
  // regex = /[//\\/?/*/[/]]/g; // disallow: \ / ? * [ ] doesn't work with / for some reason
  const columnsCandidate = data.lanes.find((l) => l.cards.length > 0);
  if (!columnsCandidate) return alert("No data to export");
  const columns = Object.keys(columnsCandidate.cards[0].metadata);
  const formatTitle = (string) => string.substr(0, 30);
  const convertKanbanDataToSpreadsheetData = (previous, lane) => {
    let rows = [columns];
    rows = [...rows, ...lane.cards.map((card) => Object.keys(card.metadata).map((field) => card.metadata[field]))];
    return Object.assign(previous, { [formatTitle(lane.title)]: rows });
  };

  const output = data.lanes.reduce(convertKanbanDataToSpreadsheetData, {});
  exportToSpreadsheet(output, "exportedFile");
}

function skipTrelloBoardLaneSetupDataChange(firstLoadFlag, firstLoadFlagSet, newData: Lanes, oldData: Lanes) {
  if (firstLoadFlag) {
    firstLoadFlagSet(false);
    return true;
  }
  const oldDataNotSetup = oldData.lanes.some((lane: Lane) => lane.cards.some((card: Card) => card.positionId === undefined || card.laneId === undefined));
  return oldDataNotSetup;
}

const searchFn = (value) => (fieldValue) => fieldValue.toLowerCase().indexOf(value) >= 0;

function changeTrueToEmptyQuoteInAssignedTo(data: Lanes): Lanes {
  const fixedList: Lanes = {
    lanes: data.lanes.map((lane) => {
      const fixedLane: Lane = Object.assign({}, lane);
      fixedLane.cards = fixedLane.cards.map((card) => {
        const fixedCard: Card = Object.assign({}, card);
        if (fixedCard.assignedTo === "true") fixedCard.assignedTo = "";
        return fixedCard;
      });
      return fixedLane;
    }),
  };
  return fixedList;
}

function MyPropertiesContainer(props) {
  const [data, dataSet] = useState<Lanes>({ lanes: [] });
  const [originalData, originalDataSet] = useState<Lanes>({ lanes: [] });
  const [sourceData, sourceDataSet] = useState<any[]>([]);
  const [updateOriginalDataFlag, updateOriginalDataFlagSet] = useState<number>(0);
  const [isLoading, isLoadingSet] = useState(true);
  const [isSaving, isSavingSet] = useState(false);
  const [searchFilter, searchFilterSet] = useState("");
  const [errorMessage, errorMessageSet] = useState("");
  const [firstLoadFlag, firstLoadFlagSet] = useState<boolean>(true);
  const assignedToList = useAssignedToList();
  const [propertyColours, propertyColoursSet] = usePropertyColours();
  const [selectedUser, selectedUserSet] = useState("");
  const [boardKey, boardKeySet] = useState(0);

  // handle intial data loading
  useEffect(() => {
    (async () => {
      errorMessageSet("");
      let properties: any[] | string;

      properties = await dataFn();
      if (typeof properties === "string") {
        errorMessageSet(properties);
        return;
      }
      sourceDataSet(properties);

      // Reformat properties to lanes
      const [lanes] = propertiesToLanes(properties, assignedToList, propertyColours);
      const data = { lanes };
      dataSet(data);
      originalDataSet(data);
      isLoadingSet(false);
    })();
    // eslint-disable-next-line
  }, [updateOriginalDataFlag, assignedToList]);

  useEffect(() => {
    const properties = sourceData;
    // Reformat properties to lanes
    const [lanes] = propertiesToLanes(properties, assignedToList, propertyColours);
    const data = { lanes };
    dataSet(data);
    originalDataSet(data);
    boardKeySet(boardKey + 1); // force board resync by updating it's key field
    // eslint-disable-next-line
  }, [sourceData, assignedToList, propertyColours]);

  useEffect(() => {
    if (originalData?.lanes.length) {
      const searchstring = searchFilter.toLowerCase();
      const inStr = searchFn(searchstring);

      const filteredData = originalData.lanes.map((rawLane) => {
        const lane = Object.assign({}, rawLane);
        lane.cards = lane.cards.filter(
          (c) =>
            inStr(c.description) ||
            inStr(c.note) ||
            inStr(c.metadata.property_address) ||
            inStr(c.metadata.postcode) ||
            inStr(c.metadata.district) ||
            inStr(c.metadata.county) ||
            inStr(c.metadata.region) ||
            false
        );
        if (selectedUser) {
          if (selectedUser === "NO_ONE") {
            lane.cards = lane.cards.filter((c) => c.assignedTo === null);
          } else {
            // eslint-disable-next-line
            lane.cards = lane.cards.filter((c) => c.assignedTo == selectedUser);
          }
        }
        return lane;
      });

      dataSet({ lanes: filteredData });
      boardKeySet(boardKey + 1); // force board resync by updating it's key field
    }
    // eslint-disable-next-line -- add to silence boardKey missing from dependency array
  }, [searchFilter, originalData, selectedUser]);

  async function onBoardDataChange(newData: Lanes) {
    if (isSaving || isLoading) return;
    if (newData.lanes[0].id === "loading" || data.lanes[0].id === "loading") return;
    if (skipTrelloBoardLaneSetupDataChange(firstLoadFlag, firstLoadFlagSet, newData, data)) return;
    const noChanges = JSON.stringify(newData) === JSON.stringify(data);
    if (noChanges) return;
    isSavingSet(true);
    const fixedData = changeTrueToEmptyQuoteInAssignedTo(newData);
    await saveChangedData(fixedData);
    updateOriginalDataFlagSet(updateOriginalDataFlag + 1);
    isSavingSet(false);
  }

  return (
    <>
      <div style={{ textAlign: "center", margin: "0px" }}>
        <h1>OUR PROPERTIES</h1>
      </div>

      {errorMessage && <p>There was an error: {errorMessage}</p>}
      {!data && <p>No results to display</p>}
      {data && (
        <>
          <div className="mypropertiesheaderbar">
            <TextField
              placeholder="Search..."
              value={searchFilter}
              onChange={updateFromEventValue(searchFilterSet)}
              variant="outlined"
              size="small"
              style={{ margin: "0px 20px" }}
            />
            <Button className="MuiButton-contained" onClick={() => searchFilterSet("")} disabled={searchFilter.length === 0}>
              Clear
            </Button>
            <Button className="MuiButton-contained exportkanban" onClick={() => exportData(data)}>
              Export Data
            </Button>
            <Tooltip
              style={{ marginLeft: 5 }}
              title="You can export all your purchased leads into an .xlsx file, just click on the Export Button."
              aria-label="add"
            >
              <InfoIcon fontSize="small" />
            </Tooltip>
            <div style={{ display: "flex" }}>
              <ColorPicker
                text="Distressed"
                colourHex={propertyColours.distressed}
                onChange={(colourHex) => propertyColoursSet(Object.assign({}, propertyColours, { distressed: colourHex }))}
              />
              <ColorPicker
                text="Stressed"
                colourHex={propertyColours.stressed}
                onChange={(colourHex) => propertyColoursSet(Object.assign({}, propertyColours, { stressed: colourHex }))}
              />
              <Select value={selectedUser} onChange={(event) => selectedUserSet(event.target.value as string)} native>
                <option value="">All Users</option>
                <option value="NO_ONE">No One</option>
                {Object.keys(assignedToList).map((key) => (
                  <option value={key}>{assignedToList[key]}</option>
                ))}
              </Select>
            </div>
          </div>
          <Board
            key={boardKey}
            className="boardContainer"
            data={isLoading ? loadingData : data}
            collapsibleLanes
            onDataChange={onBoardDataChange}
            onCardClick={(_, property) => viewProperty(props.history, property)}
            style={{
              backgroundColor: "#fff",
              marginTop: "20px",
            }}
            components={{ Card: CardEditableNote }}
          />
        </>
      )}
    </>
  );
}

export default withRouter(MyPropertiesContainer);
