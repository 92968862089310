import React, { useState } from "react";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContentText,
  DialogTitle,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@material-ui/core";
import { toTitleCase } from "../../libs/helpres";

export interface IDialogConfig {
  title: string;
  description: string;
  onClose?: () => {};
  onOk?: Function;
  fields: any[];
  longCallIndicator: boolean;
}
export interface CalculatorDialogProps {
  open: boolean;
  config?: IDialogConfig;
}
const setFieldValue = (formDataSet, formData, field) => (e) => formDataSet(Object.assign({}, formData, { [field.field]: e.target.value }));

export default function CalculatorDialog(props: CalculatorDialogProps) {
  const initialValues = {};
  props.config?.fields.forEach((field) => {
    initialValues[field.field] = field.options ? field.options[0] : field.value || "";
  });
  const { onClose, onOk, title, description, fields } = props?.config || {};
  const [formData, formDataSet] = useState(initialValues);
  const [isBusy, isBusySet] = useState(false);
  const longCallIndicator = props?.config?.longCallIndicator || false;

  return (
    <Dialog className="simple-dialog-container" onClose={()=>!isBusy && onClose?.()} open={props.open}>
      <DialogTitle style={{ textAlign: "center" }}>{title}</DialogTitle>
      <DialogContentText component="div">
        <div style={{ textAlign: "center" }}>{description}</div>
        <Grid container>
          {fields?.map((f, idx) => (
            <Grid key={`field-${idx}`} item xs={12} md={6} style={{ textAlign: "center" }}>
              {f.type === "Number" && (
                <TextField
                  required={f.required}
                  label={toTitleCase(f.label)}
                  variant="outlined"
                  style={{ margin: 10 }}
                  type="number"
                  inputProps={{min: 0}}
                  value={formData[f.field]}
                  onChange={setFieldValue(formDataSet, formData, f)}
                  disabled={f.disabled || isBusy}
                />
              )}
              {f.type === "String" && (
                <TextField
                  required={f.required}
                  label={toTitleCase(f.label)}
                  variant="outlined"
                  style={{ margin: 10 }}
                  type="text"
                  value={formData[f.field]}
                  onChange={setFieldValue(formDataSet, formData, f)}
                  disabled={f.disabled || isBusy}
                />
              )}
              {f.options && (
                <FormControl>
                  <InputLabel id="demo-simple-select-label">{toTitleCase(f.label)}</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    required
                    onChange={setFieldValue(formDataSet, formData, f)}
                    value={formData[f.field]}
                    style={{ width: 200, margin: 10 }}
                    variant="outlined"
                    disabled={f.disabled || isBusy}
                    >
                    {f.options.map((o, idx) => (
                      <MenuItem key={`menuitem-${idx}`} value={o}>
                        {toTitleCase(`${o}`)}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
            </Grid>
          ))}
        </Grid>
      </DialogContentText>
      <DialogActions style={{ backgroundColor: "white" }}>
        {isBusy && longCallIndicator && <span>Please wait, this can be a long call</span>}
        {isBusy && <CircularProgress />}
        {!isBusy && (
          <>
            <Button className="MuiButton-contained-modal" variant="outlined" onClick={onClose}>
              Cancel
            </Button>
            <Button className="MuiButton-contained-modal" variant="outlined" disabled={!onOk} onClick={() => [isBusySet(true), onOk && onOk(formData)]}>
              OK
            </Button>
          </>
        )}
      </DialogActions>
      <DialogContentText style={{ textAlign: "center", fontSize: "small", marginTop: 16 }}>
        <b>Disclaimer:</b> This estimate is strictly for guidance only and we are not liable for any loss or damage following use or reliance on the estimation
        provided. The calculation of any property remains the responsibility of the owner of the property. Providing an accurate cost for insurance or any other
        purposes remains your responsibility. If any are in any doubt about the accuracy of the cost provided in these calculators, you should hire a chartered
        surveyor to provide a professional opinion.
      </DialogContentText>
    </Dialog>
  );
}
