import React, { useState, useEffect } from "react";
import { API } from "aws-amplify";
import { getSub } from "../../api/Api";
import { getInitialisedMessage } from "../../libs/session";
import { numberWithCommas } from "../../libs/helpres";
import "./ResultsLikeTable.scss";
import TablePagination from "@material-ui/core/TablePagination";
import { Button } from "@material-ui/core";
import { PROPERTY_TYPE_LOOKUP } from "../SearchResultTiles/SearchResultTiles";
import BorderLinearProgress from "../BorderLinearProgress/BorderLinearProgress";

const columns = [
  { title: "id", field: "id", hidden: true },
  { title: "Postcode", field: "postcode" },
  {
    title: "Tenure",
    field: "tenure",
    lookup: {
      Freehold: "Freehold",
      Leasehold: "Leasehold",
    },
  },
  { title: "Lease Term", field: "lease_term" },
  { title: "District", field: "district" },
  { title: "County", field: "county" },
  { title: "Region", field: "region", hidden: true },
  // { title: "Floor Size", field: "total_floor_area" },
  { title: "Plot Size (acre)", field: "plot_size" },
  { title: "Land", field: "land" },
  { title: "Property Type", field: "property_type", emptyValue: "Coming Soon" },
  { title: "Price Paid", field: "price_paid", classNames: "text-right" },
  { title: "Date Added", field: "date_added", defaultSort: "desc" as "asc" | "desc" },
];
const DEFAULT_RESULT_SIZE = 25;
const DEFAULT_PAGE = 0;
const ASC = "asc";
const DESC = "desc";

interface ActionProps {
  icon: string;
  tooltip: string;
  onClick: Function;
}
interface PropertyTableProps {
  endpointName: string;
  tableTitle: string;
  actions?: ActionProps[];
  options?: any;
  query?: string[] | string;
}

export default function PropertyTable(props: PropertyTableProps) {
  const [previousQuery, setPreviousQuery] = useState("");
  const [data, dataSet] = useState([]);
  const [page, pageSet] = useState(DEFAULT_PAGE);
  const [rowsPerPage, rowsPerPageSet] = useState(DEFAULT_RESULT_SIZE);
  const [totalCount, totalCountSet] = useState(0);
  const [sortBy, sortBySet] = useState("");
  const [sortAsc, sortAscSet] = useState(ASC);
  const [isLoading, isLoadingSet] = useState(false);

  const { actions, endpointName = "property-in-distress" } = props;

  // trigger dataFn if query has been updated
  useEffect(() => {
    if (JSON.stringify(props.query) !== previousQuery) {
      setTimeout(() => {
        setPreviousQuery(JSON.stringify(props.query) || "");
      }, 500);
    }
  }, [props.query, previousQuery]);

  // trigger dataFn if any query related variable is updated
  useEffect(() => {
    if (JSON.stringify(props.query) !== previousQuery) return;
    const paginatorSettings = { page, pageSize: rowsPerPage, orderBy: { field: sortBy, orderDirection: sortAsc } };
    const query = Object.assign({}, props.query, paginatorSettings);
    dataFn(props, endpointName, dataSet, pageSet, rowsPerPageSet, totalCountSet, isLoadingSet, query);
    // eslint-disable-next-line
  }, [previousQuery, props.query, page, rowsPerPage, sortBy, sortAsc]);

  const tablePaginationProps = {
    totalCount,
    page,
    pageSet,
    pageSize: rowsPerPage,
    pageSizeSet: rowsPerPageSet,
  };

  const tableDataProps = {
    data,
    columns,
    actions,
  };

  return (
    <>
      {isLoading && (
        <BorderLinearProgress color="primary" />
      )}
      <table className={`result-table ${isLoading?'loading':''}`}>
        <thead>
          <tr>
            {columns.map(
              (c, idx) =>
                !c.hidden && (
                  <th key={idx} onClick={() => sortByAndToggle(sortBy, sortAscSet, sortAsc, sortBySet, c)}>
                    {sortByIndicator(sortBy, sortAsc, c.field)}
                    {c.title}
                  </th>
                )
            )}
            {actions && actions.length > 0 && <th>Actions</th>}
          </tr>
        </thead>
        {data.length === 0 ? <NoDataBody {...tableDataProps} /> : <DataBody {...tableDataProps} />}
      </table>
      <TablePaginationCtrl {...tablePaginationProps} />
      <br />
    </>
  );

}

async function dataFn(props, endpointName, dataSet, pageSet, rowsPerPageSet, totalCountSet, isLoadingSet, query) {
  // basic query bits
  const pageSize = query.pageSize;
  const page = query.page;
  let orderBy = {};

  // order by
  if (query.orderBy) {
    orderBy["field"] = query.orderBy.field;
    orderBy["asc"] = query.orderBy.orderDirection === "asc";
  }

  let where: any[] = !props.query ? [] : typeof props.query === "string" ? [props.query] : props.query;
  try {
    isLoadingSet(true);
    const response = await loadData(endpointName, page * pageSize, pageSize, orderBy, where);
    isLoadingSet(false);
    response.data.data = response.data.data.map((p) =>
      Object.assign(p, {
        total_floor_area: numberWithCommas(p.total_floor_area),
        price_paid: numberWithCommas(p.price_paid),
      })
    );
    dataSet(response.data.data);
    pageSet(response.data.page);
    rowsPerPageSet(response.data.count);
    totalCountSet(response.data.totalCount);
  } catch (e: any) {
    console.log(e);
    const blankResponse = {
      data: [],
      page: 0,
      totalCount: 0,
    };
    dataSet(blankResponse.data);
  } finally {
    isLoadingSet(false);
  }
}

async function loadData(endpointName, offset: Number, count: Number, orderBy, where) {
  const sub = await getSub();
  const headers = await getInitialisedMessage();
  const init = Object.assign(headers, {
    queryStringParameters: {
      count,
      offset,
    },
  });
  if (orderBy.field) {
    init.queryStringParameters["orderBy"] = `${orderBy.field},${orderBy.asc}`;
  }
  if (where.length) {
    init.queryStringParameters["filters"] = JSON.stringify(where);
  }
  return API.get("internal", `internal/${sub}/${endpointName}`, init);
}

interface TableBodyProps {
  data?: any[];
  columns: any[];
  actions?: any[];
}
function NoDataBody(props: TableBodyProps) {
  const colSpan = props.columns.length + (props?.actions?.length ?? 0);
  return (
    <tbody>
      <tr>
        <td {...{ colSpan }}>No results to display</td>
      </tr>
    </tbody>
  );
}

function DataBody(props: TableBodyProps) {
  const { data = [], columns, actions = [] } = props;
  const TRs = data.map((r, idx) => (
    <tr key={idx}>
      {columns.map(
        (c, idx) =>
          !c.hidden && (
            <td className={c.classNames || ""} key={idx}>
              {c.field === "property_type" ? toPropertyTypeText(r[c.field]) : r[c.field]}
            </td>
          )
      )}
      {actions.length > 0 && (
        <td>
          {actions.map((a, idx) => (
            <Button variant="contained" color="secondary" key={idx} onClick={(e) => a.onClick(e, r)}>
              {a.icon}
            </Button>
          ))}
        </td>
      )}
    </tr>
  ));
  return <tbody>{TRs}</tbody>;
}

function toPropertyTypeText(value: string = ""): string {
  const fieldType: string = ("" + value).toUpperCase();
  return PROPERTY_TYPE_LOOKUP[fieldType]?.text || "-";
}

interface TablePaginationCtrlProps {
  page: number;
  pageSize: number;
  totalCount: number;
  pageSet: (value: number) => void;
  pageSizeSet: (value: number) => void;
}
function TablePaginationCtrl(props: TablePaginationCtrlProps) {
  const handleChangePage = (_, newPage: number) => props.pageSet(newPage);
  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => [
    props.pageSizeSet(parseInt(event.target.value, 10)),
    props.pageSet(DEFAULT_PAGE),
  ];

  return (
    <TablePagination
      count={props.totalCount}
      style={{ color: "white", textAlign: "center" }}
      // component="div"
      page={props.page}
      rowsPerPage={props.pageSize}
      onPageChange={handleChangePage}
      onChangeRowsPerPage={handleChangeRowsPerPage}
    />
  );
}

function sortByIndicator(sortBy, sortAsc, fieldName) {
  if (sortBy === "" || fieldName !== sortBy) return "";
  if (sortAsc === ASC) return <>&#8710; </>;
  if (sortAsc === DESC) return <>&#8711; </>;
}

// cause query to update on sort and field selection
function sortByAndToggle(sortBy, sortAscSet, sortAsc, sortBySet, column) {
  if (sortBy === column.field) {
    return sortAscSet(sortAsc === ASC ? DESC : ASC);
  }
  sortBySet(column.field);
}
