import React from "react";
import { Grid } from "@material-ui/core";
import "./SearchResultTiles.scss";
import { Button } from "@material-ui/core";

interface Props {
  data?;
  actions?: {
    onPurchaseProperty: (rowData: any) => void;
    onDeleteProperty: (rowData: any) => void;
  };
}
interface PropertyResult {
  company_number: string;
  county: null | string;
  date_added: string;
  district: null | string;
  id: number;
  land: string;
  lease_price_paid: null | string;
  lease_term: null | string;
  plot_size: null | string;
  postcode: null | string;
  price_paid: null | string;
  property_type: null | string;
  region: null | string;
  tenure: string;
  title_number: string;
  total_floor_area: null | string;
  // new fields not in database yet
  building_height: null | string;
  building_footprint: null | string;
  Last_Sold_Date: null | string;
}

export default function SearchResultTilesMobile(props: Props) {
  const dashNull = (value) => value || "-";
  const { onPurchaseProperty, onDeleteProperty } = props?.actions || {};

  return props.data.data.map((plot: PropertyResult, idx: number) => (
    <div className="search-result-item" key={idx}>
      <Grid container alignItems="center">
        <Grid item md={3} xs={12}>
          <p style={{ color: "black", textAlign: "center" }}>{plot.property_type || "No image"}</p>
        </Grid>
        <Grid item md={8} xs={12}>
          <table style={{padding: 8}}>
            <tbody>
              <tr>
                <td>Postcode:</td>
                <td>{dashNull(plot.postcode)}</td>
              </tr>
              <tr>
                <td>District:</td>
                <td>{dashNull(plot.district)}</td>
              </tr>
              <tr>
                <td>Region:</td>
                <td>{dashNull(plot.region)}</td>
                </tr>
              <tr>
                <td>Property Type:</td>
                <td>{dashNull(plot.property_type)}</td>
              </tr>
              <tr>
                <td>Plot Size (acres):</td>
                <td>{dashNull(plot.plot_size)}</td>
                </tr>
              <tr>
                <td>Building Heights m:</td>
                <td>{dashNull(plot.building_height)} </td>
              </tr>
              <tr>
                <td>Building Footprint m2:</td>
                <td>{dashNull(plot.building_footprint)}</td>
              </tr>
              <tr>
                <td>Leasehold Term:</td>
                <td>{dashNull(plot.lease_term)}</td>
              </tr>
              <tr>
                <td>Last Sold Price:</td>
                <td>{dashNull(plot.price_paid)}</td>
              </tr>
              <tr>
                <td>Last Sold Date:</td>
                <td>{dashNull(plot.Last_Sold_Date)}</td>
              </tr>
              <tr>
                <td>Date Added:</td>
                <td>{dashNull(plot.date_added)}</td>
              </tr>
            </tbody>
          </table>
        </Grid>
        <Grid item md={1} xs={12}>
          <Grid container alignItems="center">
            <Grid item md={12} xs={6} style={{textAlign: "center", marginBottom: '5px'}}>
             <Button variant="contained" className="resultbutton" size="small" style={{marginBottom: '10px'}}  onClick={() => onPurchaseProperty?.(plot)}>Purchase
              </Button>
            </Grid>
            <Grid item md={12} xs={6} style={{textAlign: "center", marginBottom: '5px'}}>
              <Button variant="contained" className="resultbutton2" size="small" style={{marginBottom: '10px'}} onClick={() => onDeleteProperty?.(plot)}>Delete
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  ));
}
