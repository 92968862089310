import { Button, FormControl, FormControlLabel, Grid, InputLabel, Radio, RadioGroup, Select, TextField } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { getDatesPropertiesAdded } from "../../api/Api";
import { SearchFilterProps } from "../Containers/types";
import "./QueryForm.scss";

interface Props {
  show?: boolean;
  submitFilter: (filters: SearchFilterProps) => void;
}

export default function QueryForm(props: Props) {
  const [priceFrom, priceFromSet] = useState("");
  const [priceTo, priceToSet] = useState("");
  const [plotSizeFrom, plotSizeFromSet] = useState("");
  const [plotSizeTo, plotSizeToSet] = useState("");
  const [dateTo, dateToSet] = useState("");
  const [dateFrom, dateFromSet] = useState("");
  const [land, landSet] = useState("0");
  const [tenure, tenureSet] = useState("both");
  const [datesAdded, datesAddedSet] = useState<string[]>([]);

  useEffect(() => {
    (async () => {
      const results = await getDatesPropertiesAdded();
      datesAddedSet(results);
    })();
  }, []);

  const toggleLand = (e) => {
    landSet(e.target.value);
  };

  const toggleTenure = (e) => {
    tenureSet(e.target.value);
  };

  function numericOnly(value: string) {
    if (!value) return value;
    const lastChar = value.length - 1;
    const char = value.charAt(lastChar);
    if (char >= "0" && char <= "9") {
      return value;
    }
    return value.substr(0, lastChar);
  }

function updateQuery() {
    const query: SearchFilterProps = {
      priceFrom: priceFrom ? parseFloat(priceFrom) : undefined,
      priceTo: priceTo ? parseFloat(priceTo) : undefined,
      plotSizeFrom: plotSizeFrom ? parseFloat(plotSizeFrom) : undefined,
      plotSizeTo: plotSizeTo ? parseFloat(plotSizeTo) : undefined,
      dateTo: dateTo || undefined,
      dateFrom: dateFrom || undefined,
      land,
      tenure,
    };
    props.submitFilter(query);
  }

  return (
    <>
      <Grid container style={{ color: "white" }} spacing={2} justifyContent="flex-end">
        {/* Tenure */}
        <Grid item md={2} xs={4}>
          <p style={{ paddingTop: "16px" }}>Tenure:</p>
        </Grid>
        <Grid item md={10} xs={8}>
          <RadioGroup row value={tenure} onChange={toggleTenure}>
            <FormControlLabel value="both" control={<Radio />} label="Both" />
            <FormControlLabel value="freehold" control={<Radio />} label="Freehold" />
            <FormControlLabel value="leasehold" control={<Radio />} label="Leasehold" />
          </RadioGroup>
        </Grid>

        {/* Land */}
        <Grid item md={2} xs={4}>
          <p style={{ paddingTop: "16px" }}>Land:</p>
        </Grid>
        <Grid item md={10} xs={8}>
          <RadioGroup row value={land} onChange={toggleLand}>
            <FormControlLabel value="0" control={<Radio />} label="Both" />
            <FormControlLabel value="1" control={<Radio />} label="With Land" />
            <FormControlLabel value="2" control={<Radio />} label="Without Land" />
          </RadioGroup>
        </Grid>

        {/* Price */}
        <Grid item xs={12} md={2}>
          <p>Price:</p>
        </Grid>
        <Grid item xs={12} md={5}>
          <TextField className="rounded-input" variant="outlined" value={priceFrom} onChange={(e) => priceFromSet(numericOnly(e.target.value))} />
        </Grid>
        <Grid item xs={12} md={5}>
          <TextField className="rounded-input" variant="outlined" value={priceTo} onChange={(e) => priceToSet(numericOnly(e.target.value))} />
        </Grid>

        {/* Plot Size */}
        <Grid item xs={12} md={2}>
          <p>Plot Size (acre):</p>
        </Grid>
        <Grid item xs={12} md={5}>
          <TextField className="rounded-input" variant="outlined" value={plotSizeFrom} onChange={(e) => plotSizeFromSet(e.target.value)} />
        </Grid>
        <Grid item xs={12} md={5}>
          <TextField className="rounded-input" variant="outlined" value={plotSizeTo} onChange={(e) => plotSizeToSet(e.target.value)} />
        </Grid>

        {/* Date Added */}
        <Grid item xs={12} md={2}>
          <p>Date Added:</p>
        </Grid>
        <Grid item xs={12} md={5}>
          <FormControl className="select-field" variant="outlined">
            <InputLabel id="demo-simple-select-outlined-label"></InputLabel>
            <Select className="rounded-input" native value={dateFrom} onChange={(e) => dateFromSet(numericOnly(e.target.value as string))}>
              <option value=""> </option>
              {datesAdded.map((value, idx) => (
                <option key={idx} value={value}>
                  {value}
                </option>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} md={5}>
          <FormControl className="select-field" variant="outlined">
            <InputLabel id="demo-simple-select-outlined-label"></InputLabel>
            <Select className="rounded-input" native value={dateTo} onChange={(e) => dateToSet(numericOnly(e.target.value as string))}>
              <option value=""> </option>
              {datesAdded.map((value, idx) => (
                <option key={idx} value={value}>
                  {value}
                </option>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} style={{ textAlign: "center", marginTop: "25px" }}>
          <Button fullWidth className="rounded-button2" variant="outlined" onClick={updateQuery}>
            search
          </Button>
        </Grid>
      </Grid>
    </>
  );
}
