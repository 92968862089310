import React, { useEffect, useState } from "react";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import { Button, Container, Grid, Typography } from "@material-ui/core";
import NewLineGraph from "./StatsGraphs/NewLineGraph";
import API from "@aws-amplify/api";

import "./FrontPage.scss";
import { getInitialisedMessage } from "../../libs/session";
import { getSub } from "../../api/Api";
import { numberWithCommas, toTitleCase } from "../../libs/helpres";

interface Props {}
interface BoxStatsProps {
  label: string;
  value: string;
  href?: string;
}
type SQLSort = "asc" | "desc";
interface QueryParams {
  pageSize: number;
  page: number;
  orderBy: {
    field: string;
  };
  orderDirection: SQLSort;
  where: any[];
}

const initialStatsData = [];
const initialPropertyData = [];
const endpointName = "distressed";

const boxStyles = {
  display: "flex",
  justifyItem: "space-between",
  justifyContent: "space-between",
  border: 1,
  borderStyle: "solid",
  borderColor: "black",
  borderRadius: 15,
  padding: "8px 16px 0px",
  margin: 8,
  boxShadow: "0 0 16px rgba(0, 0, 0, 0.05)",
  height: 144,
};
const bigBoxStyles = boxStyles;
const buttonStyles = {
  margin: "8px",
};

export default function FrontPage(props: Props) {
  const [data, dataSet] = useState<any>(initialStatsData);
  const [hasLoadedData, hasLoadedDataSet] = useState(false);
  const [propertyData, propertyDataSet] = useState(initialPropertyData);
  const [hasLoadedPropertyData, hasLoadedPropertyDataSet] = useState(false);
  const [hasLoaded, hasLoadedSet] = useState(false);
  const [graphData, graphDataSet]: [any, any] = useState([]);

  useEffect(() => {
    loadGraphData(hasLoadedSet, graphDataSet, dataSet, hasLoadedDataSet);
  }, []);

  useEffect(() => {
    loadMoreData(dataFn, propertyDataSet, hasLoadedPropertyDataSet);
  }, []);

  return (
    <>
      <div className="graph">
        <h1 style={{ textAlign: "center" }}>DI/STRESSED PROPERTIES</h1>
        <NewLineGraph data={graphData} hasLoaded={hasLoaded} />
      </div>
      <Container style={{ maxWidth: 1024 }}>
        {hasLoadedData && (
          <Grid container justifyContent="center">
            {data.map((d, key) => (
              <Grid item xs={11} md={5} key={key}>
                <Box {...d} />
              </Grid>
            ))}
          </Grid>
        )}
        {data.length > 0 && propertyData.length > 0 && <hr style={{ width: "50%", marginTop: "50px", marginBottom: "25px" }} />}
        <div style={{ textAlign: "center" }}>
          <h1 style={{ marginTop: 40, marginBottom: 40 }}>LATEST PROPERTIES ADDED</h1>
        </div>
        {hasLoadedPropertyData && (
          <Grid container justifyContent="center">
            {propertyData.map((d, key) => (
              <Grid item xs={11} sm={6} md={4} lg={3} key={key}>
                <Box2 {...d} />
              </Grid>
            ))}
          </Grid>
        )}
      </Container>
    </>
  );
}

function massageData(data) {
  const sorted = data.data.sort((a, b) => a.date_added - b.date_added);
  const result = sorted.map((d) => [d["date_added"], d["count"]]);
  return result;
}
function joinSets(set1: [], set2: []): any[] {
  const labels = new Set();
  set1.forEach((data) => labels.add(data[0]));
  set2.forEach((data) => labels.add(data[0]));

  const uniqueLabels: {} = {};

  for (const item of labels.values()) {
    uniqueLabels[item as string] = [];
  }
  set1.forEach((data) => (uniqueLabels[data[0]] as []).push(data[1]));
  set2.forEach((data) => (uniqueLabels[data[0]] as []).push(data[1]));

  const result = Object.keys(uniqueLabels)
    .map((key) => [key, uniqueLabels[key][0] || 1, uniqueLabels[key][1] || 2])
    .sort((a: any[], b: any[]) => (a[0] > b[0] ? 1 : -1));

  return result;
}

async function loadGraphData(hasLoadedSet, graphDataSet, dataSet, hasLoadedDataSet) {
  // get data
  hasLoadedSet(false);
  const headers = await getInitialisedMessage();
  const sub = await getSub();
  let results = await API.get("backend", `user/${sub}/stats/distressed-by-properties`, headers);
  let resultsStressed = await API.get("backend", `user/${sub}/stats/stressed-by-properties`, headers);
  hasLoadedSet(true);

  // massage data
  const set1 = massageData(results);
  const set2 = massageData(resultsStressed);
  const data = joinSets(set1, set2);
  console.log({ set1, set2 });

  // here we collect data for the info panels on the front page
  // note: entry with 0000-00 as the date is the count of all properties in the system

  const totals = data.shift();
  const totalPropertiesValue = totals[1];
  const totalProperties = {
    label: "Total Current Distressed Properties",
    value: numberWithCommas(totalPropertiesValue),
    href: "/distressed-properties/search",
  };
  const newPropertiesThisMonthValue = data[data.length - 1][1];
  const thisMonth = new Date().toJSON().substr(0, 7);
  const newPropertiesThisMonth = {
    label: "New Distressed Properties This Month",
    value: numberWithCommas(newPropertiesThisMonthValue),
    href: `distressed-properties/search?dateFrom=${thisMonth}`,
  };

  const totalPropertiesValueStressed = totals[2];
  const totalPropertiesStressed = {
    label: "Total Current Stressed Properties",
    value: numberWithCommas(totalPropertiesValueStressed),
    href: "/stressed-properties/search",
  };
  const newPropertiesThisMonthValueStressed = data[data.length - 1][2];
  const thisMonthStressed = new Date().toJSON().substr(0, 7);
  const newPropertiesThisMonthStressed = {
    label: "New Stressed Properties This Month",
    value: numberWithCommas(newPropertiesThisMonthValueStressed),
    href: `stressed-properties/search?dateFrom=${thisMonthStressed}`,
  };

  data.length && dataSet([totalProperties, newPropertiesThisMonth, totalPropertiesStressed, newPropertiesThisMonthStressed]);

  const wrappedData = [[{ type: "string", label: "Date Added" }, "Number of distressed properties", "Number of stressed properties"], ...data];
  graphDataSet(wrappedData);
  hasLoadedDataSet(true);
}

async function dataFn(query: QueryParams) {
  // basic query bits
  const pageSize = query.pageSize;
  const page = query.page;
  let orderBy = {};

  // order by
  if (query.orderBy) {
    orderBy["field"] = query.orderBy.field;
    orderBy["asc"] = query.orderDirection === "asc";
  }

  const where = query.where;
  try {
    const results = await loadData(page * pageSize, pageSize, orderBy, where);
    results.data.data = results.data.data.map((p) =>
      Object.assign(p, {
        total_floor_area: numberWithCommas(p.total_floor_area),
        price_paid: numberWithCommas(p.price_paid),
      })
    );
    const data = results.data;
    return data;
  } catch (e: any) {
    console.log(e);
    return {
      data: [],
      page: 0,
      totalCount: 0,
    };
  }
}

async function loadMoreData(dataFn, propertyDataSet, hasLoadedPropertyDataSet) {
  const DEFAULT_PAGE_SIZE = 10;
  const query: QueryParams = {
    pageSize: DEFAULT_PAGE_SIZE,
    page: 0,
    orderBy: {
      field: "date_added",
    },
    orderDirection: "desc",
    where: [],
  };
  const data = await dataFn(query);
  const requiredData = data.data.map((p) => ({ label: p.postcode, value: toTitleCase(p.district + ", " + p.county) }));
  propertyDataSet(requiredData);
  hasLoadedPropertyDataSet(true);
}

function Box(props: BoxStatsProps) {
  return (
    <FormControl component="fieldset" style={bigBoxStyles}>
      <FormLabel component="legend">{props.label}</FormLabel>
      <Typography variant="h4" align="center">
        {props.value}
      </Typography>
      <Button className="box-button" variant="contained" style={buttonStyles} href={props.href}>
        Click to View
      </Button>
    </FormControl>
  );
}

function Box2(props: BoxStatsProps) {
  return (
    <FormControl component="fieldset" style={boxStyles}>
      <Typography variant="h6" align="left">
        <b>{props.label}</b>
      </Typography>
      <Typography
        variant="body1"
        align="left"
        style={{
          flexGrow: 1,
          overflow: "hidden",
        }}
      >
        {props.value}
      </Typography>
      <Button className="box-button" variant="contained" style={buttonStyles} href={`distressed-properties/search?postcode=${props.label}`}>
        Click to View
      </Button>
    </FormControl>
  );
}

async function loadData(offset: Number, count: Number, orderBy, where) {
  const sub = await getSub();
  const headers = await getInitialisedMessage();
  const init = Object.assign(headers, {
    queryStringParameters: {
      count,
      offset,
    },
  });
  if (orderBy.field) {
    init.queryStringParameters["orderBy"] = `${orderBy.field},${orderBy.asc}`;
  }
  if (where.length) {
    init.queryStringParameters["filters"] = JSON.stringify(where);
  }
  return API.get("ccod", `user/${sub}/${endpointName}`, init);
}
