import { useContext, createContext } from "react";

export const AppContext = createContext({
  isAuthenticated: false,
  setAuthenticated: (s: boolean) => {},
});

export function useAppContext() {
  return useContext(AppContext);
}
