import React, { useEffect, useState } from "react";
import { withRouter, useLocation } from "react-router-dom";
import { PropertyDetails } from "../PropertyDetails/PropertyDetails";
import { PropertyDetails as PropertyDetailsPrintView } from "../PropertyDetails/PropertyDetailsPrintView";
import { getPropertyByTitleNumber, getPropertyByUPRN, getSub, processPropertyData } from "../../api/Api";
import { API } from "aws-amplify";
import { getInitialisedMessage } from "../../libs/session";

function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

export default withRouter(PropertyByTitleContainer);

function PropertyByTitleContainer(props) {
  const [property, setProperty] = useState({ message: "Loading" });
  const [dossier, setDossier] = useState<any>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [extraData, extraDataSet] = useState({});
  const [isLoadingExtraData, isLoadingExtraDataSet] = useState(false);
  const [displayPrintView, displayPrintViewSet] = useState(window.location.search.indexOf("printview=true")>=0);
  const [isUpdatingBooking] = useState(false);
  const [isViewingBooked, isViewingBookedSet] = useState(true);

  const query = useQuery();
  const title_number = query.get("title_number");
  const postcode = query.get("postcode");
  const uprn = query.get("uprn");

  useEffect(() => {
    (async () => {
      setIsLoading(true);

      // di/stressed - none domestic
      const nonDomesticData = await getPropertyByTitleNumber(title_number);

      let data = nonDomesticData["data"];

      if (!data) {
        data = {
          title_number,
        };
        if (uprn) {
          let uprnData = await getPropertyByUPRN(uprn);
          if (uprnData) {
            const latlng = uprnData["lat"] ? [uprnData["lat"], uprnData["lng"]] : null;
            const moreData = {
              property_address: uprnData["address"],
              latlng,
              // property_type,
            };
            data = Object.assign({}, data, moreData);
          }
        }
      }
      if (data) {
        setProperty(data);
      } else {
        setProperty({ message: `Could not find property with title number ${title_number}` });
      }
    })();
  }, [setProperty, props, setDossier, postcode, title_number, uprn]);

  useEffect(()=>{
    (async ()=>{
      // ccod+ companies house - none domestic
      const config = await getInitialisedMessage();
      const sub = await getSub();
      const result = await API.get("internal", `internal/${sub}/company-id-by-title-number/${title_number}`, config);
      setDossier(result.data);
      setIsLoading(false);
    })();
  }, [title_number]);

  useEffect(()=>{
    (async ()=>{
      if (postcode) {
        isLoadingExtraDataSet(true);
        extraDataSet(await processPropertyData(postcode));
        isLoadingExtraDataSet(false);
      }
    })();
  },[postcode]);

  const bookViewing = (uprn: string) => [isViewingBookedSet(!isViewingBooked), alert(`bookViewing(${uprn})`)];
  const openBookedViewing = (uprn: string) => [isViewingBookedSet(!isViewingBooked), alert(`openBookedViewing(${uprn})`)];

  return !displayPrintView ? (
    <PropertyDetails
      isLoading={isLoading}
      isLoadingExtraData={isLoadingExtraData}
      property={property}
      dossier={dossier}
      extraData={extraData}
      togglePrintView={()=>[displayPrintViewSet(!displayPrintView)]}
      bookViewing={bookViewing}
      isUpdatingBooking={isUpdatingBooking}
      isViewingBooked={isViewingBooked}
      openBookedViewing={openBookedViewing}
    />
  ) : (
    <PropertyDetailsPrintView
      isLoading={isLoading}
      isLoadingExtraData={isLoadingExtraData}
      property={property}
      dossier={dossier}
      extraData={extraData}
      togglePrintView={() => [displayPrintViewSet(!displayPrintView)]}
    />
  );
}
