import React, { useEffect, useState } from "react";
import GoogleMapReact from "google-map-react";
import "./GoogleMap.scss";
import { CircularProgress } from "@material-ui/core";

const renderMarkers = (map, maps, latLng, infowindowContentString?) => {
  let marker = new maps.Marker({
    position: { lat: latLng.lat, lng: latLng.lng },
    map,
  });

  if (infowindowContentString) {
    maps.event.addListener(marker, "click", function () {
      var infowindow = new maps.InfoWindow({
        content: infowindowContentString,
      });
      infowindow.open(map, marker);
    });
  }

  return marker;
};

const arrayOfArraysToArrayOfLatLng = (polygonPoints) => polygonPoints.map((a) => ({ lat: parseFloat(a[0]), lng: parseFloat(a[1]) }));
const renderPolygon = (map, maps, polygonPoints, colour="#FF0000") => {
  if (!polygonPoints) return undefined;
  var polygon = new maps.Polygon({
    paths: arrayOfArraysToArrayOfLatLng(polygonPoints),
    strokeColor: colour,
    strokeOpacity: 0.8,
    strokeWeight: 2,
    fillColor: colour,
    fillOpacity: 0.35,
  });
  polygon.setMap(map);
};

interface Props {
  address?: string;
  lat?: number;
  lng?: number;
  infoWindowContentString?: string;
  hideMapTypeToggle?: boolean;
  mapTypeId?: "roadmap" | "satellite" | undefined;
  polygonPoints?: any[];
  zoom?: number;
  pathOptions?: { 
    color: string 
  }
  titleNumber?: any;
  mappingData?: any;
}

const defaultMapTypeId = localStorage.getItem("defaultMapTypeId") ? localStorage.getItem("defaultMapTypeId") : "roadmap";
const defaultMapOptions = {
  styles: [
    { stylers: [{ visibility: "n" }, { saturation: "-100" }, { gamma: "0.54" }] },
    { featureType: "road", elementType: "labels.icon", stylers: [{ visibility: "off" }] },
    { featureType: "water", stylers: [{ color: "#4d4946" }] },
    { featureType: "poi", elementType: "labels.icon", stylers: [{ visibility: "off" }] },
    { featureType: "road", elementType: "geometry.fill", stylers: [{ color: "#ffffff" }] },
    { featureType: "water", elementType: "labels.text.fill", stylers: [{ color: "#ffffff" }] },
    { featureType: "transit.line", elementType: "geometry", stylers: [{ gamma: "0.48" }] },
    { featureType: "transit.station", elementType: "labels.icon", stylers: [{ gamma: "7.18" }] },
  ],
  streetViewControl: true,
  mapTypeControl: true,
  mapTypeId: defaultMapTypeId,
};

const zoomLevel = 17;
const key = "AIzaSyBP-5s3vZJtfCNIKYtFXWOXbKw9CcyCvkE";

export function GoogleMap(props: Props) {
  const [latLng, latLngSet] = useState<null | { lat: number; lng: number }>(null);
  const [loading, loadingSet] = useState(false);
  const [mapOptions, mapOptionsSet] = useState(Object.assign({}, defaultMapOptions, props.mapTypeId ? { mapTypeId: props.mapTypeId } : {}));

  function toggleMapType() {
    const newMapOptions = Object.assign({}, mapOptions, { mapTypeId: mapOptions.mapTypeId === "satellite" ? "roadmap" : "satellite" });
    mapOptionsSet(newMapOptions);
    localStorage.setItem("defaultMapTypeId", newMapOptions.mapTypeId);
  }

  useEffect(() => {
    if (props.lat && props.lng) {
      const latlng = { lat: props.lat, lng: props.lng };
      latLngSet(latlng);
      return;
    }
    const address = escape(props.address || "");
    const apiUrl = `https://maps.googleapis.com/maps/api/geocode/json?key=${key}&address=${address}`;
    loadingSet(true);
    getData();
    loadingSet(false);
    async function getData() {
      const result = await fetch(apiUrl).then((a) => a.json());
      if (result.results.length) {
        const location = result.results[0].geometry.location;
        latLngSet(location);
      }
    }
  }, [props]);

  return (
    <>
      {loading && (
        <div>
          Locating map data... <CircularProgress size="1rem" />{" "}
        </div>
      )}
      <div className="map">
        <div className="google-map">
          {latLng && (
            <GoogleMapReact
              bootstrapURLKeys={{ key }}
              defaultCenter={latLng}
              defaultZoom={props.zoom || zoomLevel}
              yesIWantToUseGoogleMapApiInternals
              onGoogleApiLoaded={({ map, maps }) => [
                renderMarkers(map, maps, latLng, props.infoWindowContentString),
                renderPolygon(map, maps, props.polygonPoints, props?.pathOptions?.color),
              ]}
              options={mapOptions}
            ></GoogleMapReact>
          )}
          {!props.hideMapTypeToggle &&
            (mapOptions.mapTypeId === "satellite" ? (
              <button onClick={toggleMapType}>Roadmap View</button>
            ) : (
              <button onClick={toggleMapType}>Satelite View</button>
            ))}
        </div>
      </div>
    </>
  );
}
