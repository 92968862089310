import mixpanel from "mixpanel-browser";

export default function loadMixPanel() {
  // https://mixpanel.com/report/2369629/view/2914405/setup/#code
  console.info("[loadMixPanel] loading...");
  try {
    mixpanel.init("42753e0134acccd6658525658c13bbef", { api_host: "https://api-eu.mixpanel.com" }, "");
    console.info("[loadMixPanel] loaded");
  } catch (e: any) {
    console.error(e);
    console.log("[loadMixPanel] failed to load");
    return;
  }
}
