import React, { useEffect, useState } from "react";
import API from "@aws-amplify/api";
import { Grid, IconButton } from "@material-ui/core";
import { getInitialisedMessage } from "../../libs/session";
import CachedIcon from "@material-ui/icons/Cached";
import GenericLineGraph from "../Containers/StatsGraphs/GenericLineGraph";
import useStyles from "./useLoadingSpinnerStyles";

interface Props {
  companyNumber?: string;
}

interface Rating {
  rating: string;
  date_rated: string;
  date_loaded: string;
}

interface CreditRatings {
  ratings: Rating[];
}

interface CurrentCreditData {
  company_reg?: string;
  current_rating?: number;
  current_rating_date?: string;
}
interface CreditHistoryData extends CreditRatings, CurrentCreditData {}

type APIReturn<T> = [string | null, T | null];

async function getData(companyNumber: string): Promise<APIReturn<CreditHistoryData>> {
  try {
    const config = await getInitialisedMessage();
    const url = `internal/company/${companyNumber}/credit-file`;
    const { data } = await API.get("internal", url, config);
    return [null, data || {}];
  } catch (e: any) {
    return [e["message"] as string, null];
  }
}

async function runData(companyNumber, classes, dataSet, loadingClassSet, bumpKey) {
  if (companyNumber) {
    loadingClassSet(classes.rotateIcon);
    const [err, data] = await getData(companyNumber as string);
    loadingClassSet({});

    if (!err) {
      bumpKey();
      dataSet(data);
      return;
    }

    console.log("[CreditHistoryPanel] getData error: ", err);
  } else {
    console.log({ CreditHistoryPanel: "No companyNumber provided" });
  }
}

function formatRatingsData(data: Rating[]): [] {
  const result: any[] = [["date", "rating"]];

  data?.forEach((rating: Rating) => {
    let rate: number | null = parseInt(rating.rating);
    if (isNaN(rate)) rate = null;

    result.push([rating.date_loaded, rate]);
  });

  return result as [];
}

const unpackRatingsData = (ratingsDataSet, currentRatingDataSet) => (data: CreditHistoryData) => {
  const { current_rating, current_rating_date, ratings = [] } = data;
  ratingsDataSet(formatRatingsData(ratings));
  currentRatingDataSet({ current_rating, current_rating_date });
};

export function CreditHistoryPanel(props: Props) {
  const [ratingsData, ratingsDataSet] = useState<any[]>([["date", "rating"]]);
  const [currentRatingData, currentRatingDataSet] = useState<CurrentCreditData>({});
  const [loadingClass, loadingClassSet] = useState<any>({});
  const [chartKey, chartKeySet] = useState<number>(0);
  const classes = useStyles();

  const companyNumber = props.companyNumber;
  const dataToShow = ratingsData ?? [];
  const bumpKey = () => chartKeySet(chartKey + 1);
  const updateData = () => runData(companyNumber, classes, unpackRatingsData(ratingsDataSet, currentRatingDataSet), loadingClassSet, bumpKey);

  useEffect(() => {
    runData(companyNumber, classes, unpackRatingsData(ratingsDataSet, currentRatingDataSet), loadingClassSet, bumpKey);
    // eslint-disable-next-line
  }, [companyNumber, classes]);

  return (
    <Grid item className="summary" xs={12} md={6}>
      <Grid container justifyContent="space-between">
        <Grid item>
          <h3>company credit history</h3>
        </Grid>
        <Grid item>
          <IconButton title="Refresh" className={loadingClass} onClick={() => updateData()}>
            <CachedIcon />
          </IconButton>
        </Grid>
      </Grid>

      <hr className="short" />

      {dataToShow?.length < 1 && (
        <p>
          <i>No Data available</i>
        </p>
      )}
      <table>
        <tbody>
          <tr>
            <td>
              <GenericLineGraph data={ratingsData as []} hasLoaded={true} keyValue={chartKey} />
              {currentRatingData?.current_rating && (
                <>
                  <div style={{ textAlign: "center", width: 100, margin: "0px auto" }}>
                    <span style={{ fontSize: "small", textTransform: "capitalize" }}>Current rating</span>
                    <br />
                    <span style={{ fontSize: "xxx-large" }}>{currentRatingData?.current_rating}</span>
                    <br />
                    <span style={{ fontSize: "small" }}>{currentRatingData?.current_rating_date}</span>
                  </div>
                </>
              )}
            </td>
          </tr>
        </tbody>
      </table>
    </Grid>
  );
}
