import { DEFAULT_LANES } from "./types";

// Add cards to correct lanes
export function propertiesToLanes(properties, assignedToList = {}, propertyColours) {
  // TODO: this is inefficient
  // eslint-disable-next-line
  const newCardIdx = {};
  const lanes = duplicate(DEFAULT_LANES);
  const laneIdx = {
    "0": lanes[0],
    "1": lanes[1],
    "2": lanes[2],
    "3": lanes[3],
    "4": lanes[4],
    "5": lanes[5],
    "6": lanes[6],
    "7": lanes[7],
    "8": lanes[8],
    "9": lanes[9],
    "10": lanes[10],
    "11": lanes[11],
  };

  properties?.forEach?.((p) => {
    const { title_number, county, positionId, laneId, note, property_address, assigned_to = "", title_type } = p;
    const card = {
      id: title_number,
      title: `${property_address || `<no address>`}`,
      description: `${county}`,
      label: `${title_number}`,
      draggable: true,
      metadata: p,
      positionId,
      note,
      assignedTo: assigned_to,
      assignedToList,
      style: {
        backgroundColor: propertyColours[title_type],
      },
    };
    const lane = "" + (laneId || 0);
    laneIdx[lane].cards.push(card);
    newCardIdx[card.id] = card;
  });
  // sort cards in lane
  lanes.forEach((lane) => {
    const sortedCards = lane.cards.sort((a: any, b: any) => a.positionId - b.positionId);
    lane.cards = sortedCards;
  });
  // sort first lane in date_added then id, to be from the most recently added
  const sortedFirstLane = lanes[0].cards.sort((a, b) => (`${a.metadata.date_added}${a.metadata.id}` > `${b.metadata.date_added}${b.metadata.id}` ? -1 : 1));
  lanes[0].cards = sortedFirstLane;
  return [lanes, newCardIdx];
}


export const duplicate = (string) => JSON.parse(JSON.stringify(string));
export const updateFromEventValue = (setFn) => (event) => setFn(event.target.value);
