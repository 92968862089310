import { CircularProgress, Grid, IconButton, MenuItem, Select, Tooltip, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import "./Notifications.scss";
import AddIcon from "@material-ui/icons/Add";
import InfoIcon from "@material-ui/icons/Info";
import postcode from "./postcodes.json";
import { notificationsGet, notificationsPatch, profileGet } from "../../api/Api";
import { Interface_Profile, SubscriptionType } from "../Profile/sharedTypes";

const districts = Object.keys(postcode).sort((a, b) => (a < b ? 1 : 0));

const districtMenuItems = districts.map((d, idx) => (
  <MenuItem key={idx} value={d.toUpperCase()}>
    {d.toUpperCase()}
  </MenuItem>
));

const postcodeMenuItems = {};
districts.forEach((d) => {
  const pcs = postcode[d].map((p, idx) => (
    <MenuItem key={idx} value={p.toUpperCase()}>
      {p}
    </MenuItem>
  ));
  postcodeMenuItems[d] = pcs;
});

interface DistrictPostcode {
  district: String;
  postcode: String;
}
interface NotificationsData {
  notifications_desktop: number;
  notifications_email: number;
  settings: DistrictPostcode[];
}

interface Props {
  notificationDistrict?: string;
  notificationPostcode?: string;
  subscriptionType: SubscriptionType;
  isSubscriptionActive: boolean;
  onClose: () => void;
}

export default function NotificationsForm(props: Props) {
  const [notificationsDesktop, notificationsDesktopSet] = useState(false);
  const [notificationsEmail, notificationsEmailSet] = useState(false);
  const [existingSettings, existingSettingsSet]: [DistrictPostcode[], Function] = useState([]);
  const [settings, settingsSet]: [DistrictPostcode[], Function] = useState([]);
  const [newDistrict, newDistrictSet] = useState(props.notificationDistrict || "");
  const [newPostcode, newPostcodeSet] = useState(props.notificationPostcode || "");
  const [isSaving, isSavingSet] = useState(false);
  const [isSubscriptionPro, isSubscriptionProSet] = useState(false);

  useEffect(() => {
    fetchCurrentSettings();
    fetchSubscriptionType();
  }, []);

  return (
    <>
      {props.isSubscriptionActive && (
        <>
          <Grid container>
            <Grid item xs={12}>
              <table style={{ color: "white", width: "100%" }}>
                <thead>
                  <tr>
                    <th>
                      <h3>District</h3>
                    </th>
                    <th>
                      <h3>
                        Postcode{" "}
                        <Tooltip style={{ position: "absolute" }} title="Only available with Pro subscription" aria-label="add">
                          <InfoIcon fontSize="small" />
                        </Tooltip>
                      </h3>
                    </th>
                  </tr>
                </thead>
                <tfoot>
                  <tr>
                    <td>
                      <Select
                        disabled={isSaving}
                        fullWidth
                        inputProps={{ style: { color: "white", wordBreak: "break-all" } }}
                        style={{ color: "white", border: "1px white solid", borderRadius: 2, minWidth: 151 }}
                        variant="outlined"
                        value={newDistrict}
                        onChange={(a) => newDistrictSet(a.target.value as string)}
                      >
                        <MenuItem key={0} value={""}>
                          <em>Select</em>
                        </MenuItem>
                        {districtMenuItems}
                      </Select>
                    </td>
                    <td style={{ textAlign: "center" }}>
                      {!isSubscriptionPro ? (
                        <span style={{ color: "grey" }}>Upgrade to Pro</span>
                      ) : (
                        <Select
                          disabled={isSaving}
                          fullWidth
                          inputProps={{ style: { color: "white" } }}
                          style={{ color: "white", border: "1px white solid", borderRadius: 2, minWidth: 89 }}
                          variant="outlined"
                          value={newPostcode}
                          onChange={(a) => newPostcodeSet(a.target.value as string)}
                        >
                          <MenuItem key={0} value={""}>
                            <em>Select District First</em>
                          </MenuItem>
                          {postcodeMenuItems[newDistrict]}
                        </Select>
                      )}
                    </td>
                    <td>
                      {isSaving && <CircularProgress />}
                      {!isSaving && (
                        <IconButton aria-label="add" onClick={addSelection} color={newDistrict ? "secondary" : "primary"} disabled={!newDistrict}>
                          <AddIcon />
                        </IconButton>
                      )}
                    </td>
                  </tr>
                </tfoot>
              </table>
            </Grid>
          </Grid>
        </>
      )}
      {!props.isSubscriptionActive && <Typography>No active subscription, no notification will be sent.</Typography>}
    </>
  );

  function fetchCurrentSettings() {
    (async () => {
      try {
        const data: NotificationsData = (await notificationsGet()) as NotificationsData;
        const { notifications_desktop, notifications_email } = data;
        notificationsDesktopSet(notifications_desktop === 1 || false);
        notificationsEmailSet(notifications_email === 1 || false);
        existingSettingsSet(data.settings || []);
      } catch (e: any) {}
    })();
  }

  async function fetchSubscriptionType() {
    const profile: Interface_Profile | null = await profileGet();
    if (profile && profile.subscriptionType !== "NONE") {
      isSubscriptionProSet(profile.subscriptionType === "PRO");
    }
  }

  async function submitNotificationUpdate(notificationSettings) {
    try {
      await notificationsPatch(notificationSettings);
    } catch (e: any) {}
  }

  async function addSelection() {
    const newSettings = deduplicateArray([...existingSettings, ...settings, { district: newDistrict, postcode: newPostcode }]);
    settingsSet(newSettings);
    newPostcodeSet("");
    newDistrictSet("");
    const notificationSettings = {
      notifications_desktop: notificationsDesktop,
      notifications_email: notificationsEmail,
      settings: newSettings,
    };
    isSavingSet(true);
    await submitNotificationUpdate(notificationSettings);
    isSavingSet(false);
    props.onClose();
  }

  function deduplicateArray(array) {
    const setOfUninqueValues = new Set();
    array.forEach((el) => setOfUninqueValues.add(JSON.stringify(el)));
    const arrayofUniqueValues = Array.from(setOfUninqueValues).map((value) => JSON.parse(value as string));
    return arrayofUniqueValues;
  }
}
