import React, { useState } from "react";
import { Button, CircularProgress, TextField } from "@material-ui/core";

interface SearchBarAllFieldsProps {
  getData: (searchText: string, searchBy?: string) => void;
  isBusy: boolean;
}

export default function SearchBarAllFields(props: SearchBarAllFieldsProps) {
  const [searchText, searchTextSet] = useState<string>("");

  const { getData, isBusy = false } = props;
  const executeSearch = (event) => [event.preventDefault(), getData(searchText)];
  
  return (
    <form onSubmit={executeSearch}>
      <table className="MuiPaper-rounded query-form">
        <tbody>
          <tr>
            <td> </td>
            <td>
              <TextField
                fullWidth
                style={{ color: "black", border: "1px white solid", borderRadius: 2 }}
                InputProps={{ style: { color: "black" } }}
                variant="outlined"
                value={searchText}
                onChange={(e) => searchTextSet(e.target.value)}
                disabled={isBusy}
              />
            </td>
            <td style={{ textAlign: "center" }}>
              <Button variant="contained" style={{ borderColor: "grey", minWidth: 90 }} onClick={() => getData(searchText)}>
                {!isBusy && "Search"}
                {isBusy && <CircularProgress color="primary" size="1.5rem" />}
              </Button>
            </td>
          </tr>
        </tbody>
      </table>
    </form>
  );
}
