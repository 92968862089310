import API from "@aws-amplify/api";
import { getInitialisedMessage } from "../../libs/session";

export type PropertyData = {
  uprn: string;
  address: string;
  usrn: string;
  lpi_key: string;
  organisation: string;
  sao_text: string;
  sao_start_number: string;
  sao_start_suffix: string;
  sao_end_number: string;
  sao_end_suffix: string;
  pao_text: string;
  pao_start_number: string;
  pao_start_suffix: string;
  pao_end_number: string;
  pao_end_suffix: string;
  street_description: string;
  locality: string;
  town_name: string;
  administrative_area: string;
  postcode_locator: string;
  rpc: string;
  x_coordinate: string;
  y_coordinate: string;
  lng: string;
  lat: string;
  status: string;
  logical_status_code: string;
  classification_code: string;
  classification_code_description: string;
  local_custodian_code: string;
  local_custodian_code_description: string;
  country_code: string;
  postal_address_code: string;
  postal_address_code_description: string;
  blpu_state_code_description: string;
  topography_layer_toid: string;
  parent_uprn: string;
  last_update_date: string;
  entry_date: string;
  match: string;
  match_description: string;
};

export async function getData(postcodeUprn: string, dataSet: any, isBusySet: any, searchBy?: string) {
  const config = await getInitialisedMessage();
  dataSet([]);
  const byPostcodeOrUPRN = searchBy === "postcode" ? "postcode" : "uprn";
  const urlPath = `internal/osplacesapi/${byPostcodeOrUPRN}/${postcodeUprn}`;

  isBusySet && isBusySet(true);

  try {
    const { status, message, data } = await API.get("internal", urlPath, config);
    if (status === "error") throw new Error(`Error: ${message}`);
    if (data?.fault) throw new Error(`Fault calling "${urlPath}"":\n${data.fault?.faultstring}`);
    dataSet(data);
  } catch (e: any) {
    console.log(e);
    alert(`An error occurred in getData for PropertySearchForm.\n\n${e?.message}`);
  }

  isBusySet && isBusySet(false);
}

export async function getTitledData(dataSet: any, isBusySet: any) {
  const config = await getInitialisedMessage();
  dataSet([]);
  const urlPath = `internal/previous-property-searches`;

  isBusySet && isBusySet(true);

  try {
    const { status, message, data } = await API.get("internal", urlPath, config);
    if (status === "error") {
      alert(`Error: ${message}`);
      return;
    }
    dataSet(data);
  } catch (e) {
    console.log(e);
    alert("An error occurred in getData for PropertySearchForm.  See console for details.");
  }

  isBusySet && isBusySet(false);
}

export async function getTitleNumber(uprn: string): Promise<[string|null, any?]> {
  const url = `internal/uprn-to-title-number/${uprn}`;
  const config = await getInitialisedMessage();
  let result;

  try {
    const response = await API.get("internal", url, config);
    const { status, message, data } = response.data;
    if (status === "error") {
      if (message === "Uprn not found"){
        return ['NO_UPRN'];
      }
      return [`Error: ${message}`];
    }
    result = data;
  } catch (e) {
    console.log(e);
    alert("An error occurred in getData for PropertySearchForm.  See console for details.");
  }

  return [null, result];
}
