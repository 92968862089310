import { Button, Checkbox, FormControl, FormControlLabel, Grid, Radio, RadioGroup, Select, TextField } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import CountyData from "./counties.json";
import DistrictData from "./districts.json";
import TenureData from "./tenure.json";
import "./QueryForm.scss";
import { getDatesPropertiesAdded } from "../../api/Api";
import { updateField } from "../../libs/updateField";

interface Props {
  onChange: Function;
  queryValue?: string;
  show?: boolean;
  toggleFilter?: Function;
  filterState?: boolean;
}

export default function QueryForm(props: Props) {
  const [postcode, postcodeSet] = useState("");
  const [district, districtSet] = useState("");
  const [tenure, tenureSet] = useState("");
  const [county, countySet] = useState("");
  const [priceFrom, priceFromSet] = useState("");
  const [priceTo, priceToSet] = useState("");
  const [plotSizeFrom, plotSizeFromSet] = useState("");
  const [plotSizeTo, plotSizeToSet] = useState("");
  const [dateTo, dateToSet] = useState("");
  const [dateFrom, dateFromSet] = useState("");
  const [showMore, showMoreSet] = useState(false);
  const [land, landSet] = useState("0");
  // const [propertyType, propertyTypeSet] = useState("");
  const [datesAdded, datesAddedSet] = useState<string[]>([]);

  useEffect(() => {
    (async () => {
      const result = await getDatesPropertiesAdded();
      datesAddedSet(result);
    })();
  }, []);

  const toggleLand = (e) => {
    landSet(e.target.value);
  };

  return (
    <table className="MuiPaper-rounded query-form">
      <thead>
        <tr>
          <td colSpan={2}>
            <h2 style={{ textAlign: "center" }}>PROPERTY SEARCH</h2>
            {/* <Checkbox checked={props.filterState} onClick={() => props.toggleFilter && props.toggleFilter()} /> Use Advanced Filter */}
          </td>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>Postcode</td>
          <td>
            <TextField
              fullWidth
              style={{ color: "black", border: "1px white solid", borderRadius: 2 }}
              InputProps={{ style: { color: "black" } }}
              variant="outlined"
              value={postcode}
              onChange={(e) => postcodeSet(e.target.value)}
            />
          </td>
        </tr>
        <tr>
          <td>Tenure</td>
          <td>
            <Select
              fullWidth
              inputProps={{ style: { color: "black" } }}
              style={{ color: "white", border: "1px white solid", borderRadius: 2 }}
              variant="outlined"
              value={tenure}
              onChange={(a) => tenureSet(a.target.value as string)}
              native
            >
              <option value={""}>Select</option>
              {TenureData.map((a) => (
                <option key={a} value={a}>
                  {a}
                </option>
              ))}
            </Select>
          </td>
        </tr>
        <tr>
          <td>District</td>
          <td>
            <Select
              fullWidth
              inputProps={{ style: { color: "black" } }}
              style={{ color: "black", border: "1px white solid", borderRadius: 2 }}
              variant="outlined"
              value={district}
              onChange={(a) => districtSet(a.target.value as string)}
              native
            >
              <option value={""}>Select</option>
              {DistrictData.map((a) => (
                <option key={a} value={a}>
                  {a}
                </option>
              ))}
            </Select>
          </td>
        </tr>
        <tr>
          <td>County</td>
          <td>
            <Select
              fullWidth
              inputProps={{ style: { color: "black" } }}
              style={{ color: "black", border: "1px white solid", borderRadius: 2 }}
              variant="outlined"
              value={county}
              onChange={(a) => countySet(a.target.value as string)}
              native
            >
              <option value={""}>Select</option>
              {CountyData.map((a) => (
                <option key={a} value={a}>
                  {a}
                </option>
              ))}
            </Select>
          </td>
        </tr>
        {showMore && (
          <>
            <tr>
              <td>Land</td>
              <td>
                <RadioGroup row value={land} onChange={toggleLand}>
                  <FormControlLabel value="0" control={<Radio />} label="Both" />
                  <FormControlLabel value="1" control={<Radio />} label="With Land" />
                  <FormControlLabel value="2" control={<Radio />} label="Without Land" />
                </RadioGroup>
              </td>
            </tr>
            <tr>
              <td>Price</td>
              <td>
                <Grid container justifyContent="space-between">
                  <Grid item xs={5}>
                    <TextField
                      style={{ color: "black", border: "1px white solid", borderRadius: 2 }}
                      InputProps={{ style: { color: "black" } }}
                      variant="outlined"
                      value={priceFrom}
                      onChange={(e) => priceFromSet(numericOnly(e.target.value))}
                    />
                  </Grid>
                  <Grid item xs={5}>
                    <TextField
                      style={{ color: "black", border: "1px white solid", borderRadius: 2 }}
                      InputProps={{ style: { color: "black" } }}
                      variant="outlined"
                      value={priceTo}
                      onChange={(e) => priceToSet(numericOnly(e.target.value))}
                    />
                  </Grid>
                </Grid>
              </td>
            </tr>
            <tr>
              <td>Plot Size (acre)</td>
              <td>
                <Grid container justifyContent="space-between">
                  <Grid item xs={5}>
                    <TextField
                      style={{ color: "black", border: "1px white solid", borderRadius: 2 }}
                      InputProps={{ style: { color: "black" } }}
                      variant="outlined"
                      value={plotSizeFrom}
                      onChange={(e) => plotSizeFromSet(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={5}>
                    <TextField
                      style={{ color: "black", border: "1px white solid", borderRadius: 2 }}
                      InputProps={{ style: { color: "black" } }}
                      variant="outlined"
                      value={plotSizeTo}
                      onChange={(e) => plotSizeToSet(e.target.value)}
                    />
                  </Grid>
                </Grid>
              </td>
            </tr>
            <tr>
              <td>Date Added</td>
              <td>
                <Grid container justifyContent="space-between">
                  <Grid item lg={5}>
                    <FormControl className="select-field" variant="outlined">
                      <Select native value={dateFrom || ""} onChange={updateField(dateFromSet)}>
                        <option value={""}> </option>
                        {datesAdded.map((value, idx) => (
                          <option key={idx} value={value}>
                            {value}
                          </option>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item lg={5}>
                    <FormControl className="select-field" variant="outlined">
                      <Select native value={dateTo || ""} onChange={updateField(dateToSet)}>
                        <option value={""}> </option>
                        {datesAdded.map((value, idx) => (
                          <option key={idx} value={value}>
                            {value}
                          </option>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              </td>
            </tr>
          </>
        )}
      </tbody>
      <tfoot>
        <tr>
          <td colSpan={2}>
            <div style={{ textAlign: "right", display: "flex", justifyContent: "space-between" }}>
              <div style={{ display: "inline-block" }}>
                <Checkbox checked={showMore} onClick={() => toggleShowMore()} />
                Show more
              </div>
              <div style={{ display: "inline-block" }}>
                <Button variant="outlined" style={{ border: "1px solid white", color: "black", marginRight: "1px" }} onClick={clearForm}>
                  Clear
                </Button>
                <Button variant="outlined" style={{ border: "1px solid white", color: "black", marginLeft: "1px" }} onClick={updateQuery}>
                  Submit
                </Button>
              </div>
            </div>
          </td>
        </tr>
      </tfoot>
    </table>
  );

  function clearForm() {
    [postcodeSet, districtSet, tenureSet, countySet, priceFromSet, priceToSet, plotSizeFromSet, plotSizeToSet, dateFromSet, dateToSet, landSet].map((setFn) =>
      setFn("")
    );
  }

  function toggleShowMore() {
    showMoreSet(!showMore);
    if (!showMore) {
      [priceFromSet, priceToSet, plotSizeFromSet, plotSizeToSet, dateFromSet, dateToSet].map((setFn) => setFn(""));
    }
  }

  function numericOnly(value: string) {
    if (!value) return value;
    const lastChar = value.length - 1;
    const char = value.charAt(lastChar);
    if (char >= "0" && char <= "9") {
      return value;
    }
    return value.substr(0, lastChar);
  }

  function updateQuery() {
    const query: any = [];

    if (postcode) {
      query.push({ field: `postcode`, op: "=s", value: postcode });
      props.onChange(query);
    }
    if (tenure) {
      query.push({ field: `tenure`, op: "=s", value: tenure.toLowerCase() });
    }
    if (district) {
      query.push({ field: `district`, op: "=s", value: district.toLowerCase() });
    }
    if (county) {
      query.push({ field: `county`, op: "=s", value: county.toLowerCase() });
    }

    if (priceFrom && priceTo) {
      query.push({ field: `price_paid`, op: "between", value1: priceFrom, value2: priceTo });
    } else if (priceFrom) {
      query.push({ field: `price_paid`, op: ">=", value: priceFrom });
    } else if (priceTo) {
      query.push({ field: `price_paid`, op: "<=", value: priceTo });
    }

    if (plotSizeFrom && plotSizeTo) {
      query.push({ field: `plot_size`, op: "between", value1: plotSizeFrom, value2: plotSizeTo });
    } else if (plotSizeFrom) {
      query.push({ field: `plot_size`, op: ">=", value: plotSizeFrom });
    } else if (plotSizeTo) {
      query.push({ field: `plot_size`, op: "<=", value: plotSizeTo });
    }

    if (dateFrom && dateTo) {
      query.push({ field: `date_added`, op: "between", value1: dateFrom, value2: dateTo });
    } else if (dateFrom) {
      query.push({ field: `date_added`, op: ">=d", value: dateFrom });
    } else if (dateTo) {
      query.push({ field: `date_added`, op: "<=d", value: dateTo });
    }

    if (land === "1") {
      query.push({ field: `land`, op: "=s", value: "land" });
    } else if (land === "2") {
      query.push({ field: `land`, op: "=s", value: "-" });
    }

    props.onChange(query);
  }
}
