import { Auth } from "aws-amplify";

export async function getInitialisedMessage(body: any = null) {
  const user = await Auth.currentAuthenticatedUser();
  const token = user.signInUserSession.idToken.jwtToken;
  return {
    headers: { Authorization: token },
    response: true,
    body,
  };
}
