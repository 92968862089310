import React from "react";
import { Grid, IconButton, Select } from "@material-ui/core";
import LeftIcon from "@material-ui/icons/ArrowLeft";
import RightIcon from "@material-ui/icons/ArrowRight";
import { updateField } from "../../libs/updateField";
import { SearchFilterProps } from "./types";

import "./SearchNavigator.scss";

interface Props {
  page: number;
  pageCount: number;
  setFilter: (filter: SearchFilterProps) => void;
}

export default function SearchNavigator(props: Props) {
  const { page, pageCount, setFilter } = props;

  const pageSet = (value) => setFilter({ page: value });

  return (
    <div className="search-results-paginator">
      <Grid container justifyContent="space-between">
        <IconButton disabled={page===1} onClick={() => setFilter({ page: page - 1 })}>
          <LeftIcon/>
          <div className="paginatorfont">
          Previous
          </div>
        </IconButton>
        <div className="dropdownsection">
          Page{" "}
          <Select native variant="filled" style={{ background: "white" }} value={page || ""} onChange={updateField(pageSet)}>
            {new Array(pageCount || 1).fill(0).map((p, i) => (
              <option key={`mi-${i}`} value={i + 1}>
                {i + 1}
              </option>
            ))}
          </Select>{" "}
          of {pageCount}
        </div>
        <IconButton disabled={page===pageCount} onClick={() => setFilter({ page: page + 1 })}>
          <div className="paginatorfont">
          Next
          </div>
          <RightIcon />
        </IconButton>
      </Grid>
    </div>
  );
}
