import React from "react";

interface Props {
  link?: string;
  text?: string;
}

export function CompanyHouseLink(props: Props) {
  const { link, text = "Companies House Record" } = props;
  return !link ? null : (
    <a href={`https://beta.companieshouse.gov.uk${link}`} target="_blank" rel="noopener noreferrer" style={{ textDecoration: "underline" }}>
      {text}
    </a>
  );
}
