import { AppBar, Button, FormControl, Grid, InputLabel, Link, MenuItem, Select, TextField, Toolbar, Tooltip, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { getAutocompletePlaces, getDatesPropertiesAdded } from "../../api/Api";
import InfoIcon from "@material-ui/icons/Info";
import { updateField } from "../../libs/updateField";
import NotificationsDialogContainer from "../Notifications/NotificationsDialog";
import "./SearchBar.scss";
import { SearchFilterProps } from "./types";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { transformAPIPlacesListForAutocompleteList } from "../../libs/helpres";

interface Props {
  setFilter: (filter: SearchFilterProps) => void;
  page: number;
  pageCount: number;
  hasResults?: boolean;
  filter: SearchFilterProps;
  totalCount: number;
  isSearching: boolean;
  data: [];
}

const defaultPropValues = {
  hasResults: true,
  filter: {
    priceFrom: "",
    priceTo: "",
    plotSizeFrom: "",
    plotSizeTo: "",
    propertyType: "",
    dateFrom: "",
    dateTo: "",
  },
};

interface Breadcrumb {
  region?: string[];
  district?: string[];
  postcode?: string[];
}

export default function SearchBar(props: Props) {
  props = Object.assign({}, defaultPropValues, props);
  const { hasResults, totalCount, filter, page, pageCount, isSearching, data } = props;
  const [selectedPlace, selectedPlaceSet] = useState<any>(null);
  const [priceFrom, minPriceSet] = useState(filter.priceFrom);
  const [priceTo, maxPriceSet] = useState(filter.priceTo);
  const [plotSizeFrom, minPlotSet] = useState(filter.plotSizeFrom);
  const [plotSizeTo, maxPlotSet] = useState(filter.plotSizeTo);
  const [propertyType, propertyTypeSet] = useState(filter.propertyType);
  const [dateFrom, dateFromSet] = useState(filter.dateFrom);
  const [dateTo, dateToSet] = useState(filter.dateTo);
  const [datesAdded, datesAddedSet] = useState<string[]>([]);
  const [dialogAlertShow, dialogAlertShowSet] = useState(false);
  const [places, placesSet] = useState<[]>([]);
  const [breadcrumb, breadcrumbSet] = useState<Breadcrumb>({});

  useEffect(() => {
    getDatesPropertiesAdded().then(datesAddedSet);
    getAutocompletePlaces().then((places) => placesSet(transformAPIPlacesListForAutocompleteList(places) as any));
  }, []);

  useEffect(() => {
    if (data?.length) {
      // @ts-ignore
      const line = data[0] || { district: "district", region: "region", postcode: "postcode" };
      if (window.location.search.includes("?postcode")) {
        breadcrumbSet({
          district: [line["district"]],
          region: [line["region"]],
          postcode: [line["postcode"]],
        });
      } else if (window.location.search.includes("?district")) {
        breadcrumbSet({
          district: [line["district"]],
          region: [line["region"]],
          postcode: undefined,
        });
      } else if (window.location.search.includes("?region")) {
        breadcrumbSet({
          district: undefined,
          region: [line["region"]],
          postcode: undefined,
        });
      }
    }
  }, [data]);

  function runQuery() {
    const newFilter: SearchFilterProps = {
      priceFrom,
      priceTo,
      plotSizeFrom,
      plotSizeTo,
      dateFrom,
      postcode: selectedPlace?.type === "postcode" ? selectedPlace.place : "",
      region: selectedPlace?.type === "region" ? selectedPlace.place : "",
      district: selectedPlace?.type === "district" ? selectedPlace.place : "",
    };
    props.setFilter(newFilter);
    console.log({ runQuery: newFilter, selectedPlace });
  }

  return (
    <>
      <NotificationsDialogContainer
        district={filter.district}
        postcode={filter.postcode}
        show={!isSearching && dialogAlertShow}
        handleClose={() => dialogAlertShowSet(!dialogAlertShow)}
      />

      <AppBar position="static" className="search-bar" style={{ backgroundColor: "white", color: "black" }}>
        <Toolbar>
          <Grid container spacing={2}>
            <Grid item>
              <Autocomplete
                className="postcode-field"
                size="small"
                id="combo-box-demo"
                options={places}
                getOptionLabel={(option) => option["place"]}
                fullWidth
                style={{ backgroundColor: "white", color: "black", borderRadius: 10, width: 200 }}
                onChange={(event, value) => selectedPlaceSet(value)}
                renderInput={(params) => <TextField {...params} variant="outlined" placeholder="Postcode, District, Region..." />}
              />
            </Grid>
            <Grid item>
              <FormControl className="select-field" size="small" variant="outlined">
                <InputLabel id="demo-simple-select-outlined-label">Min Price</InputLabel>
                <Select value={priceFrom || ""} onChange={updateField(minPriceSet)} label="Min Price">
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  <MenuItem value={1000}>1,000</MenuItem>
                  <MenuItem value={10000}>10,000</MenuItem>
                  <MenuItem value={100000}>100,000</MenuItem>
                  <MenuItem value={1000000}>1,000,000</MenuItem>
                  <MenuItem value={10000000}>10,000,000</MenuItem>
                  <MenuItem value={100000000}>100,000,000</MenuItem>
                  <MenuItem value={1000000000}>1B</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item>
              <FormControl className="select-field" size="small" variant="outlined">
                <InputLabel id="demo-simple-select-outlined-label">Max Price</InputLabel>
                <Select value={priceTo || ""} onChange={updateField(maxPriceSet)} label="Max Price">
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  <MenuItem value={1000}>1,000</MenuItem>
                  <MenuItem value={10000}>10,000</MenuItem>
                  <MenuItem value={100000}>100,000</MenuItem>
                  <MenuItem value={1000000}>1,000,000</MenuItem>
                  <MenuItem value={10000000}>10,000,000</MenuItem>
                  <MenuItem value={100000000}>100,000,000</MenuItem>
                  <MenuItem value={1000000000}>1B</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item>
              <FormControl className="select-field" size="small" variant="outlined">
                <InputLabel id="demo-simple-select-outlined-label">Min Plot</InputLabel>
                <Select value={plotSizeFrom || ""} onChange={updateField(minPlotSet)} label="Min Plot">
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  <MenuItem value={0}>0 acre</MenuItem>
                  <MenuItem value={1}>1 acre</MenuItem>
                  <MenuItem value={10}>10 acre</MenuItem>
                  <MenuItem value={100}>100 acre</MenuItem>
                  <MenuItem value={1000}>1000 acre</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item>
              <FormControl className="select-field" size="small" variant="outlined">
                <InputLabel id="demo-simple-select-outlined-label">Max Plot</InputLabel>
                <Select value={plotSizeTo || ""} onChange={updateField(maxPlotSet)} label="Max Plot">
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  <MenuItem value={0}>0 acre</MenuItem>
                  <MenuItem value={1}>1 acre</MenuItem>
                  <MenuItem value={10}>10 acre</MenuItem>
                  <MenuItem value={100}>100 acre</MenuItem>
                  <MenuItem value={1000}>1000 acre</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item>
              <FormControl className="select-field" size="small" variant="outlined">
                <InputLabel id="demo-simple-select-outlined-label">Property Type</InputLabel>
                <Select value={propertyType || ""} onChange={updateField(propertyTypeSet)} label="Property Type">
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  <MenuItem value={"c"}>Commercial</MenuItem>
                  <MenuItem value={"l"}>Land</MenuItem>
                  <MenuItem value={"r"}>Residential</MenuItem>
                  <MenuItem value={"x"}>Dual Use</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item>
              <FormControl className="select-field" size="small" variant="outlined">
                <InputLabel id="demo-simple-select-outlined-label">Date From</InputLabel>
                <Select native value={dateFrom || ""} onChange={updateField(dateFromSet)} label="Date From">
                  <option value={""}> </option>
                  {datesAdded.map((value, idx) => (
                    <option key={idx} value={value}>
                      {value}
                    </option>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item>
              <FormControl className="select-field" size="small" variant="outlined">
                <InputLabel id="demo-simple-select-outlined-label">Date To</InputLabel>
                <Select native value={dateTo || ""} onChange={updateField(dateToSet)} label="Date To">
                  <option value={""}> </option>
                  {datesAdded.map((value, idx) => (
                    <option key={idx} value={value}>
                      {value}
                    </option>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item>
              <FormControl className="select-field" size="small" variant="outlined">
                <Button variant="contained" onClick={runQuery}>
                  Search
                </Button>
              </FormControl>
            </Grid>
          </Grid>
        </Toolbar>
        <Grid container spacing={0} className="MuiToolbar-gutters" direction="column">
          <Grid item>
            <Typography variant="caption">
              {hasResults && selectedPlace && (
                <>
                  Distressed Properties in {("" + selectedPlace.place).toUpperCase()} results{" ▷"}
                </>
              )}
              &nbsp;
            </Typography>
            {!isSearching && selectedPlace && (
              <Link onClick={() => dialogAlertShowSet(true)}>
                <Typography variant="caption">Create Alert</Typography>
                <Tooltip
                  style={{ position: "absolute", marginLeft: "10px" }}
                  title="Click here to create Notifications for this postcode. Notifications can be managed on your profile page."
                  aria-label="add"
                >
                  <InfoIcon fontSize="small" />
                </Tooltip>
              </Link>
            )}
          </Grid>
          <Grid item>
            <Typography variant="caption">
              {hasResults && (
                <>
                  Total Leads {isSearching ? "?" : totalCount}, page {page} of {pageCount}
                </>
              )}
              &nbsp;
            </Typography>
          </Grid>
          <Grid item>
            {data?.length > 0 && (
              <Typography variant="caption">
                {breadcrumb.region && (
                  <span>
                    {/* eslint-disable-next-line */}
                    <a href={`?region=${breadcrumb.region}`} style={{ textDecoration: "underline" }}>
                      {breadcrumb.region}
                    </a>
                  </span>
                )}
                {breadcrumb.district && (
                  <span>
                    {" "}
                    &gt; {/* eslint-disable-next-line */}
                    <a href={`?district=${breadcrumb.district}`} style={{ textDecoration: "underline" }}>
                      {breadcrumb.district}
                    </a>
                  </span>
                )}
                {breadcrumb.postcode && (
                  <span>
                    {" "}
                    &gt; <span>{breadcrumb.postcode}</span>
                  </span>
                )}
              </Typography>
            )}
          </Grid>
        </Grid>
      </AppBar>
    </>
  );
}
