import React from "react";
import { Route, Switch } from "react-router-dom";
import DistressedAssetsContainer from "../Containers/DistressedAssetsContainer";
// import DistressedAssetsContainer2 from "../Containers/DistressedAssetsContainer2";
import ContactUsContainer from "../Containers/ContactUsContainer";
import DeletedPropertiesContainer from "../Containers/DeletedPropertiesContainer";
import HistoricPropertiesContainer from "../Containers/HistoricPropertiesContainer";
import MyPropertiesContainer from "../MyPropertyContainer/MyPropertiesContainer";
import MyPropertyContainer from "../Containers/MyPropertyContainer";
import DistressedAssetsSearchContainer from "../Containers/DistressedAssetsSearchContainer";
import FrontPage from "../Containers/FrontPage";
import PropertySearchContainer from "../PropertySearchForm/PropertySearchContainer";
import PropertyByTitleContainer from "../Containers/PropertyByTitleContainer";

export default function Routes() {
  return (
    <Switch>
      {/* <Route exact={true} path="/distressed-properties2">
        <PricingPodium>
          <DistressedAssetsContainer2 />
        </PricingPodium>
      </Route> */}
      <Route exact={true} path="/distressed-properties">
        <DistressedAssetsSearchContainer key={'distressed-properties'} />
      </Route>
      <Route exact={true} path="/distressed-properties/search">
        <DistressedAssetsContainer key={'distressed-properties-search'} />
      </Route>

      <Route exact={true} path="/stressed-properties">
        <DistressedAssetsSearchContainer showStressed={true}  key={'stressed-properties'} />
      </Route>
      <Route exact={true} path="/stressed-properties/search">
        <DistressedAssetsContainer showStressed={true} key={'stressed-properties-search'} />
      </Route>
      <Route exact={true} path="/properties/search">
        <PropertySearchContainer key={'properties-search'} />
      </Route>

      <Route exact={true} path="/deleted-properties">
        <DeletedPropertiesContainer />
      </Route>
      <Route exact={true} path="/historic-properties">
        <HistoricPropertiesContainer />
      </Route>
      <Route exact={true} path="/my-properties">
        <MyPropertiesContainer />
      </Route>
      <Route exact={true} path="/my-properties/:table_id/:property_stress_type">
        <MyPropertyContainer />
      </Route>
      <Route exact={true} path="/property">
        <PropertyByTitleContainer />
      </Route>
      <Route exact={true} path="/contact-us">
        <ContactUsContainer />
      </Route>
      <Route>
        <FrontPage />
      </Route>
    </Switch>
  );
}
