import React, { useEffect, useState } from "react";
import PropertySearchForm from "./PropertySearchForm";
import { getData, getTitledData, getTitleNumber, PropertyData } from "./api";
import SearchBarPostcodeUPRN from "./SearchBarPostcodeUPRN";
import SearchBarAllFields from "./SearchBarAllFields";

const NO_UPRN = "NO_UPRN"

type searchForTitleNumberFn = (data: any[], dataSet: (data: any[]) => void) => (uprn: string) => void;

const searchForTitleNumber: searchForTitleNumberFn = (data, dataSet) => async (uprn, parentUprn?) => {
  // if uprn does not yield a titleNumber
  let [error, titleData] = await getTitleNumber(uprn);
  if (error) {

    // try the parent uprn
    if (parentUprn && error === NO_UPRN) {
      [error, titleData] = await getTitleNumber(uprn);
    }

    // else display an error
    if (error) {
      return alert(error === NO_UPRN ? "There is no TitleNumber for this property currently" : error);
    }
  }

  if (titleData) {
    // update newPropertySearchData with data from titleData
    if (titleData?.title_data?.length) {
      let title_number = titleData.title_data[0]["title_number"];

      // we currently assume there will only ever be 2 entries in title_data
      if (titleData.title_data[1]?.["title_class"] === "Absolute freehold title") {
        title_number = titleData.title_data[1]["title_number"];
      }

      // update newPropertySearchData with the title_number
      const updatedData: any[] = data.map((p) => (p.uprn !== uprn ? p : Object.assign({}, p, { title_number })));
      dataSet(updatedData);
    }
  }
};

interface Props {}

export default function PropertySearchContainer(props: Props) {
  const [oldPropertySearchDataIsBusy, oldPropertySearchDataIsBusySet] = useState<boolean>(false);
  const [newPropertySearchDataIsBusy, newPropertySearchDataIsBusySet] = useState<boolean>(false);
  const [newPropertySearchData, newPropertySearchDataSet] = useState<PropertyData[]>([]);
  const [oldPropertySearchData, oldPropertySearchDataSet] = useState<PropertyData[]>([]);

  useEffect(() => {
    getTitledData(oldPropertySearchDataSet, newPropertySearchDataIsBusySet);
  }, []);

  return (
    <>
      <PropertySearchForm
        getData={(postcodeUprn: string, searchBy?: string) => getData(postcodeUprn, newPropertySearchDataSet, oldPropertySearchDataIsBusySet, searchBy)}
        searchForTitleNumber={searchForTitleNumber(newPropertySearchData, newPropertySearchDataSet)}
        title="Property Search"
        data={newPropertySearchData}
        isBusy={oldPropertySearchDataIsBusy}
        SearchBarComponent={SearchBarPostcodeUPRN}
      />
      <PropertySearchForm
        getData={() => getTitledData(oldPropertySearchDataSet, newPropertySearchDataIsBusySet)}
        title="Previous Property Search Results"
        data={oldPropertySearchData}
        isBusy={newPropertySearchDataIsBusy}
        SearchBarComponent={SearchBarAllFields}
      />
    </>
  );
}
