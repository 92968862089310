import FirebaseApp from "firebase/app";
import "firebase/messaging";
import { updateFirebaseToken } from "../api/Api";

export const firebaseConfig = {
  apiKey: "AIzaSyA5Fji2FsWWxhcHJqgWXQ5JZniLPLqz00c",
  authDomain: "hammered-8f07f.firebaseapp.com",
  projectId: "hammered-8f07f",
  storageBucket: "hammered-8f07f.appspot.com",
  messagingSenderId: "396913510104",
  appId: "1:396913510104:web:505eab6c6edd00d2b979ad",
};

export const firebaseConfigOparoAccount = {
  apiKey: "AIzaSyCIm-jlr_YwLKcRA3_Fj6iQA1UREalI0IA",
  authDomain: "hammered-cbda2.firebaseapp.com",
  projectId: "hammered-cbda2",
  storageBucket: "hammered-cbda2.appspot.com",
  messagingSenderId: "963813485654",
  appId: "1:963813485654:web:63bd75a8d368ec2dff9ff7",
};

const vapidKey = "BDS51NpYUqCQJh9ZAih13T95o7QdcumvAbS6XK2yn_mkMHCoXT1Ar60BsfHnt2J0ixq_RblxKffDjSqZ0Izw6wY";

export default async function setupFirebase() {
  // Initialize Firebase
  if (!FirebaseApp.app.length){

  FirebaseApp.initializeApp(firebaseConfig);
  const messaging = FirebaseApp.messaging();

  messaging
    .getToken({ vapidKey })
    .then(async (token) => {
      localStorage.setItem("firebase-token", token);
      await updateFirebaseToken(token);
    })
    .catch((err) => {
      if (err.code === "messaging/permission-blocked") {
        console.log("permission blocked");
        return false;
      }
      if (err.message.indexOf("already up to date") >= 0) {
        return true;
      }
      console.log("Don`t have permission :(", err);
    });

  messaging.onMessage((payload) => {
    const { title, icon, body } = payload.notification;
    const options = {
      image: icon,
      body,
    };
    Notification.permission === "granted" && new Notification(title, options);
  });

  }
}

export async function taredownFirebase(){
  localStorage.setItem("firebase-token", "");
}