import { useState } from "react";

const DEFAULT_PROPERTY_COLOURS = {
  distressed: "beige",
  stressed: "lightblue",
};
const PROPERTY_COLOURS_LOCALSTORAGE_KEY = "defaultColoursSettings";

let propertyColours;
try {
  propertyColours = JSON.parse(localStorage[PROPERTY_COLOURS_LOCALSTORAGE_KEY]);
} catch (e) {
  propertyColours = Object.assign({}, DEFAULT_PROPERTY_COLOURS);
  localStorage[PROPERTY_COLOURS_LOCALSTORAGE_KEY] = JSON.stringify(propertyColours);
}

export default function usePropertyColours(): [{ distressed: string; stressed: string }, ({ distressed, stressed }) => void] {
  const [savedColours, savedColoursSet] = useState(propertyColours);

  function coloursSet({ distressed, stressed }: { distressed: string; stressed: string }) {
    let currentColours = Object.assign({}, savedColours);

    if (distressed) {
      Object.assign(currentColours, { distressed });
      localStorage.setItem(PROPERTY_COLOURS_LOCALSTORAGE_KEY, JSON.stringify(currentColours));
      savedColoursSet(currentColours);
    }
    if (stressed) {
      Object.assign(currentColours, { stressed });
      localStorage.setItem(PROPERTY_COLOURS_LOCALSTORAGE_KEY, JSON.stringify(currentColours));
      savedColoursSet(currentColours);
    }
  }
  return [savedColours, coloursSet];
}
