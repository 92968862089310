import API from "@aws-amplify/api";
import { Grid, IconButton, makeStyles, createStyles, Checkbox, CircularProgress } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { getInitialisedMessage } from "../../libs/session";
import CachedIcon from "@material-ui/icons/Cached";
import { sortDesc } from "../../libs/helpres";
import config from "../../config/config";

interface Props {
  companyNumber?: string;
}

interface ChargeTransaction {
  delivered_on: string;
  filing_type: string;
  insolvency_case_number: number;
  links: {
    filing: string;
    insolvency_case: string;
  };
  transaction_id: number;
}

interface Charges {
  charge_number: string;
  created_on: string;
  status: string;
  persons_entitled: { name: string }[];
  transactions: ChargeTransaction[];
}

type APIReturn<T> = [string | null, T[] | null];

export const useStyles = makeStyles(() =>
  createStyles({
    rotateIcon: {
      animation: "$spin 2s linear infinite",
    },
    "@keyframes spin": {
      "0%": {
        transform: "rotate(360deg)",
      },
      "100%": {
        transform: "rotate(0deg)",
      },
    },
  })
);

async function getData(companyNumber: string): Promise<APIReturn<Charges>> {
  try {
    const config = await getInitialisedMessage();
    const url = `internal/company/${companyNumber}/charges`;
    const { data = [] }: { data: Charges[] } = await API.get("internal", url, config);
    return [null, data];
  } catch (e: any) {
    return [e["message"] as string, null];
  }
}

async function runData(companyNumber, classes, dataSet, loadingClassSet) {
  if (companyNumber) {
    loadingClassSet(classes.rotateIcon);
    const [err, data] = await getData(companyNumber as string);
    loadingClassSet({});
    if (!err) return dataSet(data ? data : []);
    console.log("[ChargesPanel] getData error: ", err);
  } else {
    console.log({ ChargesPanel: "No companyNumber provided" });
  }
}

function downloadURI(url, name) {
  var link = document.createElement("a");
  link.download = name;
  link.href = url;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

function DownloadButton(props) {
  const [isBusy, isBusySet] = useState(false);
  const { name, href } = props;
  const endpoint = config.apiGateway.URL_INTERNAL;

  const onClick = (url) => {
    if (isBusy) return;
    isBusySet(true);
    const getDoc = async () => {
      try {
        const config = await getInitialisedMessage();
        await fetch(`${endpoint}${url}`, config)
          .then((response) => response.blob())
          .then((data) => {
            const link = URL.createObjectURL(data);
            downloadURI(link, name);
          });
      } catch (e: any) {
        return [e["message"] as string, null];
      } finally {
        isBusySet(false);
      }
    };
    getDoc();
  };
  return (
    <>
      <button disabled={isBusy} onClick={() => onClick(href)}>
        {name}
      </button>
      {isBusy && <CircularProgress size={15} />}
    </>
  );
}

function transactionsToLinks(companyId, transactions: ChargeTransaction[]) {
  if (!transactions || transactions.length === 0) return null;
  const documentLink = (link) => {
    const filingHistoryId = link.split("/").pop();
    return `internal/company/${companyId}/filing-history-document/${filingHistoryId}`;
  };

  return (
    <ul>
      {transactions.sort(sortDesc("delivered_on")).map((t, idx) => (
        <li key={`charge-trans-${idx}`}>
          {t.links?.filing && (
            <DownloadButton href={documentLink(t.links.filing)} name={t.filing_type}>
              {t.filing_type}
            </DownloadButton>
          )}
          {!t.links?.filing && <span>{t.filing_type}</span>}
        </li>
      ))}
    </ul>
  );
}

export function ChargesPanel(props: Props) {
  const [data, dataSet] = useState<Charges[]>([]);
  const [showOnlyUnpaidCharges, showOnlyUnpaidChargesSet] = useState<boolean>(true);
  const [loadingClass, loadingClassSet] = useState<any>({});
  const classes = useStyles();

  const totalColumns = 4;
  const companyNumber = props.companyNumber;
  const dataToShow = data.filter((a) => (showOnlyUnpaidCharges ? a.status.toUpperCase() === "OUTSTANDING" : true));

  useEffect(() => {
    runData(companyNumber, classes, dataSet, loadingClassSet);
  }, [companyNumber, classes]);

  return (
    <Grid item className="summary" xs={12} md={6}>
      <Grid container justifyContent="space-between">
        <Grid item>
          <h3>charges</h3>
        </Grid>
        <Grid item>
          <IconButton title="Refresh" className={loadingClass} onClick={() => runData(companyNumber, classes, dataSet, loadingClassSet)}>
            <CachedIcon />
          </IconButton>
        </Grid>
      </Grid>

      <hr className="short" />

      {data?.length < 1 && (
        <p>
          <i>No Data available</i>
        </p>
      )}
      <table>
        <thead>
          <tr>
            <th style={{ textAlign: "left" }} colSpan={totalColumns}>
              Show only outstanding charges <Checkbox checked={showOnlyUnpaidCharges} onClick={() => showOnlyUnpaidChargesSet(!showOnlyUnpaidCharges)} />
            </th>
          </tr>
        </thead>
        {dataToShow
          .sort((a, b) => (a.created_on > b.created_on ? 1 : -1))
          .map((t, idx, arr) => (
            <tbody key={`cp-${idx}-${t["charge_number"]}`}>
              <tr>
                <td style={{ width: "5%" }}>{t["charge_number"]}: </td>
                <td style={{ width: "20%" }}>{t["created_on"]}</td>
                <td style={{ width: "20%" }}>{t["status"]}</td>
                <td>{t?.persons_entitled.map((a) => a["name"]).join(", ")}</td>
              </tr>
              <tr>
                <td colSpan={totalColumns}>{transactionsToLinks(companyNumber, t.transactions)}</td>
              </tr>
              {idx < arr.length - 1 && (
                <tr>
                  <td colSpan={totalColumns}>
                    <hr />
                  </td>
                </tr>
              )}
            </tbody>
          ))}
      </table>
    </Grid>
  );
}
