import React from "react";
import "./styles.scss";

import SearchForm from "./SearchForm";
import { SearchFilterProps } from "./types";
import { withRouter } from "react-router";

function DistressedAssetsContainer(props) {
  const showStressed = props.showStressed;
  return <SearchForm setFilter={setPathParams} showStressed={props.showStressed} />;

  function setPathParams(criteria: SearchFilterProps) {
    const filters: string[] = [];
    Object.keys(criteria).forEach((c) => {
      if (!!criteria[c]) {
        filters.push(`${c}=${criteria[c]}`);
      }
    });
    const filter = "?" + filters.join("&");
    const history = props["history"];
    const showStressedProperties = showStressed || criteria.stressed;
    const newHistory = `/${showStressedProperties ? "stressed-properties" : "distressed-properties"}/search${filter}`;
    history.push(newHistory);
  }
}

export default withRouter(DistressedAssetsContainer);
