import React, { useState } from "react";

import "./styles.scss";
import { doLogin } from "../../api/Api";
import { updateField } from "../../libs/updateField";
import { IconButton, Typography } from "@material-ui/core";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";

interface Props {
  onLogin: () => void;
  onRegistrationClick: () => void;
  onForgottenPassword: (email: string) => void;
}

const ERROR_MESSAGE_DISPLAY_TIME = 10000;

function LoginUI(props: Props) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isAuthenticating, setIsAuthenticating] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [errorMessage, errorMessageSet] = useState("");

  return (
    <div className="base-container page-login">
      <div className="content">
        <div className="header">Oparo Dashboards Login</div>
        <div className="form">
          <div className="form-group form-group-top">
            <input type="text" name="username" placeholder="Email" value={email} onChange={updateField(setEmail)} />
          </div>
          <div className="form-group form-group-password">
            <input type={showPassword ? "text" : "password"} name="password" placeholder="Password" value={password} onChange={updateField(setPassword)} />
            <IconButton aria-label="toggle password visibility" onClick={() => setShowPassword(!showPassword)}>
              {showPassword ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          </div>
        </div>
        <div className="form-group">
          <Typography variant="body2" color="error">
            {errorMessage || <br />}
          </Typography>
        </div>
      </div>
      <div>
        {/* eslint-disable-next-line */}
        <a onClick={() => props.onForgottenPassword(email)}>Forgotten Password</a>
      </div>
      <div>
        <div className="footer">
          <button type="button" className="btnfocus" onClick={handleLogin} disabled={isAuthenticating}>
            Login
          </button>
        </div>
      </div>
    </div>
  );

  function showErrorMessage(result) {
    errorMessageSet(result);
    setTimeout(() => {
      errorMessageSet("");
    }, ERROR_MESSAGE_DISPLAY_TIME);
  }

  async function handleLogin() {
    setIsAuthenticating(true);
    const result = await doLogin({ email, password });
    setIsAuthenticating(false);

    if (result === true) {
      return props.onLogin();
    }

    showErrorMessage(result);
  }
}

export default LoginUI;
