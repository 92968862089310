import React, { useEffect, useState } from "react";
import { getPermissions, profileGet } from "../../api/Api";

interface Props {
  permission: string;
  children?: any;
  doesNotHave?: boolean;
  value?: number;
}
interface Permission {
  id: number;
  name: string;
  trader: number;
  starter: number;
  dominator: number;
}
type PermissionsArray = Permission[];

// eslint-disable-next-line
function PermissionedContent(props: Props) {
  /* 
    Usage:
      <PermissionedContent permission={PermissionType.PROJECT_CALCULATORS}>[contnt here]</PermissionedContent>
    PermissionType refers to an entry in database table "permissions", which records 1 for each user type
  */
  const [p, pSet] = useState<PermissionsArray>([]);
  const [u, uSet] = useState("");
  const [loaded, loadedSet] = useState(false);
  useEffect(() => {
    (async () => Promise.all([pSet(await getPermissions()), uSet(await profileGet())]).then(()=>loadedSet(true)) )();
  }, []);
  if (p && u) {
    const permission: Permission = p[props.permission];
    const userType = u["subscriptionType"];

    // handle special case where we're displaying something if permission isn't given
    // as well as when permission is given
    if (!permission) return "Unvailable";
    if (!(permission[userType] === 1 || (permission[userType] === 0 && props?.doesNotHave))) {
      if (permission["trader"]) return <p>{" "}</p>;
      if (permission["dominator"]) return <p>{" "}</p>;
    }
  }
  if (loaded){
    return props["children"] || <></>;
  }
  return <></>;  
}

export enum PermissionType {
  DATA_EPIC = "DATA_EPIC",
  ALERTS_ADVANCED = "ALERTS_ADVANCED",
  DATA_FULL_ACCESS = "DATA_FULL_ACCESS",
  DATA_SALES_COMPS = "DATA_SALES_COMPS",
  REPORTING_BESPOKE = "REPORTING_BESPOKE",
  REPORTING_BRANDED = "REPORTING_BRANDED",
  ALERTS_POSTCODE_LEVEL = "ALERTS_POSTCODE_LEVEL",
  CREDITS_CARRY_FORWARD = "CREDITS_CARRY_FORWARD",
  DATA_COST_SQUARE_FOOT = "DATA_COST_SQUARE_FOOT",
  DATA_PROPERTY_VALUATION = "DATA_PROPERTY_VALUATION",
  DATA_PLANNING_PORTAL_LINK = "DATA_PLANNING_PORTAL_LINK",
  PROJECT_CALCULATORS = "PROJECT_CALCULATORS",
  AREA_ANALYSIS = "AREA_ANALYSIS",
  PROPERTY_DETAILS_OS_MAP = "PROPERTY_DETAILS_OS_MAP",
  PROPERTY_DETAILS_GOOGLE_MAP = "PROPERTY_DETAILS_GOOGLE_MAP",
};
