
export type Card = {
  description: string;
  style?: {};
  draggable: boolean;
  id: string;
  label: string;
  note?: string;
  laneId: string;
  positionId?: number;
  metadata: {
    company_number: string;
    county: string;
    date_added: string;
    district: string;
    id: number;
    land: string;
    laneId: number;
    lease_price_paid?: number;
    lease_term?: string;
    note: string;
    plot_size: string;
    positionId: number;
    postcode: string;
    price_paid?: number;
    property_address: string;
    property_type?: string;
    region: string;
    tenure: string;
    title_number: string;
    total_floor_area: string;
  };
  assignedToList?: object;
  assignedTo?: string;
};

export type Lane = {
  cards: Card[];
  currentPage: number;
  id: string;
  label: string;
  title: string;
  style: any;
};

export type Lanes = {
  lanes: Lane[];
};

export const DEFAULT_LANES = [
  {
    id: "0",
    title: "Lead Purchased",
    label: "",
    cards: [],
    style: {
      color: "#fff",
      alignItems: "center",
      backgroundColor: "#060606",
      boxShadow: "2px 2px 4px 0px rgba(0,0,0,0.75)",
      border: "1px solid black",
    },
  },
  {
    id: "1",
    title: "Asset Due Diligence",
    label: "",
    cards: [],
    style: {
      color: "#fff",
      alignItems: "center",
      backgroundColor: "#060606",
      boxShadow: "2px 2px 4px 0px rgba(0,0,0,0.75)",
      border: "1px solid black",
    },
  },
  {
    id: "2",
    title: "Area Due Diligence",
    label: "",
    cards: [],
    style: {
      color: "#fff",
      alignItems: "center",
      backgroundColor: "#060606",
      boxShadow: "2px 2px 4px 0px rgba(0,0,0,0.75)",
      border: "1px solid black",
    },
  },
  {
    id: "3",
    title: "Run the Numbers & Stress Test",
    label: "",
    cards: [],
    style: {
      color: "#fff",
      alignItems: "center",
      backgroundColor: "#060606",
      boxShadow: "2px 2px 4px 0px rgba(0,0,0,0.75)",
      border: "1px solid black",
    },
  },
  {
    id: "4",
    title: "Research Legal Owner & Contact",
    label: "",
    cards: [],
    style: {
      color: "#fff",
      alignItems: "center",
      backgroundColor: "#060606",
      boxShadow: "2px 2px 4px 0px rgba(0,0,0,0.75)",
      border: "1px solid black",
    },
  },
  {
    id: "5",
    title: "Contact Receiver or Administrator",
    label: "",
    cards: [],
    style: {
      color: "#fff",
      alignItems: "center",
      backgroundColor: "#060606",
      boxShadow: "2px 2px 4px 0px rgba(0,0,0,0.75)",
      border: "1px solid black",
    },
  },
  {
    id: "6",
    title: "Present Offer",
    label: "",
    cards: [],
    style: {
      color: "#fff",
      alignItems: "center",
      backgroundColor: "#060606",
      boxShadow: "2px 2px 4px 0px rgba(0,0,0,0.75)",
      border: "1px solid black",
    },
  },
  {
    id: "7",
    title: "Not Accepted Re-assess & Re-offer",
    label: "",
    cards: [],
    style: {
      color: "#fff",
      alignItems: "center",
      backgroundColor: "#ff4101",
      boxShadow: "2px 2px 4px 0px rgba(0,0,0,0.75)",
      border: "2px solid black",
    },
  },
  {
    id: "8",
    title: "Agree Heads of Terms",
    label: "",
    cards: [],
    style: {
      color: "#fff",
      alignItems: "center",
      backgroundColor: "#060606",
      boxShadow: "2px 2px 4px 0px rgba(0,0,0,0.75)",
      border: "1px solid black",
    },
  },
  {
    id: "9",
    title: "Ongoing Negotiation",
    label: "",
    cards: [],
    style: {
      color: "#fff",
      alignItems: "center",
      backgroundColor: "#060606",
      boxShadow: "2px 2px 4px 0px rgba(0,0,0,0.75)",
      border: "1px solid black",
    },
  },
  {
    id: "10",
    title: "Exchange Contracts",
    label: "",
    cards: [],
    style: {
      color: "#fff",
      alignItems: "center",
      backgroundColor: "#060606",
      boxShadow: "2px 2px 4px 0px rgba(0,0,0,0.75)",
      border: "1px solid black",
    },
  },
  {
    id: "11",
    title: "Completed Deals",
    label: "",
    cards: [],
    style: {
      color: "#fff",
      alignItems: "center",
      backgroundColor: "#009a74",
      boxShadow: "2px 2px 4px 0px rgba(0,0,0,0.75)",
      border: "2px solid black",
    },
  },
];