import React, { useEffect, useState } from "react";
import { getSub, postPurchasePropertyInternal, postDeletePropertyInternal } from "../../api/Api";
import "./styles.scss";
import SimpleDialogContainer from "./SimpleDialogContainer";
import SearchBar from "./SearchBar";
import { SearchFilterProps } from "./types";
import { Box, Fade, Hidden, Modal } from "@material-ui/core";
import { getInitialisedMessage } from "../../libs/session";
import { API } from "aws-amplify";
import { numberWithCommas } from "../../libs/helpres";
import SearchNavigator from "./SearchNavigator";
import { useLocation, withRouter } from "react-router";
import SearchResultTiles from "../SearchResultTiles/SearchResultTiles";
import SearchResultTilesMobile from "../SearchResultTiles/SearchResultTilesMobile";
import BorderLinearProgress from "../BorderLinearProgress/BorderLinearProgress";
// import AllPropertiesContainer from './AllPropertiesContainer';

// import OSMap from "../PropertyDetails/OSMap";
import Maps from "../PropertyDetails/Maps";

type SQLSort = "asc" | "desc";

interface QueryParams {
  pageSize: number;
  page: number;
  orderBy: {
    field: string;
  };
  orderDirection: SQLSort;
  where: any[];
}

interface FieldOpValue {
  field: string;
  op: string;
  value?: string | number;
  value1?: string | number;
  value2?: string | number;
}
interface ResultsData {
  count: number;
  data: any[];
  page: number;
  totalCount: number;
}
type SimpleFn = (value: any) => void;

const DEFAULT_PAGE_SIZE = 10;

function scrollToTop() {
  window.scrollTo(0, 0);
}

function fieldsToWhereClause(fields?: SearchFilterProps | null): FieldOpValue[] {
  if (!fields) return [];

  const fieldOpValue: FieldOpValue[] = [];
  const { postcode, district, land, tenure, priceFrom, priceTo, plotSizeFrom, plotSizeTo, dateFrom, dateTo, propertyType, region } = fields;

  if (postcode) {
    fieldOpValue.push({ field: `postcode`, op: "~s", value: postcode });
  }
  if (region) {
    fieldOpValue.push({ field: `region`, op: "=s", value: region });
  }
  if (tenure && tenure.toLowerCase() !== "both") {
    fieldOpValue.push({ field: `tenure`, op: "=s", value: tenure.toLowerCase() });
  }
  if (district) {
    fieldOpValue.push({ field: `district`, op: "=s", value: district.toLowerCase() });
  }

  if (priceFrom && priceTo) {
    fieldOpValue.push({ field: `price_paid`, op: "between", value1: priceFrom, value2: priceTo });
  } else if (priceFrom) {
    fieldOpValue.push({ field: `price_paid`, op: ">=", value: priceFrom });
  } else if (priceTo) {
    fieldOpValue.push({ field: `price_paid`, op: "<=", value: priceTo });
  }

  if (plotSizeFrom && plotSizeTo) {
    fieldOpValue.push({ field: `plot_size`, op: "between", value1: plotSizeFrom, value2: plotSizeTo });
  } else if (plotSizeFrom) {
    fieldOpValue.push({ field: `plot_size`, op: ">=", value: plotSizeFrom });
  } else if (plotSizeTo) {
    fieldOpValue.push({ field: `plot_size`, op: "<=", value: plotSizeTo });
  }

  if (dateFrom && dateTo) {
    fieldOpValue.push({ field: `date_added`, op: "between", value1: dateFrom, value2: dateTo });
  } else if (dateFrom) {
    fieldOpValue.push({ field: `date_added`, op: ">=d", value: dateFrom });
  } else if (dateTo) {
    fieldOpValue.push({ field: `date_added`, op: "<=d", value: dateTo });
  }

  if (land === "1") {
    fieldOpValue.push({ field: `land`, op: "=s", value: "land" });
  } else if (land === "2") {
    fieldOpValue.push({ field: `land`, op: "=s", value: "-" });
  }

  if (propertyType) {
    fieldOpValue.push({ field: `property_type`, op: "=s", value: propertyType.toLowerCase() });
  }

  return fieldOpValue;
}

async function loadData(offset: Number, count: Number, orderBy, where, endpointName = "distressed") {
  const sub = await getSub();
  const headers = await getInitialisedMessage();
  const init = Object.assign(headers, {
    queryStringParameters: {
      count,
      offset,
    },
  });
  if (orderBy.field) {
    init.queryStringParameters["orderBy"] = `${orderBy.field},${orderBy.asc}`;
  }
  if (where.length) {
    init.queryStringParameters["filters"] = JSON.stringify(where);
  }
  return API.get("internal", `internal/${sub}/${endpointName}`, init);
}

async function dataFn(query: QueryParams, pageSet: SimpleFn, pageCountSet: SimpleFn, endpoint) {
  // basic query bits
  const pageSize = query.pageSize;
  const page = query.page;
  let orderBy = {};

  // order by
  if (query.orderBy) {
    orderBy["field"] = query.orderBy.field;
    orderBy["asc"] = query.orderDirection === "asc";
  }

  const where = query.where;
  try {
    const results = await loadData(page * pageSize, pageSize, orderBy, where, endpoint);
    results.data.data = results.data.data.map((p) =>
      Object.assign(p, {
        total_floor_area: numberWithCommas(p.total_floor_area),
        price_paid: numberWithCommas(p.price_paid),
      })
    );
    const data = results.data;
    pageSet(data.page + 1);
    pageCountSet(Math.floor(data.totalCount / data.count) + (data.totalCount % data.count > 0 ? 1 : 0));
    return data;
  } catch (e: any) {
    console.log(e);
    return {
      data: [],
      page: 0,
      totalCount: 0,
    };
  }
}
function searchToObject(s: string) {
  if (!s) return "";
  const search = s.substring(1);
  return JSON.parse('{"' + decodeURI(search).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g, '":"') + '"}');
}
function useLocationParams(): SearchFilterProps {
  const location = searchToObject(useLocation().search);
  const result = {
    postcode: location.postcode,
    district: location.district,
    tenure: location.tenure,
    dateFrom: location.dateFrom,
    dateTo: location.dateTo,
    region: location.region,
    land: location.land,
    priceFrom: location.priceFrom,
    priceTo: location.priceTo,
    plotSizeTo: location.plotSizeTo,
    plotSizeFrom: location.plotSizeFrom,
  };
  return result;
}

interface ModalMapProps {
  open: boolean;
  onClose;
  postcode: any;
}
const ModalMapStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
};

function ModalMap(props: ModalMapProps) {
  const { open, onClose, postcode } = props;
  return (
    <div>
      <Modal open={open} closeAfterTransition>
        <Fade in={open}>
          <Box sx={{ ...ModalMapStyle, width: 500, justifyItems: "space-between" }}>
            <Maps placeText={postcode?.postcode} titleNumber={postcode?.title_number} postcode={postcode?.postcode} showOutcodeLayerOnly />
            <button style={{ float: "right" }} onClick={() => onClose()}>
              Close
            </button>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}

function DistressedAssetsContainer(props) {
  const query = useLocationParams();
  const [selectedRow, setSelectedRow] = useState({});
  const [errorDialogShow, setErrorDialogShow] = useState(false);
  const [errorDialogMessage, setErrorDialogMessage] = useState("");
  const [errorDialogTitle, setErrorDialogTitle] = useState("");
  const [isBusy, setIsBusy] = useState(false);

  const [dialogDeleteShow, setDialogDeleteShow] = useState(false);
  const [dialogDeleteMessage, setDialogDeleteMessage] = useState("");
  const [dialogDeleteTitle, setDialogDeleteTitle] = useState("");
  const [selectedDeleteRow, setSelectedDeleteRow] = useState({});

  const [dialogViewPropertyShow, dialogViewPropertyShowSet] = useState(false);
  const [openModalPostcode, openModalPostcodeSet] = useState<string>("");
  const [openModalMap, openModalMapSet] = useState<boolean>(false);

  const [hasResults, hasResultsSet] = useState(false);
  const [data, dataSet] = useState<any>([]);
  const [filter, filterSet] = useState<SearchFilterProps>(query);
  const [isSearching, isSearchingSet] = useState<boolean>(false);
  const [previousQueryJSON, previousQueryJSONSet] = useState("");

  const [page, pageSet] = useState(1);
  const [pageCount, pageCountSet] = useState(1);
  const justDisplayResults = props.justDisplayResults || false;

  useEffect(() => {
    const jsonQuery = JSON.stringify(query);
    // skip if no changes detected
    if (previousQueryJSON === jsonQuery) return;
    previousQueryJSONSet(jsonQuery);
    setFilter(query);
    // eslint-disable-next-line
  }, [query, previousQueryJSON]);

  const onShowModalMap = (postcode: any) => [openModalPostcodeSet(postcode), openModalMapSet(!openModalMap)];

  function setFilter(criteria: SearchFilterProps) {
    const endpoint = props?.showStressed === true ? "stressed" : "distressed";
    let newFilter: SearchFilterProps = Object.assign({}, criteria);
    const queryParams = Object.keys(newFilter)
      .filter((p) => newFilter[p])
      .map((key) => `${key}=${newFilter[key]}`)
      .join("&");
    if (queryParams) {
      const newPath = `/${endpoint}-properties/search?${queryParams}`;
      props.history.push(newPath);
    }
    filterSet(newFilter);
    isSearchingSet(true);

    window.setTimeout(async () => {
      scrollToTop();
      const query: QueryParams = {
        pageSize: DEFAULT_PAGE_SIZE,
        page: !criteria.page ? 0 : criteria.page < 0 ? 0 : criteria.page - 1,
        orderBy: {
          field: "date_added",
        },
        orderDirection: "desc",
        where: fieldsToWhereClause(newFilter),
      };
      const data = await dataFn(query, pageSet, pageCountSet, endpoint);
      dataSet(data);
      isSearchingSet(false);
      hasResultsSet(true);
    }, 0);
  }

  function onPurchaseProperty(rowData) {
    setSelectedRow(rowData);
    onTrackConfirm(rowData);
  }


  function onDeleteProperty(rowData) {
    setSelectedDeleteRow(rowData);
    setDialogDeleteMessage("You are about to move the property to the Deleted list");
    setDialogDeleteTitle("Confirm Deletion");
    setDialogDeleteShow(true);
  }

  function removeRowFromData(id) {
    const newData: ResultsData = Object.assign({}, data, { data: data.data.filter((d) => d.id !== id) });
    newData.totalCount--;
    dataSet(newData);
  }

  async function onDialogViewPropertyConfirm() {
    // histor
    const newPath = `/my-properties/${selectedRow["id"]}/${props?.showStressed?'stressed':'distressed'}`;
    props.history.push(newPath);
    onDialogViewPropertyCancel();
  }

  async function onDialogViewPropertyCancel() {
    dialogViewPropertyShowSet(false);
  }

  async function onTrackConfirm(rowData) {
    const id = rowData?.id || selectedRow?.["id"];
    if (!id) return alert('nothing to add');

    try {
      setIsBusy(true);
      const stressed: boolean = props?.showStressed || false;
      await postPurchasePropertyInternal(id, stressed);
      removeRowFromData(id);
      dialogViewPropertyShowSet(true);
    } catch (e: any) {
      console.log(e);
      setIsBusy(false);
      displayErrorDialog(e.message);
    }
    setIsBusy(false);
  }

  function displayErrorDialog(message, code = null) {
    if (code === 402) {
      setErrorDialogMessage(`Sorry, you have run out of credit or your subscription has expired`);
      setErrorDialogTitle("Purchase Canceled");
      setErrorDialogShow(true);
      return;
    }
    setErrorDialogMessage(`Sorry, there was an error:\n"${message}"`);
    setErrorDialogTitle("Purchase Canceled");
    setErrorDialogShow(true);
  }

  function onCloseErrorDialog() {
    setErrorDialogShow(false);
  }

  async function onDeleteConfirm() {
    try {
      setIsBusy(true);
      await postDeletePropertyInternal(selectedDeleteRow["id"]);
      removeRowFromData(selectedDeleteRow["id"]);
    } catch (e: any) {
      setDialogDeleteShow(false);
      setIsBusy(false);
      displayErrorDialog(e.message);
    }
    setIsBusy(false);
    setDialogDeleteShow(false);
  }

  function onDeleteCancel() {
    setDialogDeleteShow(false);
  }

  return (
    <>
      <SimpleDialogContainer
        show={dialogDeleteShow}
        message={dialogDeleteMessage}
        title={dialogDeleteTitle}
        onConfirm={onDeleteConfirm}
        onCancel={onDeleteCancel}
        lock={isBusy}
      />
      <SimpleDialogContainer
        show={dialogViewPropertyShow}
        message={"Do you want to view the property now?"}
        title={""}
        onConfirm={onDialogViewPropertyConfirm}
        onCancel={onDialogViewPropertyCancel}
        lock={isBusy}
      />
      <SimpleDialogContainer show={errorDialogShow} message={errorDialogMessage} title={errorDialogTitle} onCancel={onCloseErrorDialog} />

      <ModalMap open={openModalMap} onClose={onShowModalMap} postcode={openModalPostcode} />

      {!justDisplayResults && <SearchBar {...{ isSearching, setFilter, filter, page, pageCount, data: data.data }} totalCount={data ? data.totalCount : 0} />}

      {isSearching && (
        <div style={{ padding: 16 }}>
          <BorderLinearProgress color="primary" />
        </div>
      )}
      {/* <AllPropertiesContainer /> */}

      {hasResults && (
        <>
          <Hidden implementation="js" only={["xs", "sm"]}>
            <SearchResultTiles data={data} actions={{ onPurchaseProperty, onDeleteProperty, onShowModalMap }} />
          </Hidden>
          <Hidden implementation="js" only={["xl", "lg", "md"]}>
            <SearchResultTilesMobile data={data} actions={{ onPurchaseProperty, onDeleteProperty }} />
          </Hidden>
        </>
      )}
      {hasResults && !justDisplayResults && <SearchNavigator {...{ page, pageCount, setFilter }} />}
    </>
  );
}

export default withRouter(DistressedAssetsContainer);
