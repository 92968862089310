/* Register.jsx */
import React, { useState } from "react";
import loginImg from "./hammeredblack.png";
import "./styles.scss";
import { doRegistration } from "../../api/Api";
import { updateField } from "../../libs/updateField";
import { Typography, IconButton } from "@material-ui/core";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";

interface Props {
  onRegisterSuccess: () => void;
  onCancel: () => void;
  onForgottenPassword: () => void;
}

const ERROR_MESSAGE_DISPLAY_TIME = 10000;

function Register(props: Props) {
  const [name, nameSet] = useState("");
  const [phone, phoneSet] = useState("");
  const [company, companySet] = useState("");
  const [email, emailSet] = useState("");
  const [password, passwordSet] = useState("");
  const [errorMessage, errorMessageSet] = useState("");
  const [showConfirmationCode, showConfirmationCodeSet] = useState(false);
  const [showPassword, showPasswordSet] = useState(false);
  const [isPhoneInvalid, isPhoneInvalidSet] = useState(false);

  return showConfirmationCode ? renderConfirmationForm() : renderRegistrationForm();

  function renderRegistrationForm() {
    return (
      <div className="base-container page-login">
        <div className="content">
          <div className="image">
            <a href="https://www.hammeredleads.co.uk">
              <img className="img-login" src={loginImg} alt="register form" />
            </a>
          </div>
          <div className="header">Register</div>
          <div className="form">
            <div className="form-group form-group-top">
              <input required type="text" name="name" value={name} placeholder="Full Name*" onChange={updateField(nameSet)} autoFocus />
            </div>
            <div className="form-group">
              <input type="text" name="companyName" value={company} placeholder="Company Name" onChange={updateField(companySet)} />
            </div>
            <div className="form-group">
              <input
                style={isPhoneInvalid ? { borderColor: "red" } : {}}
                onKeyUp={() => isPhoneInvalidSet(false)}
                required
                type="text"
                name="phoone"
                value={phone}
                placeholder="Phone Number* (+447890123456)"
                onChange={updateField(phoneSet)}
              />
            </div>
            <div className="form-group">
              <input required type="text" name="email" placeholder="Email*" onChange={updateField(emailSet)} />
            </div>
            <div className="form-group form-group-password">
              <input required type={showPassword ? "text" : "password"} name="password" placeholder="Password*" onChange={updateField(passwordSet)} />
              <IconButton aria-label="toggle password visibility" onClick={() => showPasswordSet(!showPassword)}>
                {showPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </div>
          </div>
          <div className="form-group">
            <Typography variant="body2" color="error">
              {errorMessage || <br />}
            </Typography>
          </div>
        </div>
        <div className="footer">
          <button type="button" className="btnfocus" onClick={handleRegistration}>
            Register
          </button>
        </div>
        <p style={{marginTop: "20px",}}><a href="https://www.hammeredleads.co.uk">Want to know more? Check out all our features here.</a></p>
      </div>
    );
  }

  function renderConfirmationForm() {
    return (
      <div className="base-container page-login">
        <div className="content">
          <div className="image">
            <a href="https://www.hammeredleads.co.uk">
              <img className="img-login" src={loginImg} alt="register form" />
            </a>
          </div>
          <div className="header">Congratulations</div>
          <div className="form">
            <p style={{ color: "grey" }}>You account has been created, please click the button below to return to the Login Screen</p>
          </div>
          <div className="form-group">
            <Typography variant="body2" color="error">
              <br />
            </Typography>
          </div>
        </div>
        <div>
          <div className="footer">
            <button type="button" className="btn" onClick={() => props.onRegisterSuccess()}>
              Go To Login
            </button>
          </div>
        </div>
      </div>
    );
  }

  async function handleRegistration() {
    isPhoneInvalidSet(false);
    const result = await doRegistration({ email, password, name, company, phone });

    if (result === true) {
      return showConfirmationCodeSet(true);
    }

    errorMessageSet(result);
    if (result.toLowerCase() === "invalid phone number format.") {
      isPhoneInvalidSet(true);
    }
    setTimeout(() => {
      errorMessageSet("");
    }, ERROR_MESSAGE_DISPLAY_TIME);
  }
}

export default Register;
