import { Fade, Paper, Popper, Typography } from "@material-ui/core";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./Tile.scss";

interface Props {
  image: string;
  caption?: string;
  path?: string;
  size?: string;
  popperCaption?: string;
  emoji?: string;
}

function TileIcon(props: Props) {
  const [open, openSet] = useState<boolean>(false);
  const [anchorEl, anchorElSet] = React.useState<HTMLButtonElement | null>(null);

  return (
    <div
      className={`TileContainer ${props.size && props.size}`}
      onMouseMove={(e: any) => [anchorElSet(e.currentTarget), openSet(true)]}
      onMouseLeave={(e: any) => [openSet(false)]}
    >
      <Popper open={open} anchorEl={anchorEl} placement={"right"} transition modifiers={{ arrow: { enabled: true }, arrowElement: anchorEl }}>
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <Paper>
              <Typography style={{ padding: 8 }}>{props.popperCaption}</Typography>
            </Paper>
          </Fade>
        )}
      </Popper>
      <Link to={props.path || ""} title={props.caption || ""}>
        <div className={`Tile ${props.size && props.size}`}>
          {props.emoji && <span style={{ fontSize: "xx-large", width: "100%" }}>{props.emoji}</span>}
          {!props.emoji && <img src={props.image} alt={props.caption || ""} />}
        </div>
      </Link>
      {props.caption && (
        <span className="caption">
          <br />
          {props.caption}
        </span>
      )}
    </div>
  );
}

export default TileIcon;
