/* Register.jsx */
import React, { useState } from "react";
import loginImg from "./hammeredblack.png";
import "./styles.scss";
import { updateField } from "../../libs/updateField";
import { Typography, IconButton, CircularProgress } from "@material-ui/core";
import { Auth } from "aws-amplify";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";

interface Props {
  onSuccess: () => void;
  onCancel: () => void;
  // onForgottenPassword: () => void;
  email?: string;
}

const ERROR_MESSAGE_DISPLAY_TIME = 10000;
const EMAIL = "EMAIL";
const CODE = "CODE";

function Forgotten(props: Props) {
  const [code, codeSet] = useState("");
  const [email, emailSet] = useState(props.email || "");
  const [password, passwordSet] = useState(props.email || "");
  const [errorMessage, errorMessageSet] = useState("");
  const [showUI, showUISet] = useState(EMAIL);
  const [showBusy, showBusySet] = useState(false);
  const [showPassword, showPasswordSet] = useState(false);

  if (showUI === CODE) return renderCodeForm();
  return renderEmailForm();

  function renderEmailForm() {
    return (
      <div className="base-container page-login">
        <div className="content">
        <div className="image">
          <a href="https://www.hammeredleads.co.uk"><img className="img-login" src={loginImg} alt="login form" /></a>
          </div>
          <div className="header">Reset Password</div>
          <div className="form">
            <div className="form-group form-group-top">
              <input autoFocus type="email" name="code" placeholder="Please Enter Email" onChange={updateField(emailSet)} />
            </div>
          </div>
          <div className="form-group">
            <Typography variant="body2" color="error">
              {errorMessage || <br />}
            </Typography>
          </div>
        </div>
        <div className="footer">
          {showBusy && <CircularProgress />}
          {!showBusy && (
            <>
              <button type="button" className="btn" onClick={props.onCancel}>
                Cancel
              </button>
              <button type="button" className="btn" onClick={handleSendCode}>
                Send Code
              </button>
            </>
          )}
        </div>
        <div className="footer">
          {/* eslint-disable-next-line */}
          &nbsp;
        </div>
      </div>
    );
  }

  function renderCodeForm() {
    return (
      <div className="base-container page-login">
        <div className="content">
          <div className="image">{/* <img className="img-login" src={loginImg} alt="logo" /> */}</div>
          <div className="header">Enter Code and New Password</div>
          <div className="form">
            <div className="form-group disabled">
              <input type="text" value={email} disabled />
            </div>

            <div className="form-group">
              <input autoFocus type="tel" value={code} maxLength={6} minLength={6} name="code" placeholder="Code" onChange={updateField(codeSet)} />
            </div>

            <div className="form-group form-group-password">
              <input type={showPassword ? "text" : "password"} name="password" placeholder="New Password" onChange={updateField(passwordSet)} />
              <IconButton aria-label="toggle password visibility" onClick={() => showPasswordSet(!showPassword)}>
                {showPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </div>
          </div>
          <div className="form-group">
            <Typography variant="body2" color="error">
              {errorMessage || <br />}
            </Typography>
          </div>
        </div>
        <div className="footer">
          {showBusy && <CircularProgress />}
          {!showBusy && (
            <>
              <button type="button" className="btn" onClick={props.onCancel}>
                Cancel
              </button>
              <button type="button" className="btnfocus" onClick={handleCheckCode}>
                Confirm
              </button>
            </>
          )}
        </div>
        <div className="footer">
          {/* eslint-disable-next-line */}
          <a onClick={handleSendCode}>Resend Code</a>
        </div>
      </div>
    );
  }

  async function handleSendCode() {
    showBusySet(true);
    setTimeout(async () => {
      try {
        await Auth.forgotPassword(email);
        showUISet(CODE);
      } catch (e: any) {
        console.log(e);
        showErrorMessage(e.message);
      }
      showBusySet(false);
    }, 1000);
  }

  async function handleCheckCode() {
    showBusySet(true);
    setTimeout(async () => {
      try {
        await Auth.forgotPasswordSubmit(email, code, password);
        props.onSuccess();
      } catch (e: any) {
        console.log(e);
        showErrorMessage(e.message);
      }
      showBusySet(false);
    }, 1000);
  }

  function showErrorMessage(msg) {
    errorMessageSet(msg);
    setTimeout(() => {
      errorMessageSet("");
    }, ERROR_MESSAGE_DISPLAY_TIME);
  }
}

export default Forgotten;
