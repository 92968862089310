import React, { useState } from "react";
import QueryBuilder from "../QueryBuilder/QueryBuilder";
import QueryForm from "../QueryForm/QueryForm";

interface QueryUIsProps {
  onChange: Function;
  queryValue?: any;
}

export default function QueryUIs(props: QueryUIsProps) {
  const [showAdvancedFilter, showAdvancedFilterSet] = useState(false);
  const { queryValue = "", onChange } = props;
  const toggleFilter = () => {
    showAdvancedFilterSet(!showAdvancedFilter);
  }
  const filterState = showAdvancedFilter;
  return (
    <>
      <div className="clearfix"></div>
      <div className="query-holder" style={{backgroundColor:'#FFF'}}>
        {showAdvancedFilter ? (
          <QueryBuilder show={showAdvancedFilter} queryValue={queryValue} onChange={onChange} toggleFilter={()=>toggleFilter()} filterState={filterState} />
        ) : (
          <QueryForm show={showAdvancedFilter} queryValue={queryValue} onChange={onChange} toggleFilter={()=>toggleFilter()} filterState={filterState} />
        )}
      </div>
    </>
  );
}
