import React from "react";
import { Grid } from "@material-ui/core";
import "./SearchResultTiles.scss";
import { Button } from "@material-ui/core";
import Dual_Use_Black from "./Dual_Use_Black.png";
import Residential_Black from "./Residential_Black.png";
import Office_Block_Black from "./Office_Block_Black.png";
import Land from "./Land.png";
import MapIcon from "@material-ui/icons/Map";

const NOTHING = { text: "", image: null };
export const PROPERTY_TYPE_LOOKUP = {
  A: NOTHING,
  B: NOTHING,
  C: { text: "Commercial", image: Office_Block_Black },
  D: NOTHING,
  E: NOTHING,
  F: NOTHING,
  G: NOTHING,
  H: NOTHING,
  I: NOTHING,
  J: NOTHING,
  K: NOTHING,
  L: { text: "Land", image: Land },
  M: NOTHING,
  N: NOTHING,
  O: NOTHING,
  P: NOTHING,
  Q: NOTHING,
  R: { text: "Residential", image: Residential_Black },
  S: NOTHING,
  T: NOTHING,
  U: NOTHING,
  V: NOTHING,
  W: NOTHING,
  X: { text: "Dual Use", image: Dual_Use_Black },
  Y: NOTHING,
  Z: NOTHING,
};

interface Props {
  data?;
  actions?: {
    onPurchaseProperty: (rowData: any) => void;
    onDeleteProperty: (rowData: any) => void;
    onShowModalMap: (rowData: any) => void;
  };
}
interface PropertyResult {
  property_address: string;
  company_number: string;
  county: null | string;
  date_added: string;
  district: null | string;
  id: number;
  land: string;
  lease_price_paid: null | string;
  lease_term: null | string;
  plot_size: null | string;
  postcode: null | string;
  price_paid: null | string;
  property_type: null | string;
  region: null | string;
  tenure: string;
  title_number: string;
  total_floor_area: null | string;
  // new fields not in database yet
  building_height: null | string;
  building_footprint: null | string;
  date_sold: null | string;
  os_Codes_Desc: null | string;
  Last_Sold_Date: null | string;
}

function propertyTypeToImage(plot) {
  let propertyType = PROPERTY_TYPE_LOOKUP[("" + plot.property_type).toUpperCase()];
  return (
    <>
      <img src={propertyType?.image || ""} alt="" width="100%" />
      <span>{propertyType?.text}</span>
    </>
  );
}

export function extractPropertyTypes(plot) {
  let os_Codes_Desc = (plot.os_Codes_Desc || "[]").replaceAll(`'`, `"`) || "[]";
  os_Codes_Desc = os_Codes_Desc === "NULL" ? "[]" : os_Codes_Desc;
  os_Codes_Desc = os_Codes_Desc["replaceAll"]("None", `""`);
  os_Codes_Desc = os_Codes_Desc["replaceAll"]("NULL", `""`);
  let pt: any = [];
  try {
    pt = JSON.parse(os_Codes_Desc);
  } catch (e) {
    console.log("JSON from os_Codes_Desc invalid: ", os_Codes_Desc);
  }

  const pTypes = pt.map((p) => p.description.trim()).filter((s) => s !== "");
  if (pTypes.length < 1) return "-";
  const result = pTypes.join(", ");
  return result;
}

export default function SearchResultTiles(props: Props) {
  const dashNull = (value) => value || "-";
  const { onPurchaseProperty, onDeleteProperty } = props?.actions || {};

  return props.data.data.map((plot: PropertyResult, idx: number) => (
    <div className="search-result-item" key={idx}>
      <Grid container alignItems="center">
        <Grid item md={2} xs={12} style={{ padding: 32 }}>
          {propertyTypeToImage(plot) && <p className="property-block">{propertyTypeToImage(plot) || "Property Type Not Known"}</p>}
          <p className="tenure-block">{plot.tenure}</p>
        </Grid>
        <Grid item md={8} xs={12} style={{ padding: 32 }}>
          <table>
            <tbody>
              <tr>
                <td>
                  <Button variant="outlined" onClick={() => props?.actions?.onShowModalMap?.(plot)}>
                    Postcode <MapIcon />
                  </Button>
                </td>
                <td colSpan={3}>
                  {plot.property_address}<br/>
                  {plot.district} - {plot.county} - {plot.postcode}
                </td>
              </tr>
            </tbody>
            <tbody>
              <tr>
                <td>Region</td>
                <td>{dashNull(plot.region)}</td>
                <td>Property Type</td>
                <td>{extractPropertyTypes(plot)}</td>
              </tr>
              <tr style={{ backgroundColor: "#fff", border: "0px solid #fff", boxShadow: "none" }}>
                <td style={{ backgroundColor: "#fff", border: "0px solid #fff", boxShadow: "none" }}>&nbsp;</td>
              </tr>
              <tr>
                <td>Plot Size (acres)</td>
                <td>{dashNull(plot.plot_size)}</td>
                <td>Building Heights m</td>
                <td>{dashNull(plot.building_height)} </td>
              </tr>
              <tr>
                <td>Building Footprint m2</td>
                <td>{dashNull(plot.building_footprint)}</td>
                <td>Leasehold Term</td>
                <td>{dashNull(plot.lease_term)}</td>
              </tr>
              <tr>
                <td style={{ backgroundColor: "#fff", border: "0px solid #fff", boxShadow: "none" }}>&nbsp;</td>
              </tr>
              <tr>
                <td>Last Sold Price £</td>
                <td>{dashNull(plot.price_paid)}</td>
                <td>Last Sold Date</td>
                <td>{reformatDateYMD(dashNull(plot.Last_Sold_Date))}</td>
              </tr>
              <tr>
                <td>Date Added</td>
                <td>{dashNull(plot.date_added)}</td>
                <td style={{ backgroundColor: "#fff", border: "0px solid #fff", boxShadow: "none" }}></td>
                <td style={{ backgroundColor: "#fff", border: "0px solid #fff", boxShadow: "none" }}></td>
              </tr>
            </tbody>
          </table>
        </Grid>
        <Grid item md={2} xs={12}>
          <Grid container style={{ textAlign: "center" }}>
            <Grid item md={12} xs={6} style={{}}>
              <Button
                variant="contained"
                style={{ textAlign: "center", fontSize: "10px" }}
                className="resultbutton"
                size="small"
                onClick={() => onPurchaseProperty?.(plot)}
              >
                Track
              </Button>
            </Grid>
            <Grid item md={12} xs={6} style={{ marginTop: "10px" }}>
              <Button
                variant="contained"
                style={{ textAlign: "center", fontSize: "11px" }}
                className="resultbutton2"
                size="small"
                onClick={() => onDeleteProperty?.(plot)}
              >
                Delete
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  ));
}

function reformatDateYMD(dateString: string = "") {
  return dateString.split("/").reverse().join("-") || "-";
}
