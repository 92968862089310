import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { PropertyDetails } from "../PropertyDetails/PropertyDetails";
import { PropertyDetails as PropertyDetailsPrintView } from "../PropertyDetails/PropertyDetailsPrintView";
import { getPropertyInternal, processPropertyData } from "../../api/Api";
import { API } from "aws-amplify";
import { getInitialisedMessage } from "../../libs/session";

export default withRouter(MyPropertyContainer);

function MyPropertyContainer(props) {
  const [property, setProperty] = useState({ message: "Loading" });
  const [dossier, setDossier] = useState(null);
  const [noDataAvailable, setNoDataAvailable] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [extraData, extraDataSet] = useState({});
  const [isLoadingExtraData, isLoadingExtraDataSet] = useState(false);
  const [displayPrintView, displayPrintViewSet] = useState(window.location.search.indexOf("printview=true")>=0);
  const [isUpdatingBooking] = useState(true);
  const [isViewingBooked] = useState(true);

  useEffect(() => {
    (async () => {
      setIsLoading(true);

      const propertyData = await getPropertyInternal(props?.match?.params?.table_id, props?.match?.params?.property_stress_type);
      const { data } = propertyData
      if (!!data?.Polygon_Centre_Lat){
        // terrible hack, due to some latlng being swapped wtf!
        data.latlng = [data.Polygon_Centre_Long, data.Polygon_Centre_Lat]
        if (data.Polygon_Centre_Lat> data.Polygon_Centre_Long){
          data.latlng = [data.Polygon_Centre_Lat, data.Polygon_Centre_Long]
        }
      }

      if (data) {
        setProperty(data);
      } else {
        setProperty({ message: `Could not find property with id ${props?.match?.params?.table_id}/${props?.match?.params?.property_stress_type}` });
      }

      if (data === null) {
        setIsLoading(false);
        setNoDataAvailable(true);
        return;
      }
      const config = await getInitialisedMessage();
      const result = await API.get("backend", `user/any/company-id/${data["company_number"]}`, config);

      setDossier(result.data);
      setIsLoading(false);

      const postcode = data?.postcode;
      if (postcode) {
        isLoadingExtraDataSet(true);
        extraDataSet(await processPropertyData(postcode));
        isLoadingExtraDataSet(false);
      }
    })();
  }, [setProperty, props, setDossier]);

  const bookViewing = (propertyId) => alert(`bookViewing(${propertyId})`);
  const openBookedViewing = (propertyId) => alert(`openBookedViewing(${propertyId})`);

  return !displayPrintView ? (
    <PropertyDetails
      isLoading={isLoading}
      isLoadingExtraData={isLoadingExtraData}
      noDataAvailable={noDataAvailable}
      property={property}
      dossier={dossier}
      extraData={extraData}
      togglePrintView={()=>[displayPrintViewSet(!displayPrintView)]}
      bookViewing={bookViewing}
      isUpdatingBooking={isUpdatingBooking}
      isViewingBooked={isViewingBooked}
      openBookedViewing={openBookedViewing}
    />
  ) : (
    <PropertyDetailsPrintView
      isLoading={isLoading}
      isLoadingExtraData={isLoadingExtraData}
      noDataAvailable={noDataAvailable}
      property={property}
      dossier={dossier}
      extraData={extraData}
      togglePrintView={()=>[displayPrintViewSet(!displayPrintView)]}
    />
  );
}
