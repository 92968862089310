export const PRODUCT = "product";
export const SUBSCRIPTION = "subscription";
export const isLocal = window.document.location.href.includes("localhost");
export const isStaging = window.document.location.href.includes("cloudfront");
export const isDev = isLocal;
type EnvironmentTypes = "test" | "staging" | "prod";
export const environment: EnvironmentTypes = isDev || isLocal ? "test" : isStaging ? "staging" : "prod";

const mqogd5mex7Endpoint: string = `https://mqogd5mex7.execute-api.eu-west-2.amazonaws.com/api/`
const gxc63cxkoi: string = `https://gxc63cxkoi.execute-api.eu-west-2.amazonaws.com/api/`
const l461265h1i = `https://l461265h1i.execute-api.eu-west-2.amazonaws.com/api/`;

const config = {
  s3: {
    REGION: "eu-west-2",
  },
  apiGateway: {
    REGION: "eu-west-2",
    URL: isLocal ? "http://localhost:8000/" : mqogd5mex7Endpoint, // repo: backend-distressed-companies
    URL_INTERNAL: isLocal ? "http://localhost:8001/" : gxc63cxkoi, // repo: backend-internal
    URL_USER_BACKEND: isLocal ? "http://localhost:8002/" : l461265h1i, // repo: backend-users
  },
  cognito: {
    // same userpool used across all environments
    REGION: "eu-west-2",
    USER_POOL_ID: "eu-west-2_EY1iecIsU",
    APP_CLIENT_ID: "3fcu9ckd2lnsmferhikj1ivcc0",
    IDENTITY_POOL_ID: "eu-west-2:fda6ca04-cf7e-41e8-bd6c-1c7fd86dc7b3",
  },
};
export default config;

export const stripeConfig = {
  test: {
    // TODO move all this product info to the database
    products: {
      "starter": Product("prod_Jo3sCLZ0U0YHKK", "price_1JARkhA5cok6mb1ihfxXHmmW", "Hammered Subscription Starter", 15.0, "gbp", "2021-subscription-statrer", "starter-topup"),
      "trader": Product("prod_Jo3sJXRgOZZBWH", "price_1JARkGA5cok6mb1i8auc5U4f", "Hammered Subscription Trader", 50.0, "gbp", "2021-subscription-trader", "trader-topup"),
      "dominator": Product("prod_Jo3rnW4xx3wuuo", "price_1JARjnA5cok6mb1i0nNtnPri", "Hammered Subscription Dominator", 150.0, "gbp", "2021-subscription-dominator", "dominator"),

      "starter-topup": Product("prod_JoSFWtDw31j1xc", "price_1JApLQA5cok6mb1iS5YAzZrQ", "Hammered Starter Topup", 3.0, "gbp", "2021-starter-topup", "starter-topup"),
      "trader-topup": Product("prod_JoSEZrwNAFIzio", "price_1JApKVA5cok6mb1irN0NYecE", "Hammered Trader Topup", 2.5, "gbp", "2021-trader-topup", "trader-topup"),
      "dominator-topup": Product("prod_JoSCdtjuVlShfQ", "price_1JApJkA5cok6mb1iDNR9NAfc", "Hammered Dominator Topup", 1.5, "gbp", "2021-dominator-topup", "dominator-topup"),
    },
    key: "pk_test_9A2C8aWCFkIopncE8cUKIJ0W00JoVzHStP",
    urls: {
      successUrl: isLocal ? "http://localhost:3000" : window.document.location.href,
      cancelUrl: isLocal ? "http://localhost:3000" : window.document.location.href,
      subscription: {
        successUrl: isLocal ? "http://localhost:3000" : window.document.location.href,
        cancelUrl: isLocal ? "http://localhost:3000" : window.document.location.href,
      },
      product: {
        successUrl: isLocal ? "http://localhost:3000/profile" : `${window.document.location.href}`,
        cancelUrl: isLocal ? "http://localhost:3000/profile" : `${window.document.location.href}`,
      },
    },
  },
  staging: {
    // this should be a copy of test with updated product urls
    products: {
      "starter": Product("prod_Jo3sCLZ0U0YHKK", "price_1JARkhA5cok6mb1ihfxXHmmW", "Hammered Subscription Starter", 15.0, "gbp", "2021-subscription-statrer", "starter-topup"),
      "trader": Product("prod_Jo3sJXRgOZZBWH", "price_1JARkGA5cok6mb1i8auc5U4f", "Hammered Subscription Trader", 50.0, "gbp", "2021-subscription-trader", "trader-topup"),
      "dominator": Product("prod_Jo3rnW4xx3wuuo", "price_1JARjnA5cok6mb1i0nNtnPri", "Hammered Subscription Dominator", 150.0, "gbp", "2021-subscription-dominator", "dominator"),

      "starter-topup": Product("prod_JoSFWtDw31j1xc", "price_1JApLQA5cok6mb1iS5YAzZrQ", "Hammered Starter Topup", 3.0, "gbp", "2021-starter-topup", "starter-topup"),
      "trader-topup": Product("prod_JoSEZrwNAFIzio", "price_1JApKVA5cok6mb1irN0NYecE", "Hammered Trader Topup", 2.5, "gbp", "2021-trader-topup", "trader-topup"),
      "dominator-topup": Product("prod_JoSCdtjuVlShfQ", "price_1JApJkA5cok6mb1iDNR9NAfc", "Hammered Dominator Topup", 1.5, "gbp", "2021-dominator-topup", "dominator-topup"),
    },
    key: "pk_test_9A2C8aWCFkIopncE8cUKIJ0W00JoVzHStP",
    urls: {
      successUrl: window.document.location.href,
      cancelUrl: window.document.location.href,
      subscription: {
        successUrl: window.document.location.href,
        cancelUrl: window.document.location.href,
      },
      product: {
        successUrl: window.document.location.href + "/profile",
        cancelUrl: window.document.location.href + "/profile",
      },
    },
  },
  prod: {
    products: {
      "starter": Product("prod_JnkWNS10eAx1Bq", "price_1JA916A5cok6mb1iJnlFNnVb", "Hammered Subscription Starter", 15.0, "gbp", "2021-subscription-statrer", "starter-topup"),
      "trader": Product("prod_JnketMngIS5BN4", "price_1JA99CA5cok6mb1iYanAmuHl", "Hammered Subscription Trader", 50.0, "gbp", "2021-subscription-trader", "trader-topup"),
      "dominator": Product("prod_Jnkgm6x1VbgUbR", "price_1JA9AjA5cok6mb1iYnVmkKy5", "Hammered Subscription Dominator", 150.0, "gbp", "2021-subscription-dominator", "dominator-topup"),

      "starter-topup": Product("prod_JoSMJLnbvi108x", "price_1JApS0A5cok6mb1icrbseExW", "Hammered Starter Topup", 3.0, "gbp", "2021-starter-topup", "starter-topup"),
      "trader-topup": Product("prod_JoSMi0LIniAfJm", "price_1JApSDA5cok6mb1igmPnl4xc", "Hammered Trader Topup", 2.5, "gbp", "2021-trader-topup", "trader-topup"),
      "dominator-topup": Product("prod_JoSNoMtltjwpE3", "price_1JApSHA5cok6mb1iOr42cIN5", "Hammered Dominator Topup", 1.5, "gbp", "2021-dominator-topup", "dominator-topup"),
    },
    key: "pk_live_KzKSCYe5xgYyBzCpcnstSqJ500xJgqYtCb",
    urls: {
      successUrl: window.document.location.href,
      cancelUrl: window.document.location.href,
      subscription: {
        successUrl: window.document.location.href,
        cancelUrl: window.document.location.href,
      },
      product: {
        successUrl: window.document.location.href + "/profile",
        cancelUrl: window.document.location.href + "/profile",
      },
    },
  },
};

function Product(productId, priceId, name, price, currency, product_id, credits_product_key = "") {
  return { productId, priceId, name, price, currency, metadata: { product_id }, credits_product_key };
}

function getProducts(environment, config) {
  return config[environment].products;
}

function getProductByPriceId(products, priceId) {
  const keys = Object.keys(products);
  const productKey: any = keys.filter((key) => products[key].priceId === priceId);
  if (productKey.length === 0) return null;
  return products[productKey[0]];
}

export function getSubscriptionName(priceId) {
  const products = getProducts(environment, stripeConfig);
  const product = getProductByPriceId(products, priceId);
  return product ? product.name : "-";
}

export function getCreditProductBySubscriptionPriceId(priceId) {
  const products = getProducts(environment, stripeConfig);
  const subscription = getProductByPriceId(products, priceId);
  if (!subscription || !subscription.credits_product_key) return null;
  const product = products[subscription.credits_product_key];
  return product;
}
