import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { CircularProgress } from "@material-ui/core";
import './SimpleDialogContainer.scss';

interface Props {
  show: boolean;
  title: string;
  onConfirm?: Function;
  onCancel: Function;
  message: string;
  textConfirm?: string;
  textCancel?: string;
  lock?: boolean;
}

const defaultProps: Props = {
  show: false,
  title: "No Title",
  onConfirm: undefined,
  onCancel: ()=>{},
  message: "No message",
  textConfirm: "Confirm",
  textCancel: "Cancel",
  lock: false,
};

export default function SimpleDialogContainer(props: Props) {
  props = Object.assign({}, defaultProps, props);
  const {
    show,
    title,
    message,
    textConfirm,
    textCancel,
    onConfirm,
    onCancel,
    lock,
  } = props;

  return (
    <Dialog className="simple-dialog-container" open={show} onClose={() => !lock && onCancel()}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{message}</DialogContentText>
      </DialogContent>
      <DialogActions>
        {lock && <CircularProgress size="1em" color="inherit" />}
        {!lock && onCancel && (
          <Button onClick={() => onCancel()} color="primary">
            {textCancel}
          </Button>
        )}
        {!lock && onConfirm && (
          <Button onClick={() => onConfirm()} color="primary" autoFocus>
            {textConfirm}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
}
