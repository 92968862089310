import React from "react";
import Tile from "./Tile";
import image from "./DistressedAssets.png";

export function DistressedAssetsTile(props) {
  return (
    <Tile
      image={image}
      emoji={props.emoji}
      caption={props.caption !== undefined ? props.caption : "Distressed Assets"}
      popperCaption={props.popperCaption || props.caption}
      {...props}
    />
  );
}
