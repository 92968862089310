import React from "react";
import {
  Grid,
  Typography,
  Link,
} from "@material-ui/core";
import AccessAlarmIcon from "@material-ui/icons/Mail";
import FacebookIcon from "@material-ui/icons/Facebook";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import RoomIcon from "@material-ui/icons/Room";
import ReplyIcon from "@material-ui/icons/Reply";



export function OtherInfo() {
  const infos = [
    {
      icon: AccessAlarmIcon,
      heading: "Say hello",
      text: <p>Email: <a href="mailto:contact@hammeredleads.co.uk">contact@hammeredleads.co.uk</a></p>,
    },
    {
      icon: RoomIcon,
      heading: "Find Us In",
      text: (
        <p>
          HAMMERED Leads,
          <br />
          Silverstream House,
          <br />
          45 Fitzroy Street,
          <br />
          London,
          <br />
          W1T 6EB.
        </p>
      ),
    },
    {
      icon: ReplyIcon,
      heading: "Social Media",
      text: (
        <>
          <Link href="https://www.facebook.com/HammeredAuctions" target="">
            <FacebookIcon style={{paddingTop: '10px'}} fontSize="large"/>
          </Link>
          {" "}
          <Link href="https://www.linkedin.com/company/hammeredauctions" target="blank">
            <LinkedInIcon fontSize="large"/>
          </Link>
        </>
      ),
    },
  ];
  return (
    <Grid container spacing={2}>
      {infos.map((info, idx) => (
        <>
          <Grid key={`${info}${idx}`} item xs={2}>
            <Typography variant="h5" align="center">
              <info.icon fontSize="large" />
            </Typography>
          </Grid>
          <Grid item xs={10}>
            <Typography variant="h5">{info.heading}</Typography>
            {info.text}
          </Grid>
        </>
      ))}
    </Grid>
  );
}

