import React, { useState } from "react";
import { BlockPicker as SketchPicker } from "react-color";

export interface ColorPickerProps {
  onChange: (colourHex: string) => void;
  text: string;
  colourHex: string;
}

export function ColorPicker(props: ColorPickerProps) {
  const [show, showSet] = useState(false);
  const [colour, colourSet] = useState(props.colourHex);
  const toggleShow = () => showSet(!show);
  return (
    <>
      <div
        style={{ width: 100, height: 50, backgroundColor: colour, display: "flex", justifyContent: "center", alignItems: "center", cursor: "pointer" }}
        onClick={toggleShow}
      >
        {props.text}
      </div>
      {show ? (
        <div style={{ position: "absolute", zIndex: 100 }}>
          <div style={{ position: "fixed" }} onClick={toggleShow} />
          <SketchPicker color={colour} onChange={(value) => [toggleShow(), colourSet(value.hex), props.onChange(value.hex)]} />
        </div>
      ) : null}
    </>
  );
}
