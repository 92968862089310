import React, { useState } from "react";
import ResultLikeTable from "../ResultLikeTable/ResultLikeTable";
import QueryUIs from "../QueryUIs/QueryUIs";

export default function HistoricPropertiesContainer() {
  const [query, setQuery] = useState("");

  function updateQuery(_query) {
    setQuery(_query);
  }

  return (
    <>
      <div style={{ textAlign: "center" }}>
        <h1>HISTORIC PROPERTIES</h1>
      </div>

      <QueryUIs queryValue={query} onChange={updateQuery} />

      <ResultLikeTable
        tableTitle="Historic Properties"
        endpointName="historic"
        query={query}
        actions={[]}
        options={{
          actionsColumnIndex: -1,
        }}
      />
    </>
  );
}
