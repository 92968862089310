import API from "@aws-amplify/api";
import { getSub } from "../../api/Api";
import { numberWithCommas } from "../../libs/helpres";
import { getInitialisedMessage } from "../../libs/session";

export async function loadData(offset: Number, count: Number, orderBy, where) {
  const endpointName = "my-properties";
  const sub = await getSub();
  const headers = await getInitialisedMessage();
  const init = Object.assign(headers, {
    queryStringParameters: {
      count,
      offset,
    },
  });
  if (orderBy.field) {
    init.queryStringParameters["orderBy"] = `${orderBy.field},${orderBy.asc}`;
  }
  if (where.length) {
    init.queryStringParameters["filters"] = JSON.stringify(where);
  }
  return API.get("internal", `internal/${sub}/${endpointName}`, init);
}

export async function dataFn(): Promise<[] | string> {
  // basic query bits
  const pageSize = 1000000000; //query.pageSize;
  const page = 0; //query.page;
  let orderBy = {};

  let where: any[] = [];
  try {
    const results = await loadData(page * pageSize, pageSize, orderBy, where);
    results.data.data = results.data.data.map((p) =>
      Object.assign(p, {
        total_floor_area: numberWithCommas(p.total_floor_area),
        price_paid: numberWithCommas(p.price_paid),
      })
    );
    return results.data.data;
  } catch (e: any) {
    console.log("MyPropertiesContainer error", e, JSON.stringify(e));
    if (e?.message?.toLowerCase() === "network error") {
      return "Network Error, you may have a connection problem";
    }
    return e?.message || "";
  }
}