import React from "react";
import { PropertyData } from "./api";
import PropertySearchResultsTable from "./PropertySearchResultsTable";

interface Props {
  title: string;
  getData: (postcodeUprn: string, searchBy?: string) => void;
  searchForTitleNumber?: (uprn: string) => any;
  isBusy: boolean;
  data: PropertyData[];
  SearchBarComponent: any;
}

export default function PropertySearchForm(props: Props) {
  const { title, getData, isBusy, data, SearchBarComponent, searchForTitleNumber } = props;

  return (
    <>
      <div style={{ textAlign: "center" }}>
        <h1>{title}</h1>
      </div>

      <SearchBarComponent getData={getData} isBusy={isBusy} />

      <PropertySearchResultsTable data={data} searchForTitleNumber={searchForTitleNumber} />
    </>
  );
}
