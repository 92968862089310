import API from "@aws-amplify/api";
import { Grid, IconButton, makeStyles, createStyles, Checkbox } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { getInitialisedMessage } from "../../libs/session";
import CachedIcon from "@material-ui/icons/Cached";

interface Props {
  companyNumber?: string;
}

interface Establishment {
  company_name: string;
  company_number: string;
  company_status: string;
  links: {
    company: string;
  };
}

type APIReturn<T> = [string | null, T[] | null];

export const useStyles = makeStyles(() =>
  createStyles({
    rotateIcon: {
      animation: "$spin 2s linear infinite",
    },
    "@keyframes spin": {
      "0%": {
        transform: "rotate(360deg)",
      },
      "100%": {
        transform: "rotate(0deg)",
      },
    },
  })
);

async function getData(companyNumber: string): Promise<APIReturn<Establishment>> {
  try {
    const config = await getInitialisedMessage();
    const url = `internal/company/${companyNumber}/uk-establishments`;
    const { data = [] }: { data: Establishment[] } = await API.get("internal", url, config);
    return [null, data];
  } catch (e: any) {
    return [e["message"] as string, null];
  }
}

async function runData(companyNumber, classes, dataSet, loadingClassSet) {
  if (companyNumber) {
    loadingClassSet(classes.rotateIcon);
    const [err, data] = await getData(companyNumber as string);
    loadingClassSet({});
    if (!err) return dataSet(data ? data : []);
    console.log("[EstablishmentsPanel] getData error: ", err);
  } else {
    console.log({ EstablishmentsPanel: "No companyNumber provided" });
  }
}

export function EstablishmentsPanel(props: Props) {
  const [data, dataSet] = useState<Establishment[]>([]);
  const [showOnlyActiveEstablishments, showOnlyActiveEstablishmentsSet] = useState<boolean>(true);
  const [loadingClass, loadingClassSet] = useState<any>({});
  const classes = useStyles();

  useEffect(() => {
    runData(props.companyNumber, classes, dataSet, loadingClassSet);
  }, [props.companyNumber, classes]);

  return (
      <Grid item className="summary" xs={12} md={6}>
        <Grid container justifyContent="space-between">
          <Grid item>
            <h3>Establishments</h3>
          </Grid>
          <Grid item>
            <IconButton title="Refresh" className={loadingClass} onClick={() => runData(props.companyNumber, classes, dataSet, loadingClassSet)}>
              <CachedIcon />
            </IconButton>
          </Grid>
        </Grid>

        <hr className="short" />

        {data?.length < 1 && (
          <p>
            <i>No Data available</i>
          </p>
        )}
        <table>
          <thead>
            <tr>
              <th style={{ textAlign: "left" }} colSpan={2}>
                Show only active establishments{" "}
                <Checkbox checked={showOnlyActiveEstablishments} onClick={() => showOnlyActiveEstablishmentsSet(!showOnlyActiveEstablishments)} />
              </th>
            </tr>
          </thead>

          <tbody>
            {data
              .filter((a) => (showOnlyActiveEstablishments ? a.company_status.toUpperCase() === "ACTIVE" : true))
              .sort((a, b) => (a.company_name > b.company_name ? 1 : -1))
              .map((t, idx, arr) => (
                <>
                  <tr key={`ep-${idx}`}>
                    <td>{t["charge_number"]}: </td>
                    <td>{t["created_on"]}</td>
                    <td>{t["status"]}</td>
                  </tr>
                  {idx < arr.length - 1 && (
                    <tr>
                      <td colSpan={3}>
                        <hr />
                      </td>
                    </tr>
                  )}
                </>
              ))}
          </tbody>
        </table>
      </Grid>
  );
}
