import React, { useEffect, useState } from "react";
import ContactUs from "../ContactUs/ContactUs";
import { messagePost, profileGet } from "../../api/Api";

async function handleSubmit(event) {
  event.preventDefault();

  let contactUsData = {
    firstName: "",
    lastName: "",
    subject: "",
    message: "",
    email: "",
  };

  const elements = event.target.elements;
  for (let idx in elements) {
    if (contactUsData[elements[idx].name] !== undefined) {
      contactUsData[elements[idx].name] = elements[idx].value;
    }
  }
  await messagePost(contactUsData);
  return true;
}

export default function ContactUsContainer() {
  const [name, nameSet] = useState("");
  const [company, companySet] = useState("");
  const [subject] = useState("Hello");
  const [email, emailSet] = useState("");

  useEffect(() => {
    profileGet().then((profile) => {
      nameSet(profile.name);
      companySet(profile.company);
      emailSet(profile.email);
    });
  }, []);

  const childProps = { name, company, email, subject };

  return <ContactUs {...childProps} onSubmit={handleSubmit} />;
}
