import { Grid, makeStyles, createStyles } from "@material-ui/core";
import React from "react";
import { toValue } from "../../libs/helpres";
import { CompanyHouseLink } from "./CompanyHouseLink";
import { Lines } from "./Lines";
import { CompanySummary } from "./CompanySummary.type";

interface Props {
  companySummary?: CompanySummary[];
}

export const useStyles = makeStyles(() =>
  createStyles({
    rotateIcon: {
      animation: "$spin 2s linear infinite",
    },
    "@keyframes spin": {
      "0%": {
        transform: "rotate(360deg)",
      },
      "100%": {
        transform: "rotate(0deg)",
      },
    },
  })
);

export function CompanySummaryPanel(props: Props) {
  const { companySummary } = props;

  return (
      <Grid item className="summary" xs={12} md={6}>
        <h3>quick summary</h3>
        <hr className="short" />
        <table>
          <tbody>
            {companySummary?.map((t, idx) => (
              <tr key={`qs-${idx}`}>
                <td className="title">{t.text}:</td>
                <td className="value">{toValue(t.value) || <CompanyHouseLink link={t.link} /> || <Lines lines={t.lines} />}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </Grid>
  );
}
