import React, { useState } from "react";
import { Button, Grid, makeStyles, Theme } from "@material-ui/core";
import {
  getPropertyCalculation,
  getPropertyCalculationPricePerSqFoot,
  getPropertyCalculationSoldPrices,
  getPropertyCalculationSoldPricesPerSqFoot,
  getPropertyCalculationStampDuty,
  getPropertyCalculationPropertyValuationSale,
  getPropertyCalculationPropertyValuationRental,
  getPropertyCalculationDevelopmentCalculator,
  getPropertyCalculationDevelopmentMultUnit,
  getPropertyCalculationRents,
  getPropertyCalculationRentsHMO,
  getPropertyCalculationYields,
  getPropertySocialYields,
} from "../../api/Api";
import ResultDialog, { IResultDialogConfig, ResultDialogProps } from "./ResultsDialog";
import CalculatorDialog, { IDialogConfig } from "./CalculatorDialog";
import { PermissionType } from "../PermissionedContent/PermissionedContent";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
    backgroundColor: theme.palette.background.paper,
    color: "black",
  },
}));

const fields = {
  postcode: (value) => ({
    label: "postcode",
    field: "postcode",
    value,
    required: true,
    type: "String",
    disabled: true,
  }),
  minSquareFoot: () => ({
    label: "Min Square Feet",
    field: "min_sqf",
    type: "Number",
  }),
  maxSquareFoot: () => ({
    label: "Max Square Feet",
    field: "max_sqf",
    type: "Number",
  }),
  bedrooms: () => ({
    label: "No. of Bedrooms",
    field: "bedrooms",
    options: [0, 1, 2, 3, 4, 5],
    required: true,
  }),
  bathrooms: () => ({
    label: "No. of Bathrooms",
    field: "bathrooms",
    options: [0, 1, 2, 3, 4, 5],
    required: true,
  }),
  offStreetParking: () => ({
    label: "Off Street Parking",
    field: "off_street_parking",
    options: [0, 1, 2, 3],
    required: true,
  }),
  propertyType: () => ({
    label: "property type",
    field: "type",
    options: ["flat", "terraced_house", "semi-detached_house", "detached_house"],
  }),
  propertyType2: () => ({
    label: "property type",
    field: "property_type",
    options: ["flat", "terraced_house", "semi-detached_house", "detached_house"],
  }),
  tenure: () => ({
    label: "Tenure",
    field: "tenure",
    options: ["freehold", "leasehold"],
    required: true,
  }),
  numberValue: () => ({
    label: "Value",
    field: "value",
    type: "Number",
    required: true,
  }),
  country: () => ({
    label: "Country",
    field: "country",
    options: ["england", "scotland", "wales", "northern_ireland"],
  }),
  mode: () => ({
    label: "Mode",
    field: "mode",
    options: ["investment", "primary", "first_time", "non_resi"],
  }),
  constructionDate: () => ({
    label: "Construction Date",
    field: "construction_date",
    options: ["pre_1914", "1914_2000", "2000_onwards"],
    required: true,
  }),
  finishQuality: () => ({
    label: "Finish Quality",
    field: "finish_quality",
    options: ["very_high", "high", "average", "below_average", "unmodernised"],
    required: true,
  }),
  finishQualitySimple: () => ({
    label: "Finish Quality",
    field: "finish_quality",
    options: ["premium", "medium", "basic"],
    required: true,
  }),
  outdoorSpace: () => ({
    label: "Outdoor Space",
    field: "outdoor_space",
    options: ["none", "balcony_terrace", "garden", "garden_very_large"],
    required: true,
  }),
  internalArea: () => ({
    label: "Internal Area",
    field: "internal_area",
    type: "Number",
    required: true,
  }),
  purchasePrice: () => ({
    label: "Initial Property Purchase Price",
    field: "purchase_price",
    type: "Number",
    required: true,
  }),
  sqftPreDevelopment: () => ({
    label: "Sq Ft Pre Development",
    field: "sqft_pre_development",
    type: "Number",
    required: true,
  }),
  sqftPostDevelopment: () => ({
    label: "Sq Ft Post Development",
    field: "sqft_post_development",
    type: "Number",
    required: true,
  }),
  projectType: () => ({
    label: "Project Type",
    field: "project_type",
    options: ["refurbish", "demolition"],
    required: true,
  }),
  numberField: ({ label, field, value = 0 }) => ({
    label,
    field,
    value,
    type: "Number",
    required: true,
  }),
};

interface CalculatorMenuProps {
  postcode: string;
  calculatorType: PermissionType;
  propertyTitle: string;
}

export default function CalculatorMenu(props: CalculatorMenuProps) {
  const [openCalculatorDialog, openCalculatorDialogSet] = useState(false);
  const [openResultDialog, openResultDialogSet] = useState(false);
  const [diaglogConfig, diaglogConfigSet] = useState<IDialogConfig | any>();
  const [resultsDialogConfig, resultsDialogConfigSet] = useState<ResultDialogProps | any>();

  const classes = useStyles();
  const configCreateOnOk = createOnOk(openCalculatorDialogSet, resultsDialogConfigSet, openResultDialogSet, props.propertyTitle);

  const postcode = props.postcode;
  let calculators;

  if (props.calculatorType === PermissionType.AREA_ANALYSIS) {
    calculators = [
      {
        title: "Property Asking Price",
        description:
          "Calculates statistical average and confidence intervals of live property asking prices, from the smallest radius at which there is reasonable data.",
        onOk: configCreateOnOk(getPropertyCalculation, "Property Asking Price"),
        onClose: () => openCalculatorDialogSet(false),
        fields: [fields.postcode(postcode), fields.bedrooms(), fields.propertyType()],
      },
      {
        title: "Prices Per Sq Foot",
        description:
          "Calculates statistical average and confidence intervals of live property asking prices per square foot, from the smallest radius at which there is reasonable data",
        onOk: configCreateOnOk(getPropertyCalculationPricePerSqFoot, "Prices Per Sq Foot"),
        onClose: () => openCalculatorDialogSet(false),
        fields: [fields.postcode(postcode), fields.minSquareFoot(), fields.maxSquareFoot()],
      },
      {
        title: "Sold Prices",
        description: "Calculates statistical average and confidence intervals of property sold prices.",
        onOk: configCreateOnOk(getPropertyCalculationSoldPrices, "Sold Prices"),
        onClose: () => openCalculatorDialogSet(false),
        fields: [fields.postcode(postcode), fields.bedrooms(), fields.propertyType(), fields.tenure()],
      },
      {
        title: "Sold Prices Per Sq Foot",
        description: "Calculates statistical average and confidence intervals of property sold prices per square foot.",
        onOk: configCreateOnOk(getPropertyCalculationSoldPricesPerSqFoot, "Sold Prices Per Sq Foot"),
        onClose: () => openCalculatorDialogSet(false),
        fields: [fields.postcode(postcode), fields.minSquareFoot(), fields.maxSquareFoot(), fields.bedrooms(), fields.propertyType(), fields.tenure()],
      },
      {
        title: "Estimated Property Rents",
        description:
          "Returns statistical average and confidence intervals of live property asking rents. All rents are expressed as per week (for monthly values, multiply by 4.333).",
        onOk: configCreateOnOk(getPropertyCalculationRents, "Estimated Property Rents"),
        onClose: () => openCalculatorDialogSet(false),
        fields: [fields.postcode(postcode), fields.numberField({ label: "How many bedrooms", field: "bedrooms" }), fields.propertyType()],
      },
      {
        title: "Estimated Property Rents for Multiple Occupancies",
        description:
          "Returns statistical average and confidence intervals of the room rental market, segmented by double / single rooms and ensuite / shared bathroom.",
        onOk: configCreateOnOk(getPropertyCalculationRentsHMO, "Estimated Property Rents for Multiple Occupancies"),
        onClose: () => openCalculatorDialogSet(false),
        fields: [fields.postcode(postcode)],
      },
      {
        title: "Estimated Property Yields",
        description:
          "Returns the average property yields",
        onOk: configCreateOnOk(getPropertyCalculationYields, "Estimated Property Yields"),
        onClose: () => openCalculatorDialogSet(false),
        fields: [fields.postcode(postcode), fields.numberField({ label: "How many bedrooms", field: "bedrooms" }), fields.propertyType()],
      },
      {
        title: "Estimate Social Project Yield",
        description:
          "Returns initial yield for social housing",
        onOk: configCreateOnOk(getPropertySocialYields, "Estimate Social Project Yield"),
        onClose: () => openCalculatorDialogSet(false),
        fields: [fields.postcode(postcode), fields.bedrooms(), fields.numberField({ label: "Asking Price", field: "asking_price" })],
      },
    ];
  }

  if (props.calculatorType === PermissionType.PROJECT_CALCULATORS) {
    calculators = [
      {
        title: "Stamp Duty",
        description: "For a given transaction value, calculates the transaction tax payable.",
        onOk: configCreateOnOk(getPropertyCalculationStampDuty, "Stamp Duty"),
        onClose: () => openCalculatorDialogSet(false),
        fields: [fields.postcode(postcode), fields.numberValue(), fields.country(), fields.mode()],
      },
      {
        title: "Property Valuation Sale",
        description: "Calculates an estimated sale value (with +/- margin of error) for that property.",
        onOk: configCreateOnOk(getPropertyCalculationPropertyValuationSale, "Property Valuation Sale"),
        onClose: () => openCalculatorDialogSet(false),
        fields: [
          fields.postcode(postcode),
          fields.propertyType2(),
          fields.constructionDate(),
          fields.internalArea(),
          fields.bedrooms(),
          fields.bathrooms(),
          fields.finishQuality(),
          fields.outdoorSpace(),
          fields.offStreetParking(),
        ],
      },
      {
        title: "Property Valuation Rental",
        description: "Calculate an estimated rental valuation (with +/- margin of error) for that property.",
        onOk: configCreateOnOk(getPropertyCalculationPropertyValuationRental, "Property Valuation Rental"),
        onClose: () => openCalculatorDialogSet(false),
        fields: [
          fields.postcode(postcode),
          fields.propertyType2(),
          fields.constructionDate(),
          fields.internalArea(),
          fields.bedrooms(),
          fields.bathrooms(),
          fields.finishQuality(),
          fields.outdoorSpace(),
          fields.offStreetParking(),
        ],
      },
      {
        title: "Development Calculator",
        description:
          "Combines construction cost data with real-time market data on values per square foot to help you estimate how much profit a development will make.",
        onOk: configCreateOnOk(getPropertyCalculationDevelopmentCalculator, "Development Calculator"),
        onClose: () => openCalculatorDialogSet(false),
        fields: [
          fields.postcode(postcode),
          fields.purchasePrice(),
          fields.sqftPreDevelopment(),
          fields.sqftPostDevelopment(),
          fields.projectType(),
          fields.finishQualitySimple(),
        ],
      },
      {
        title: "Development Multi Unit",
        description: "Calculates an estimated gross development value (with +/- margin of error) for that property, as well as a total rental value.",
        onOk: configCreateOnOk(getPropertyCalculationDevelopmentMultUnit, "Development Multi Unit"),
        onClose: () => openCalculatorDialogSet(false),
        fields: [
          fields.postcode(postcode),
          fields.finishQualitySimple(),
          fields.internalArea(),
          fields.numberField({ label: "How many 0 bed flats", field: "flat_0" }),
          fields.numberField({ label: "How many 1 bed flats", field: "flat_1" }),
          fields.numberField({ label: "How many 2 bed flats", field: "flat_2" }),
          fields.numberField({ label: "How many 3 bed flats", field: "flat_3" }),
          fields.numberField({ label: "How many 4 bed flats", field: "flat_4" }),
          fields.numberField({ label: "How many 2 terraced houses", field: "terraced_house_2" }),
          fields.numberField({ label: "How many 3 terraced houses", field: "terraced_house_3" }),
          fields.numberField({ label: "How many 4 terraced houses", field: "terraced_house_4" }),
          fields.numberField({ label: "How many 5 terraced houses", field: "terraced_house_5" }),
          fields.numberField({ label: "How many 2 semi-detached houses", field: "semi-detached_house_2" }),
          fields.numberField({ label: "How many 3 semi-detached houses", field: "semi-detached_house_3" }),
          fields.numberField({ label: "How many 4 semi-detached houses", field: "semi-detached_house_4" }),
          fields.numberField({ label: "How many 5 semi-detached houses", field: "semi-detached_house_5" }),
          fields.numberField({ label: "How many 2 detached houses", field: "detached_house_2" }),
          fields.numberField({ label: "How many 3 detached houses", field: "detached_house_3" }),
          fields.numberField({ label: "How many 4 detached houses", field: "detached_house_4" }),
          fields.numberField({ label: "How many 5 detached houses", field: "detached_house_5" }),
        ],
        longCallIndicator: true,
      },
    ];
  }
  function showResultsModal(resultConfig) {
    resultsDialogConfigSet(resultConfig);
    openResultDialogSet(true);
  }
  return (
    <>
      {openCalculatorDialog && <CalculatorDialog open={openCalculatorDialog} config={diaglogConfig} />}
      {openResultDialog && <ResultDialog open={openResultDialog} onClose={() => openResultDialogSet(false)} config={resultsDialogConfig} />}
      <Grid container classes={classes} style={{ padding: 16 }}>
        {calculators.map((calc, idx) => (
          <Grid key={`calculators-${idx}`} item xs={12} md={6}>
            <h3>{calc.title}</h3>
            <hr className="short" />
            <p>{calc.description}</p>
            <PreviousQueries title={props.propertyTitle} id={calc.title} showFn={showResultsModal} />
            <p style={{ textAlign: "left" }}>
              <Button
                className="box-button"
                onClick={() => {
                  openCalculatorDialogSet(true);
                  diaglogConfigSet(calculators[idx]);
                }}
              >
                Open Calculator
              </Button>
            </p>
            <hr style={{ width: "50%" }} />
          </Grid>
        ))}
      </Grid>
    </>
  );
}

function formatCalculationResultResponse(response: any, title): IResultDialogConfig {
  const fields = Object.keys(response).map((fieldName) => ({ field: fieldName, value: response[fieldName] }));
  const result: IResultDialogConfig = { title, fields };
  return result;
}
function createOnOk(openCalculatorDialogSet, resultsDialogConfigSet, openResultDialogSet, propertyTitleNumber) {
  return function configCreateOnOk(calculationFunction, dialogTitle) {
    return async function onOk(data) {
      let apiResponse = await calculationFunction(data.postcode, data);
      openCalculatorDialogSet(false);
      const resultConfig: IResultDialogConfig = formatCalculationResultResponse(apiResponse, dialogTitle);

      savePreviousQueryResults(propertyTitleNumber, dialogTitle, data, resultConfig);

      resultsDialogConfigSet(resultConfig);
      openResultDialogSet(true);
    };
  };
}

function savePreviousQueryResults(propertyTitleNumber, dialogTitle, data, resultConfig) {
  // store query data for later retrieval
  const newData = Object.assign({}, data, { resultConfig });
  let storageKey: string = getStorageKey(propertyTitleNumber, dialogTitle);
  try {
    const storedCache: [] = JSON.parse(localStorage.getItem(storageKey) || "[]");
    const newCache = [newData, ...storedCache].splice(0, 5);
    localStorage.setItem(storageKey, JSON.stringify(newCache));
  } catch (e) {
    /* do nothing*/
    console.log('error in savePreviousQueryResults', e)
  }
}

function getStorageKey(title, id) {
  return `query-${title}-${id}`;
}
function formatQuery(q) {
  const label = Object.keys(q)
    .filter((f) => ["postcode", "resultConfig"].indexOf(f) < 0)
    .sort((a, b) => (a < b ? 0 : 1))
    .map((key, idx) => (
      <span key={`qw-${idx}`}>
        <b>{key}</b>:{q[key]}{" "}
      </span>
    ));
  return label;
}
function removeItemFromCache(queryStore: [], storageKey, idx) {
  const newStore = queryStore.filter((i, x) => x !== idx);
  localStorage.setItem(storageKey, JSON.stringify(newStore));
}
interface PreviousQueriesProps {
  id: string;
  title: string;
  showFn: any;
}
function PreviousQueries(props: PreviousQueriesProps) {
  const [reload, reloadSet] = useState(false);
  const storageKey = getStorageKey(props.title, props.id);
  const queryStoreJSON: string = localStorage.getItem(storageKey) || "[]";
  try {
    let queryStore = JSON.parse(queryStoreJSON);
    if (queryStore.length) {
      return (
        <ul style={{ fontFamily: "monospace", listStyleType: "none", paddingInlineStart: 0 }}>
          {queryStore.map((q, idx) => (
            <li key={`q-${idx}`}>
              <div style={{ display: "flex" }}>
                <button onClick={() => [removeItemFromCache(queryStore, storageKey, idx), reloadSet(!reload)]}>x</button>
                <button onClick={() => props.showFn(q["resultConfig"])}>{formatQuery(q)}</button>
              </div>
            </li>
          ))}
        </ul>
      );
    }
  } catch (e) {
    localStorage.setItem(storageKey, "[]");
  }
  return null;
}
